import React, { useEffect, useState } from 'react';

import * as XLSX from 'xlsx';

import ResultsRenderHandler from '../Components/Reporting/SummaryReport/ResultsRenderHandler.js';
import PrintingFunctions from '../Components/Reporting/Functions/PrintingFunctions.js';

function Download({
    session,
  }){
    const reporting = session?.reporting;
    const currentAccountID = session?.handler?.data?.currentAccountID;
    const selectedReport = reporting?.data[currentAccountID]?.selectedReport;
    const updateReporting = session?.reporting?.setData;

    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    function getSummarizedData(selectedReport, attributeData) {
        const cachedFormattedDates = new Map();

        const resultsHandler = ResultsRenderHandler({
            session,
            attributeData,
            renderFormattedColumnValue : PrintingFunctions({session, cachedFormattedDates})?.functions?.renderFormattedColumnValue,
            updateReporting,
        })?.functions;

        const activeCriteria = selectedReport?.criteria?.groupBy?.filter(criterion => 
            !criterion?.inactive && 
            typeof criterion?.attr === 'string' && criterion?.attr?.trim() !== '' &&
            typeof criterion?.formType === 'string' && criterion?.formType?.trim() !== '' &&
            typeof criterion?.combineBy === 'string' && criterion?.combineBy?.trim() !== ''
        ) ?? [];

        const summaryColumns = selectedReport?.columns.filter(column => column?.summarizeBy);
        
        const summarizedData = resultsHandler?.applyHierarchicalGrouping(
            selectedReport?.list,
            activeCriteria,
            summaryColumns
        );
        
        return summarizedData || [];
    }

    function generateColumns() {
        const attributeData = session?.[reporting?.data[currentAccountID]?.dataPointer]?.data?.attributeData;
        const defaultColumns = reporting?.data[currentAccountID]?.defaultColumns?.[selectedReport?.referenceStem]?.detailedArray || [];
        const existingColumnNames = new Set(selectedReport.columns.map(col => col.columnName));

        const prepareColumns = columns => columns
            ?.filter(col => (col?.fieldBy || col?.columnName) !== (reporting?.data[currentAccountID]?.selectAllVar || ""))
            .map(col => ({
                columnName: col?.fieldBy || col?.columnName,
                friendlyTerm: attributeData?.[col?.fieldBy || col?.columnName]?.friendlyTerm || col?.columnName
            }));

        const combinedColumns = prepareColumns(selectedReport.columns.filter(col => !col.summarizeBy))
            .concat(prepareColumns(defaultColumns.filter(col => !existingColumnNames.has(col?.columnName))));

        const finalSummaryColumns = prepareColumns(selectedReport.columns.filter(col => col.summarizeBy));
        
        return [...combinedColumns, ...finalSummaryColumns];
    }

    function generateName(){
        const isSummaryReport = selectedReport?.stem === "summary";
        const subReportName = selectedReport?.subReport?.name;
        return subReportName ? subReportName.replace(/^ > /, '') : isSummaryReport ? "All Results" : selectedReport?.details?.name;
    }

    function downloadXLSXReport(selectedReport, sortedListResults) {
        const isSummaryReport = selectedReport?.stem === "summary";
        const workbook = XLSX.utils.book_new();
        const attributeData = session?.[reporting?.data[currentAccountID]?.dataPointer]?.data[currentAccountID]?.attributeData;

        const fetchChildLevelResults = (groupedData, results = []) => {
            if (Array.isArray(groupedData)) {
                results.push(...groupedData);
            } else if (typeof groupedData === 'object' && groupedData !== null) {
                Object.values(groupedData).forEach(subData => {
                    if (subData.list && typeof subData.list === 'object') {
                        fetchChildLevelResults(subData.list, results);
                    }
                });
            }
            return results;
        };

        // Helper function for summary row on summary reports
        function getCellValue(children) {
            if (!children) return null;
            if (children.props) {
                return children;
            } else if (Array.isArray(children)) {
                for (const child of children) {
                    const totalSpan = getCellValue(child);
                    if (totalSpan) return totalSpan;
                }
            }
            return null;
        }

        function flattenGroupedDataForXLSX(groupedData, columnHeaders, level = 0, path = '') {
            const rows = [];

            // Add summary row at the top if summary report
            if(selectedReport?.stem === "summary"){
                const newRow = [];
                session?.reporting?.data[currentAccountID]?.summaryCells.forEach(item => {
                    const children = item.props.children;
                    const totalSpan = getCellValue(children);
                    if (totalSpan) {
                        const totalText = totalSpan?.props?.children?.props?.children;
                        newRow.push(totalText);
                    }
                });
                rows.push(newRow);
                rows.push([]);
            }

            if(level === 0){
                rows.push(columnHeaders.map(col => col?.friendlyTerm));
            }

            Object.entries(groupedData).forEach(([key, group], index) => {
                const currentPath = path ? `${path} > ${key}` : key;
                const summaryRow = columnHeaders.map(column => {
                    if (column.id === group.id) {
                        return key;
                    } else {
                        const summary = group.summaries && group.summaries[column.fieldBy];
                        if (summary && column.summarizeBy) {
                            return summary[column.summarizeBy];
                        }
                    }
                    return '';
                });

                rows.push(summaryRow);

                if (group.list && typeof group.list === 'object' && !Array.isArray(group.list)) {                    
                    rows.push(...flattenGroupedDataForXLSX(group.list, columnHeaders, level + 1, currentPath));
                }
            });
            return rows;
        }

        const sanitizeSheetName = (name) => {
            // Replace invalid characters with an underscore or any other valid character
            return name?.replace(/[:\\/?*\[\]]/g, '_')?.substring(0, 31);
        };

        // Function to add a sheet to the workbook
        const addSheet = (data, columns, sheetName, type) => {
            let sheetData;
            if (Array.isArray(data)) {
                sheetData = [columns.map(col => col.friendlyTerm), ...data.map(item => columns.map(col => item[col.columnName] || ''))];
            } else if (typeof data === 'object') {
                if (type === "dynamic") {
                    const flattenNestedLists = (obj) => {
                        let result = [];
                        Object.values(obj).forEach(value => {
                            if (Array.isArray(value.list)) {
                                result = result.concat(value.list);
                            } else if (typeof value === 'object') {
                                result = result.concat(flattenNestedLists(value));
                            }
                        });
                        return result;
                    };

                    sheetData = [
                        columns
                        ?.map(col => col?.friendlyTerm),
                        ...flattenNestedLists(data).map(item =>
                        columns.map(col => item[col?.columnName] || '')
                        )
                    ];
                } else {
                    sheetData = flattenGroupedDataForXLSX(data, selectedReport?.columns);
                }
            }
            const sanitizedSheetName = sanitizeSheetName(sheetName?.substring(0, 31));

            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
            XLSX.utils.book_append_sheet(workbook, worksheet, sanitizedSheetName);
        };

        // Handle summary reports
        if(isSummaryReport){
            addSheet(getSummarizedData(selectedReport, attributeData), selectedReport?.columns, "Summary");
        }
        
        const subReportName = selectedReport?.subReport?.name;
        // const rawName = subReportName ? subReportName.replace(/^ > /, '') : isSummaryReport ? "All Results" : selectedReport?.details?.name;
        // const sheetName = rawName;
        
        addSheet(isSummaryReport && !subReportName ?
            getSummarizedData(selectedReport, attributeData) : sortedListResults,
            generateColumns(),
            generateName(),
            "dynamic"
        );

        // Write the workbook to a file
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        const url = URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));
        const link = document.createElement("a");
        link.href = url;
        link.download = `${selectedReport.details.name}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function downloadCSVReport(selectedReport, sortedListResults, returnType = "csv") {
        const isSummaryReport = selectedReport?.stem === "summary";

        const attributeData = session?.[reporting?.data[currentAccountID]?.dataPointer]?.data[currentAccountID]?.attributeData;

        const generateContent = (data, columns, format) => {
            let header = columns.map(columnName => {
                const columnConfigItem = columns.find(config => config?.columnName === columnName);

                return attributeData?.[columnName]?.friendlyTerm ?? `"${columnConfigItem ? columnConfigItem?.friendlyTerm?.replace(/"/g, '""') : columnName}"`
            }).join(format === 'txt' ? "\t" : ",");

            let rows = data.map(row => {
                return columns.map(columnName => {
                    let value = row[columnName] || '';
                    if (typeof value !== 'string') {
                        value = String(value);
                    }

                    if (format === 'csv' && /^\d+$/.test(value)) {
                        value = `"${value}"`;
                    } else {
                        value = `"${value.replace(/"/g, '""')}"`;
                    }
                    return value;
                }).join(format === 'txt' ? "\t" : ",");
            }).join("\n");

            return `data:text/${format};charset=utf-8,${header}\n${rows}`;
        };

        const downloadFile = (content, filename, format) => {
            const encodedUri = encodeURI(content);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", filename + (format === 'txt' ? '.txt' : '.csv'));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        const columnNames = selectedReport.columns.map(c => c.columnName);
        const detailsContent = generateContent(sortedListResults, columnNames, returnType === 'txt' ? 'txt' : 'csv');

        // const detailsCSVContent = generateContent(sortedListResults, columnNames, returnType);

        if (selectedReport?.stem === "summary") {
            function flattenGroupedDataForCSV(groupedData, columnHeaders, level = 0, path = '') {
                const rows = [];
                Object.entries(groupedData).forEach(([key, group], index) => {
                    const currentPath = path ? `${path} > ${key}` : key;
                    
                    const summaryRow = columnHeaders.map(column => {
                        if (column.id === group.id) {
                            // If the key contains commas or double quotes, enclose it in double quotes and escape double quotes inside the key
                            const needsQuotes = /[, "]/.test(key);
                            return needsQuotes ? `"${key.replace(/"/g, '""')}"` : key;
                        } else {
                            const summary = group.summaries && group.summaries[column.fieldBy];
                            if (summary && column.summarizeBy) {
                                // If the summary value contains commas or double quotes, enclose it in double quotes and escape double quotes inside the value
                                const needsQuotes = /[, "]/.test(summary[column.summarizeBy]);
                                return needsQuotes ? `"${summary[column.summarizeBy].replace(/"/g, '""')}"` : summary[column.summarizeBy];
                            }
                        }
                        return '""';
                    }).join(',');
            
                    rows.push(summaryRow);
            
                    if (group.list && typeof group.list === 'object' && !Array.isArray(group.list)) {
                        rows.push(...flattenGroupedDataForCSV(group.list, columnHeaders, level + 1, currentPath));
                    }
                });
                return rows;
            }

            const generateSubReport = (data, columnHeaders) => {
                const flattenNestedLists = (obj) => {
                    let result = [];
                    Object.values(obj).forEach(value => {
                        if (Array.isArray(value.list)) {
                            result = result.concat(value.list);
                        } else if (typeof value === 'object') {
                            result = result.concat(flattenNestedLists(value));
                        }
                    });
                    return result;
                };
            
                return flattenNestedLists(data).map(item =>
                    columnHeaders.map(col => {
                        const value = item[col?.columnName] || '';
                        const needsQuotes = /[, "]/.test(value);
                        return needsQuotes ? `"${value.replace(/"/g, '""')}"` : `"${value}"`;
                    })
                );
            }

            function downloadSummaryReport(summarizedData, name, type, returnType = 'csv') {
                const columnHeaders = selectedReport.columns;
                const isDynamic = type === "dynamic";
                const dataRows = isDynamic ?
                    generateSubReport(summarizedData, generateColumns())
                :
                    flattenGroupedDataForCSV(summarizedData, columnHeaders);
                const formatHeaders = (columns) => columns.map(column => {
                    return returnType === 'txt' ?
                        column.friendlyTerm
                    :
                        `"${column.friendlyTerm.replace(/"/g, '""')}"`;
                }).join(returnType === 'txt' ? "\t" : ',');
            
                const reportHeaders = isDynamic ? formatHeaders(generateColumns()) : formatHeaders(columnHeaders);
                const content = `data:text/${returnType === 'txt' ? 'plain' : 'csv'};charset=utf-8,${reportHeaders}\n${dataRows.join('\n')}`;
            
                const encodedUri = encodeURI(content);
                const link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", `${name}.${returnType}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            
            const summarizedData = getSummarizedData(selectedReport, attributeData);
            const subReportName = selectedReport?.subReport?.name;

            downloadSummaryReport(summarizedData, `${selectedReport?.details?.name} Summary`, undefined, returnType);

            downloadSummaryReport(
                summarizedData
                `All Results`,
                "dynamic",
                returnType
            );

            if(isSummaryReport && subReportName){
                const columnNames = generateColumns().map(c => c?.columnName);
                const subReportCSVContent = generateContent(sortedListResults, columnNames, returnType);
                downloadFile (subReportCSVContent, `${generateName()}`, returnType);
            }
        } else {
            downloadFile (detailsContent, `${selectedReport.details.name}`, returnType);
        }
    }

    function downloadReport(selectedReport){
        if(reporting?.data[currentAccountID]?.downloadFileType === "CSV"){
            downloadCSVReport(selectedReport, selectedReport?.list);
        }

        if(reporting?.data[currentAccountID]?.downloadFileType === "XLSX"){
            downloadXLSXReport(selectedReport, selectedReport?.list);
        }

        if(reporting?.data[currentAccountID]?.downloadFileType === "TXT"){
            downloadCSVReport(selectedReport, selectedReport?.list, "txt");
        }
    }

    const functions = {
        downloadReport
    }
    
    const DownloadFunctions = {
        functions,
    }
    
    return DownloadFunctions;
};
    
export default Download;