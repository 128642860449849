import React from 'react';
import { useState, useEffect} from 'react';
import { Button, Text, useDisclosure } from '@chakra-ui/react';
import CircularProgress from '@mui/material/CircularProgress';

import SaveIcon from '@mui/icons-material/Save';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
  
  function UserAgreement({fileRows, errorCountData, session, setFileRows}) {
      //const session = session;

      // const compressData = async () => {
      //   const jsonStr = JSON.stringify(fileRows);

      //   const encoder = new TextEncoder();
      //   const encodedData = encoder.encode(jsonStr);

      //   const compressionStream = new CompressionStream('gzip');
      //   const compressedStream = encodedData.pipeThrough(compressionStream);

      //   const compressedData = await new Response(compressedStream).arrayBuffer();
        
      //   // Handle the compressed data here, such as sending it to the server
      // };

      // // Call the async function when the component mounts
      // useState(() => {
      //     compressData();
      // }, [fileRows]);

      const [loadingStatus, setLoadingStatus] = useState(false);

      const OverlayOne = () => (
        <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(6px)'
        />
      )

      const headerMapping = {
        'Internal ID': 'internal_id',
        'Internal Group': 'internal_group',
        'First Name': 'first_name',
        'Middle Name': 'middle_name',
        'Last Name': 'last_name',
        'Suffix': 'suffix',
        'SSN': 'ssn',
        'Date of Birth': 'dob',
        'City': 'city',
        'State': 'state',
        'Zip Code': 'zip_code',
        'Beneficiary First Name': 'benef_first_name',
        'Beneficiary Middle Name': 'benef_middle_name',
        'Beneficiary Last Name': 'benef_last_name',
        'Beneficiary DOB': 'benef_dob',
        'Beneficiary City': 'benef_city',
        'Beneficiary State': 'benef_state',
        'Beneficiary Zip': 'benef_zip',
        'Beneficiary Relationship': 'benef_relationship'
    };
        
    function getMappedHeader(originalHeader) {
        return headerMapping[originalHeader] || originalHeader;
    }

    const snakeCaseHeaders = [
      'internal_id',
      'internal_group',
      'first_name',
      'middle_name',
      'last_name',
      'suffix',
      'ssn',
      'dob',
      'city',
      'state',
      'zip_code',
      'benef_first_name',
      'benef_middle_name',
      'benef_last_name',
      'benef_dob',
      'benef_city',
      'benef_state',
      'benef_zip',
      'benef_relationship'
    ];

    function transformHeaders (rowsObj){
      rowsObj.forEach(row => {
        Object.keys(row).forEach(originalHeader => {
              // Check for specific headers and apply SerializedNumToDate
              if (originalHeader === 'dob' || originalHeader === 'benef_dob') {
                  row[originalHeader] = session?.upload?.functions?.convertToISODate(row[originalHeader]);
              } 
        });
      });

      uploadMany();
    }

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [overlay, setOverlay] = React.useState(<OverlayOne />)
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
    };

    function uploadMany(){
        let paramVals = {
            "individuals" : fileRows,
            "uploadHistory" : errorCountData,
            "accountID" : session?.handler?.data?.currentAccountID
        };
        session?.env?.functions?.buildFetchRequest("individual/addMany", paramVals)
          .then(response => response.json())
          .then(resData => {
            setLoadingStatus(false);
            if(resData.status === 200){
              session?.env?.setOverlay("uploadSuccess");
              setFileRows([]);
            }else{
              session?.env?.setOverlay("uploadFailure");
                //setErrorStatus(resData.message);
            }
        });
    }

    return (
      <>
        <div className='btnWIcon cC f bR'
          onClick={() => {
            setOverlay(<OverlayOne />)
            onOpen()
          }}
        >
          <div className='f cC'>Save Upload </div>
          <SaveIcon/>
        </div>
        <Modal isCentered isOpen={isOpen} size={'sm'} onClose={onClose} motionPreset='slideInBottom'>
          {overlay}
          <ModalContent top={'300px'} width={'50%'} left={'25%'} className='userAgreementBox bR cC'>
            {!loadingStatus &&
              <>
                <ModalHeader className='bold'>User Agreement</ModalHeader>
                <div className='cC' style={{width:'1000px'}}>
                  
                </div>
                <ModalBody>
                  <Text>             
                    <>
                      <input 
                            type="checkbox" 
                            style={{ transform: 'scale(1.5)' }}
                            checked={isChecked} 
                            onChange={handleCheckboxChange} 
                      />
                      &emsp;Client shall securely upload to ABL Tech in an excel file (or other file type provided by ABL Tech) 
                      that includes the following information for each Person: social security number, last name, first name, middle initial, 
                      spouse name, beneficiary name(s), date of birth, address, and zip code, each as available (the “Processing File”). Death 
                      Audit reports are delivered through the ABL Tech Reporting Platform or secure file transfer (“SFT”). The login and password 
                      for SFT is for a single, authorized user. Client agrees not to share login and password information among more than one user 
                      and shall promptly notify ABL Tech when an authorized user is no longer authorized to use the SFT (whether due to reassignment, 
                      termination or otherwise). To retrieve certain data to perform the Services, ABL Tech discloses Client’s Confidential Information 
                      to ABL Tech's data vendor(s) and Client hereby consents to such disclosure.
                    </>
                  </Text>
                  
                </ModalBody>
                <ModalFooter>
                  <div className='userAgreementButtons g cC f'>
                    {isChecked?
                      <div className='btnWIcon cC bold saveUploadBtn dP bR gC1' onClick={() => { transformHeaders(fileRows); setLoadingStatus(true);}}>Confirm Upload</div> //console.log("Send Row Objects here:",fileRows);
                      :
                      <div className='cC bold saveUploadInactive saveUploadInactiveBorder dP bR gC1'>Confirm Upload</div>
                    }
                    <div className='btnWIcon cC bold saveUploadBtn dP bR gC3' onClick={onClose}>Close</div>
                  </div>
                </ModalFooter>
              </>
            }
            {loadingStatus &&
              <>
                <ModalHeader className='bold'>Uploding File</ModalHeader>
                <div className='cC' style={{width:'1000px', height:'30px'}}>
                  
                </div>
                <ModalBody>
                  <CircularProgress disableShrink color="success" variant="indeterminate" fontSize="large"/> 
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
              </>
            }
          </ModalContent>
        </Modal>
      </>
    )
  }

  export default UserAgreement;