import React, { useEffect, useState, useRef } from 'react';

import FunctionsOutlinedIcon from '@mui/icons-material/FunctionsOutlined';
import Dropdown from '../../Dropdown.js';

function CustomColumnBlock({
    session,
    attr,
    blockID,
    groupColumnHover,
    columnObject,
    index,
    friendlyTerm = '',
    completeSummarization,
    addOrRemoveColumns,
    alterCustomColumn,
    statusIcon,
    deleteIcon,
    currentView,
    formDropdownHandler,
    attributeData,
    friendlyTermsArray,
    resetDropdowns
  }) {

    const reporting = session?.reporting;
    const currentAccountID = session?.handler?.data?.currentAccountID;
    const isHovered = groupColumnHover.data === blockID;
    const rowClassNames = `row custom alt g bR s e g dG nS fC dP bold${
        friendlyTerm && completeSummarization ? " complete" : " error"}${
            isHovered ? " hovered" : ''}`;

    const handleIconClick = () => {
        addOrRemoveColumns({
          pointer: 'id',
          objectKey: columnObject?.id,
        });
        groupColumnHover?.set(undefined);
    };

    return (
        <div
            key={blockID}
            className={rowClassNames}
        >
            <div className="topBar g tC f dG fC">
                {statusIcon}
                <FunctionsOutlinedIcon/>
                <div className={`f gCW cL${currentView === 'properties' ? ' gC3' : ' gC2'}`}>
                    {attr?.friendlyTerm}
                </div>
                <div
                    className="icon f fR tC p"
                    onMouseEnter={() => groupColumnHover?.set(blockID)}
                    onMouseLeave={() => groupColumnHover?.set(undefined)}
                    onClick={handleIconClick}
                >
                    {deleteIcon}
                </div>
            </div>
            <div className={`section g bR dP fC dG${!attr?.friendlyTerm ? " error" : " complete"}`}>
                <div className="f cL">
                    Column Name
                </div>
                <input
                    className="inputMatch"
                    placeholder="Unnamed Column"
                    value={friendlyTerm}
                    onChange={(e) => {
                        alterCustomColumn(columnObject?.id, "friendlyTerm", e?.target?.value);
                    }}
                />
            </div>
            <div className={`section g bR dP fC dG${!attr?.fieldBy ? " error" : " complete"}`}>
                <div className="f cL">
                    Target Field
                </div>
                <Dropdown
                    key={`Group-${index}-FieldBy-Dropdown`}
                    setShowMenu={(input) => {
                        const newState = input ?? (formDropdownHandler?.data?.showMenu ? undefined : `Group-${index}-FieldBy`);
                        formDropdownHandler?.set({
                            ...formDropdownHandler,
                            showMenu: newState
                        })
                    }}
                    showMenu={formDropdownHandler?.data?.showMenu === `Group-${index}-FieldBy`}
                    prompt={!attributeData?.[columnObject?.fieldBy]?.friendlyTerm ? "Select a Field" : undefined}
                    default={attributeData?.[columnObject?.fieldBy]?.friendlyTerm ?? undefined}
                    list={friendlyTermsArray}
                    onClick={(value) => {
                        const existingAttrData = Object.entries(attributeData).find(([_, child]) => child?.friendlyTerm === value);
                        const [attrDataKey, attrData] = existingAttrData; // Destructuring the key and value with more descriptive names

                        alterCustomColumn(attr?.id, "fieldBy", attrDataKey);
                    }}
                    flex={true}
                    reset={resetDropdowns}
                    className={["inForm"].concat(!attributeData?.[columnObject?.fieldBy]?.friendlyTerm ? ["error"] : ["complete"])}
                />
            </div>
            <div className={`section g bR dP fC dG${!attr?.summarizeBy ? " error" : " complete"}${!attr?.fieldBy ? " inactive" : ''}`}>
                <div className="f cL">
                    Summarize By
                </div>
                <Dropdown
                    key={`Group-${index}-Summarization-Dropdown`}
                    setShowMenu={(input) => {
                        const newState = input ?? (formDropdownHandler?.data?.showMenu ? undefined : `Group-${index}-Summarization`);
                        formDropdownHandler?.set({
                            ...formDropdownHandler,
                            showMenu: newState
                        })
                    }}
                    inactive={!columnObject?.fieldBy}
                    showMenu={formDropdownHandler?.data?.showMenu === `Group-${index}-Summarization`}
                    prompt={!columnObject?.summarizeBy ? "Select Summarization" : undefined}
                    default={columnObject?.summarizeBy ?? undefined }
                    list={reporting?.data[currentAccountID]?.combineBySummaries?.[attributeData?.[columnObject?.fieldBy]?.formType]}
                    onClick={(value) => {
                        alterCustomColumn(attr?.id, "summarizeBy", value);
                    }}
                    flex={true}
                    reset={resetDropdowns}
                    className={["inForm"].concat(!columnObject?.summarizeBy ? ["error"] : ["complete"])}
                />
            </div>
        </div>
    )
};

export default CustomColumnBlock;