import React, { useEffect, useState} from 'react';

function EditSheetVars(props){

    const initialImportedList = {
        branch : "deathTrac",
        stem : "individuals",
        columns : [],
        list: undefined, // rows
        name : undefined,
        ID : undefined,
        criteria : {
          current : {
            attr : undefined
          },  
        existing: [
            {
                "attr": "record_id",
                "criterion": {
                    "not_blank": true
                },
                "formType": "int",
                "groupID": 1,
                "groupOperator": "AND",
                "inlineOperator": "AND"
            }
        ],
        },
        details : {
          name : "Upload Preview",
          description : undefined,
          shareType : undefined,
          shareList : undefined, 
          reoccurType  : false,
          scrollType : "infinite",
          editAccessList : ["owner"],
          viewAccessList : ["owner"],
        },
        query : undefined,
        showAll : false,
        // lastQuery : undefined,
        lastPageIndex : 1,
        lastRecordID : undefined,
        editCell : {
            "row" : undefined,
            "column" : undefined,
            "value" : ''
        }
    };

    const statesArray = [
        'ALABAMA', 'AL', 'ALASKA', 'AK', 'ARIZONA', 'AZ', 'ARKANSAS', 'AR', 'CALIFORNIA', 'CA', 'COLORADO', 'CO',
        'CONNECTICUT', 'CT', 'DELAWARE', 'DE', 'FLORIDA', 'FL', 'GEORGIA', 'GA', 'HAWAII', 'HI', 'IDAHO', 'ID',
        'ILLINOIS', 'IL', 'INDIANA', 'IN', 'IOWA', 'IA', 'KANSAS', 'KS', 'KENTUCKY', 'KY', 'LOUISIANA', 'LA',
        'MAINE', 'ME', 'MARYLAND', 'MD', 'MASSACHUSETTS', 'MA', 'MICHIGAN', 'MI', 'MINNESOTA', 'MN', 'MISSISSIPPI', 'MS',
        'MISSOURI', 'MO', 'MONTANA', 'MT', 'NEBRASKA', 'NE', 'NEVADA', 'NV', 'NEW HAMPSHIRE', 'NH', 'NEW JERSEY', 'NJ',
        'NEW MEXICO', 'NM', 'NEW YORK', 'NY', 'NORTH CAROLINA', 'NC', 'NORTH DAKOTA', 'ND', 'OHIO', 'OH', 'OKLAHOMA', 'OK',
        'OREGON', 'OR', 'PENNSYLVANIA', 'PA', 'RHODE ISLAND', 'RI', 'SOUTH CAROLINA', 'SC', 'SOUTH DAKOTA', 'SD', 'TENNESSEE', 'TN',
        'TEXAS', 'TX', 'UTAH', 'UT', 'VERMONT', 'VT', 'VIRGINIA', 'VA', 'WASHINGTON', 'WA', 'WEST VIRGINIA', 'WV',
        'WISCONSIN', 'WI', 'WYOMING', 'WY', 'DC', 'WASHINGTON DC', 'D.C.', 'DISTRICT OF COLUMBIA'
    ];

    const InvalidSSNList = [
        '123456789',
        '987654321',
        '123123123',
        '111111111',
        '222222222',
        '333333333',
        '444444444',
        '555555555',
        '666666666',
        '777777777',
        '888888888',
        '999999999',
        '000000000',
    ];

    const initialErrorCountData = {
        'total_participants': 0,
        'first_name': 0,
        'last_name': 0,
        'ssn': 0,
        'dob': 0,
        'city': 0,
        'state': 0,
        'zip_code': 0,
    };

    

    const [data, setData] = useState({
        pointer: 'upload',
        importedList: initialImportedList, 
        rows: [],
        rowErrors: [],
        rowErrorKeys: [],
        errorCountData: initialErrorCountData,
        editList:[],
        editHandler: 
        {   
            position: {
                // "rowNum":{
                //     "colNum": value
                // }
            }
        },
        displayErrors: false,
        filterErrors: false,
        selectedState: {showMenu: false},
        sorting: [],
        specialColumns : {
            rowIndex : true,
            select : false,
          },
        uploadCount: 0,
        largeUploadEdits: [],
        largeUploadMainKey: '',
        largeUploadKeys: [],
    });     

    function excelSerialDateToDate(serialDate, dateOrigin = '1900') {
        let baseDate;
        if (dateOrigin === '1900') {
          // Excel incorrectly thinks 1900 is a leap year, so we subtract 1 for dates after 1900-02-28
          baseDate = new Date(1900, 0, (serialDate > 59 ? serialDate - 1 : serialDate - 0));
        } else if (dateOrigin === '1904') {
          // This is for Excel files created on Mac before Excel 2011
          baseDate = new Date(1904, 0, serialDate - 1);
        } else {
          throw new Error('Invalid date origin');
        }  
        return baseDate;
    }

    const updateReport = (path, attr, value) => {
        setData((prevState) => {
          const newState = JSON.parse(JSON.stringify(prevState)); // Deep copy
          const pathSegments = path.split('.');
          let current = newState;

          for (const segment of pathSegments) {
    
            if (segment.includes('[')) {
              // Handle array access within the path
              const [key, indexStr] = segment.split('[');
              const index = parseInt(indexStr.replace(']', ''), 10);
    
              if (!current[key]) {
                // Initialize an array if it doesn't exist
                current[key] = [];
              }
    
              if (!current[key][index]) {
                // Initialize an object within the array if it doesn't exist
                current[key][index] = {};
              }
    
              current = current[key][index];
            } else {
              // Handle regular object properties within the path
              if (!current[segment]) {
                // Initialize an object if it doesn't exist
                current[segment] = {};
              }
    
              current = current[segment];
            }
          }
    
          if (attr === null) {
            // marketValue, assignedUsersList, issueDate, accountManager, caseProcessor
            // Update the entire path's value to the new value
            newState[pathSegments[0]] = value;
          } else if (typeof attr === 'object' && !Array.isArray(attr)) {
            Object.assign(current, attr);
          } else {
            if(Array.isArray(attr)){
              newState[pathSegments[0]] = attr;
            }else{
              current[attr] = value;
            }
            // Update only the specified attribute
          }
          return newState;
        });
    };

    // Format for multiUpdateReport
    // { path: `importedList.list[1]`, attr: 'header1', value: 'newValue1' },
    const multiUpdateReport = (updates) => {
        setData((prevState) => {
            const newState = JSON.parse(JSON.stringify(prevState)); // Deep copy 
    
            for (const { path, attr, value } of updates) {
                const pathSegments = path.split('.');
                let current = newState;
    
                for (const segment of pathSegments) {
                    if (segment.includes('[')) {
                        const [key, indexStr] = segment.split('[');
                        const index = parseInt(indexStr.replace(']', ''), 10);
    
                        if (!current[key]) {
                            current[key] = [];
                        }
    
                        if (!current[key][index]) {
                            current[key][index] = {};
                        }
    
                        current = current[key][index];
                    } else {
                        if (!current[segment]) {
                            current[segment] = {};
                        }
                        current = current[segment];
                    }
                }
    
                if (attr === null) {
                    newState[pathSegments[0]] = value;
                } else if (typeof attr === 'object' && !Array.isArray(attr)) {
                    Object.assign(current, attr);
                } else {
                    if (Array.isArray(attr)) {
                        newState[pathSegments[0]] = attr;
                    } else {
                        current[attr] = value;
                    }
                }
            }
    
            return newState;
        });
    };

    const updateImportedList = (attr, value, append) => {
        setData(prevState => {
            // Append the new value to the array
            let updatedAttr = null;
            if(append){
                const existingAttr = Array.isArray(prevState.importedList[attr]) ? prevState.importedList[attr] : [];
                updatedAttr = [...existingAttr, value];
            }else{
                updatedAttr = value;
            }
            
            return {
                ...prevState,
                importedList: {
                    ...prevState.importedList,
                    [attr]: updatedAttr,
                },
            };
        });
    }
    
    //////////////////////////////// Edit cells variables /////////////////////////////////

    const saveEdit = (newValue, rowIndex, header, cellID, incrementFunc, decrementFunc) => {
        // Update the cell value in data structure & update errorCount by tracking edits

        // Var to check if previous edits at that index exists
        let prevEditsExist = false;

        //Create Edit object
        let editObj = {
            initialState: data.rows[rowIndex][header],
            modifiedState: newValue,
            index : {rowIndex, header},
            id: cellID,
            lastColor: undefined,
            currentColor: undefined
        }

        // Set current color of the cell after edit
        if(!validateEdit(newValue, header)){
            editObj.currentColor = 'green';
        }
        else{
            editObj.currentColor = 'red';
        }

        //console.log(data.editList);
        //Check if previous edits at that index exists
        for (let i = data.editList.length - 1; i >= 0; i--) {
            const eObj = data.editList[i];
            
            if (eObj.id === cellID) {
                prevEditsExist = true;
                editObj.initialState = eObj.modifiedState;
                editObj.lastColor = eObj.currentColor;
                break; // Exit the loop once the condition is met
            }
        }

        // If no previous edits exist, update Edit Object
        if(!prevEditsExist){
            editObj.lastColor = 'red';
        }

        // Update ErrorCount
        if((editObj.currentColor !== editObj.lastColor) && (editObj.currentColor === 'red')) {incrementFunc(header)};
        if((editObj.currentColor !== editObj.lastColor) && (editObj.currentColor === 'green')) {decrementFunc(header)};
        
        // Add edit to the Edit list
        addObjectToEditList(editObj);
        
        // Save the actual edit into the main list
        if(header=="ssn"){
            //updateReport(`importedList.list[${rowIndex}]`, `${header}`, newValue);
        }
        else{
            //updateReport(`importedList.list[${rowIndex}]`, `${header}`, newValue);
        }

        // Exit editing mode
        updateReport(
            `editHandler.position[${rowIndex}]`, 
            null,
            null //{...Object.fromEntries(Object.entries(data?.editHandler?.position[rowIndex])?.filter(colIndex => colIndex !== colIndex))}
        );
    };

    const scrollToCell = (cellID) => {
        const cell = document.getElementById(cellID); // 'cellId' is cell's key
        const container = document.getElementById('fileContainer'); // 'fileContainer' is container's key
    
        if (!cell || !container) return;
    
        const cellRect = cell.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
    
        const xCoordinate = cellRect.left + container.scrollLeft - containerRect.left - (containerRect.width / 2 - cellRect.width / 2);
        const yCoordinate = cellRect.top + container.scrollTop - containerRect.top - (containerRect.height / 2 - cellRect.height / 2);
    
        container.scrollTo(xCoordinate, yCoordinate);
    };

    function validateEdit(cellValue, header) {
        let setRed = false;

        if((cellValue==null)){
            setRed = true
        }
        if((cellValue==='-')&& (header==="dob"||header==="first_name"||header==="last_name"||header==="ssn"||header==="city"||header==="state"||header==="zip_code")){
            setRed = true
        }
        if((header==="ssn") &&  (String(cellValue).length !== 9 || InvalidSSNList.includes(String(cellValue)) || !/^\d+$/.test(cellValue))) {
            setRed = true;
        }
        if((header==="first_name" || header==="last_name") && !/^[A-Za-z-' ]+$/.test(cellValue)) {
            setRed = true;
        }  
        if(header == 'dob' && ((!isValidJSDate(cellValue)))){
            setRed = true;
        }
        if(header === 'city' && !/^[A-Za-z-' .]+$/.test(String(cellValue))){
            setRed = true;
        }
        if(header === 'state' &&  !statesArray.includes((String(cellValue))?.toUpperCase())){
            setRed = true;
        }
        if (header === 'zip_code' && !/^\d{5}(?:-\d{4})?$/.test(cellValue) ){
            setRed = true;
        }

        return setRed;
    };

    function decrementErrorCount(attr) { 
        if (data?.errorCountData?.[attr] > 0) {
            functions?.updateReport(`errorCountData`, attr, data?.errorCountData?.[attr] - 1);
        }
    }
    function incrementErrorCount(attr) { 
        if (data?.errorCountData?.[attr] > 0) {
            functions?.updateReport(`errorCountData`, attr, data?.errorCountData?.[attr] + 1);
        }
    }

    function isValidJSDate(dateInput) {
        // Create a Date object using the input
        const date = new Date(dateInput);
        // Check if the date is valid
        if (!isNaN(date.getTime())) {
            // check if the date string has been changed during the conversion
            const dateString = date.toISOString().split('T')[0];
            return dateString === dateInput;
        }
        return false;
    }

    const addObjectToEditList = (newObject) => {
        if(newObject.modifiedState === newObject.initialState){
            return;
        }else{
            setData(prevData => {
                // Create a new array with all old items, and add the new object
                const newEditList = [...prevData.editList, newObject];
        
                // Return the new state object with the updated editList
                return {
                    ...prevData,
                    editList: newEditList
                };
            });
        }
    };

    function undoEdit(func1, func2) {
        
        setData(prevData => {

            if (prevData.editList.length === 0) {
                return prevData;
            }
            // Get last edit item
            const lastEdit = prevData.editList[prevData.editList.length - 1];

            scrollToCell(lastEdit.id);
    
            const newList = data.importedList.list;

            // Valid Edit is current cell color, validate.
            if(func1){
                if(validateEdit(lastEdit.modifiedState,lastEdit.index.header) !== validateEdit(lastEdit.initialState,lastEdit.index.header) && (validateEdit(lastEdit.initialState,lastEdit.index.header))) {func1(lastEdit.index.header)};
                if(validateEdit(lastEdit.modifiedState,lastEdit.index.header) !== validateEdit(lastEdit.initialState,lastEdit.index.header) && (!validateEdit(lastEdit.initialState,lastEdit.index.header))) {func2(lastEdit.index.header)};
            }
            
            newList[lastEdit.index.rowIndex] = {
                ...newList[lastEdit.index.rowIndex],
                [lastEdit.index.header]: lastEdit.initialState
            };
           
            const newEditList = prevData.editList.slice(0, -1);
            // Return the new state
            return { 
                ...prevData,
                editList: newEditList,
                importedList: {
                    ...prevData.importedList,
                    list: newList
                }
            };
        });
    }
    
    function clearEditList(func1, data1) {
        data.editList.forEach((editItem, index) => {
            // option to increment delay for each undoEdit iteration
            setTimeout(() => {undoEdit();}, index * 0);         
        });

        func1(prevData => ({
            ...prevData,
            errorCountData: data1
        }));    
    }

    function createEditList(session){

        // map edit list to json 
        for (const item of session?.edit?.data?.editList) {
            if (data?.largeUploadEdits.length === 0){
                data?.largeUploadEdits.push({
                    index: item?.index?.rowIndex,
                    changes: {
                      [item?.index?.header]: item?.modifiedState
                    }
                  })
            }else{
                let exist = false;
                for(const member of data?.largeUploadEdits){
                    if(member.index === item.index.rowIndex){
                        exist = true;
                        member.changes[item?.index?.header] = item?.modifiedState;
                    }
                }
                if(!exist){
                    data?.largeUploadEdits.push({
                        index: item?.index?.rowIndex,
                        changes: {
                          [item?.index?.header]: item?.modifiedState
                        }
                      })
                }
            }
        }
        // console.log(data?.largeUploadEdits);
    }

    function editChunk(session, key){

        // EditChunk(session, session?.edit?.data?.largeUploadKeys[0]);
        // setLargeUploadEdits([]);
        session?.edit?.setData(prevData => ({
            ...prevData,
            largeUploadEdits: [],
        }));   
        createEditList(session);

        const myHeaders = new Headers();

        // const formData = new FormData();
        // formData.append("key",key);
        // formData.append("members", JSON.stringify(data?.largeUploadEdits));
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${session?.user?.data?.sessionID}`);

        const raw = JSON.stringify({
            "key": key,
            "members": data?.largeUploadEdits
          });
        
        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`https://apistg.abltech.com/proxy/modify`, requestOptions)
        .then(response => {
            // Check if the response status is 200
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Network response was not ok.');
            }
        })
        .then(resData => {
            // setLoadingStatus(false);
            // console.log(resData); 
            const filePreview = resData
            if (resData) {
                // console.log("success:",resData);
                insertLargeUpload(session);
            } else {
                session?.env?.setOverlay("uploadFailure");
                //setErrorStatus(resData.message);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            // setLoadingStatus(false);
            session?.env?.setOverlay("uploadFailure");
            //setErrorStatus(error.message);
        });
            
    }

    function insertLargeUpload(session){
        const myHeaders = new Headers();

        // const formData = new FormData();
        // formData.append("accountID", session?.handler?.data?.currentAccountID);
        // formData.append("key", data?.largeUploadMainKey); 

        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${session?.user?.data?.sessionID}`);

        const raw = JSON.stringify({
            "accountID": session?.handler?.data?.currentAccountID,
            "key": data?.largeUploadMainKey
          });
        
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`https://apistg.abltech.com/proxy/insert`, requestOptions)
        .then(response => {
            // Check if the response status is 200
            if (response.ok) {
                session?.upload?.setData(prevData => ({
                    ...prevData,
                    removeFile: true
                }));  
                functions?.updateImportedList("columns", []);
                functions?.updateReport("sorting", null, []);
                functions?.updateReport("errorCountData", session?.upload?.data?.initialErrorCount);
                session?.env?.setOverlay("uploadSuccess");
                return response.json();
            } else {
                session?.env?.setOverlay("uploadFailure");
                throw new Error('Network response was not ok.');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            //session?.env?.setOverlay("uploadFailure");
        });
            
    }

    const functions = {
        validateEdit,
        saveEdit,
        undoEdit,
        clearEditList,
        updateReport,
        updateImportedList,
        excelSerialDateToDate,
        scrollToCell,
        decrementErrorCount,
        incrementErrorCount,
        editChunk,
        createEditList,
        multiUpdateReport,
    }

    const EditSheetVars = {
        data,
        setData,
        functions,
    }

    return EditSheetVars;
    };

export default EditSheetVars;
