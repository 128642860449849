import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import ForwardToInboxRoundedIcon from '@mui/icons-material/ForwardToInboxRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PasswordRoundedIcon from '@mui/icons-material/PasswordRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import MailLockRoundedIcon from '@mui/icons-material/MailLockRounded';
import TextsmsIcon from '@mui/icons-material/Textsms';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import {Checkbox, CircularProgress} from '@chakra-ui/react'
import InputField from "../Components/InputField.js";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import SendIcon from '@mui/icons-material/Send';

import Logo from '../Images/ablTechLogoNoTagline.png';
import MicrosoftLogo from '../Images/MicrosoftLogo.png';
import GoogleLogo from '../Images/GoogleLogo.png';
import { m } from 'framer-motion';

function Login(props){
    const session = props?.session;
    const initialize = props?.initialize;
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = window.location.pathname.replace(/\//g, '');
    const params = new URLSearchParams(location.search);
    const ac = params.get("ac");
    const e = params.get("e");
    const t = params.get("text");

    const [userData, setUserData] = useState();
    const [accountData, setAccountData] = useState();
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [textLoading, setTextLoading] = useState(false);
    const [errorStatus, setErrorStatus] = useState();
    const [successStatus, setSuccessStatus] = useState(null);
    const [authStatus, setAuthStatus] = useState();
    const [textStatus, setTextStatus] = useState();
    const [firstText, setFirstText] = useState();
    const [textSent, setTextSent] = useState();
    const [timeLeft, setTimeLeft] = useState(60);
    const [countdown, setCountdown] = useState(false);
    const [authData, setAuthData] = useState();
    const [showOptions, setShowOptions] = useState(false);
    const [createPassword, setCreatePassword] = useState(true);
    const [enterText, setEnterText] = useState(true);
    const [textChecked, setTextChecked] = useState(false);
    const [authChecked, setAuthChecked] = useState(false);
    const [hasTOS, setHasTOS] = useState(false);
    const [TOSChecked, setTOSChecked] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    

    const requirements = [
        { key: "capitalLetter", label: "1 Capital Letter" },
        { key: "lowerCase", label: "1 Lowercase" },
        { key: "symbol", label: "1 Symbol" },
        { key: "number", label: "1 Number" },
        { key: "length", label: "At least 8 Characters" },
        { key: "matchingPasswords", label: "Matching Passwords" },
    ];

    const [activity, setActivity] = useState({
        currentPage : "login",
    });

    const [data, setData] = useState({
        emailCode : '',
        email : '',
        password : '',
        passwordConfirmation : '',
        authCode: '',
        textCode: '',
        phoneNumber: '',
    });

    const [qrInfoToggle, setQrInfoToggle] = useState(true);

    const handleQrInfo = () => {
        setQrInfoToggle(prevState => !prevState);
    };

    const twoFactorPageToggle = () => {
        if(authPage.currentPage === "textCode"){
            updateUseState(setAuthPage,"currentPage", "authCode");setErrorStatus(null);
        }
        else if(authPage.currentPage === "authCode"){
            updateUseState(setAuthPage,"currentPage", "textCode");setErrorStatus(null);
        }
    }

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    }
    const [authPage, setAuthPage] = useState({
        currentPage : "textCode"
    })

    const marginValue = (!textChecked && !authChecked) ? '5px' : '10px';

    const handleTextChecked = (e) => {
        data.textCode = '';
        setTextChecked(e.target.checked);
    };

    const handleAuthChecked = (e) => {
        data.authCode = '';
        setAuthChecked(e.target.checked);
    };

    const handleTOSCheckbox = (event) => {
        setTOSChecked(event.target.checked);
      };

    const [lastFour, setLastFour] = useState("");
    const [numberEnding, setNumberEnding] = useState(null);

    useEffect(() => {
        let timerId;
        if (timeLeft <= 0) {
            setCountdown(false);
            setTimeLeft(60);
            setTextSent(false);
          return;
        }
        if(countdown && timeLeft > 0){
            timerId = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
              }, 1000);
        }
        return () => {clearTimeout(timerId)};
    }, [countdown, timeLeft]);

    useEffect(() => {
        if (typeof lastFour === 'string') { 
            // Extract the number using RegExp
            const matchedNumbers = lastFour.match(/\d+/); // finds a sequence of digits in the string
            if (matchedNumbers) {
                setNumberEnding(matchedNumbers[0]); 
            }
        }
    }, [lastFour]);

    //*NOT WORKING*
    useEffect(() => {
        if (successStatus !== null) {
        }
      }, [successStatus]);

      useEffect(() => {
        if(userData?.step==1){
            requestTwoFactor(false,false); 
        }
      }, [userData]);

       useEffect(() => {
             if(userData?.validationCode){
                (!textStatus && authStatus) && updateUseState(setAuthPage,"currentPage", "authCode")
                textStatus && updateUseState(setAuthPage,"currentPage", "textCode")
             }
       }, [textStatus,authStatus]);
    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmission();
        }
    };

    function handleSubmission(){
        if(activity.currentPage === "login"){
            if(eligibility(["password", "email"])){
                validateUser();
            }
        }

        if(activity.currentPage === "reset"){
            if(authPage.currentPage === "authCode"){
                if(eligibility(["email", "authCode"])){
                    requestTwoFactor(true);
                }
            }
            else if(authPage.currentPage === "textCode"){
                if(eligibility(["email", "textCode"])){
                    requestTwoFactor(true);
                }
            }           
        }

        if(activity.currentPage === "create" && createPassword){
            if (eligibility(["password", "passwordConfirmation"]) && requirementStatus("all")) {
                setCreatePassword(false);
            }else{
                setErrorStatus("Invaid Password");
            }
        }

        if(activity.currentPage === "create" && !createPassword && !enterText){
            if (eligibility(["password", "passwordConfirmation"]) && requirementStatus("all")) {
                //attemptTwoFactor(ac);
                if(eligibility(["authCode"])){
                    attemptTwoFactor(ac);
                }else if
                (eligibility(["textCode"])){
                    attemptTwoFactor(ac);
                }
            }
        }

        if(activity.currentPage === "create" && !createPassword && enterText){
            if(authChecked && !textChecked){
                requestNewAuth(true);
                setEnterText(false);  
                setErrorStatus();
            }
            else if(authChecked && textChecked && isValidPhoneNumber(data?.phoneNumber)){
                requestTwoFactor(false, true);
                requestNewAuth(true);
                setEnterText(false);
                setErrorStatus();
            }
            else if(!authChecked && textChecked && isValidPhoneNumber(data?.phoneNumber)){
                requestNewAuth(true);
                setErrorStatus();
            }
            else{
                setErrorStatus("Invaid Phone Number");
            }
        }

        if(activity.currentPage === "existingReset"){
            if (eligibility(["password", "passwordConfirmation", "emailCode"]) && requirementStatus("all", true)) {
                attemptTwoFactor(data.emailCode, data.email, true);
            }
        }

        if(activity.currentPage === "twoFactor"){
            if(authPage.currentPage === "authCode"){
                if(eligibility(["authCode"])){
                    attemptTwoFactor(data.emailCode, data.email, false, false);
                }
            }
            else if(authPage.currentPage === "textCode"){
                if(eligibility(["textCode"])){
                    attemptTwoFactor(data.emailCode, data.email, false, true);
                }
            }
            
        }

        if(activity.currentPage === "setupTwoFactor"){
            if (eligibility(["emailCode"])) {
                if(eligibility(["authCode"])){
                    attemptTwoFactor(data.emailCode, data.email, false);
                }else if
                (eligibility(["textCode"])){
                    attemptTwoFactor(data.emailCode, data.email, false, true);
                }
            }
        }

        if(activity.currentPage === "termsOfService"){
            // Send date to backend
            //setCurrentDate(new Date());
            // Route to send TOS checked          
            sendTOSChecked();
        }
    }

    const updateUseState = (setter, attr, value) => {
        setter((prev) => ({
          ...prev,
          [attr]: value,
        }));
    }

    function eligibility(attrs) {
        for (const attr of attrs) {
            if (data[attr] === '') {
                return false;
            }
        }
        return true;
    }

    function isValidPhoneNumber(phoneNumber) {
        // Regular expression for phone number validation
        const phoneRegex = /^(\+?\d{1,2})?[-.\s]?(\(?\d{3}\)?)[-.\s]?\d{3}[-.\s]?\d{4}$/;
    
        // Test the phone number against the regex
        return phoneRegex.test(phoneNumber);
    }

    function requirementStatus(req, includeAuth) {
        const password = data?.password || '';
        const passwordConfirmation = data?.passwordConfirmation || '';
      
        const checks = {
            length: password.length >= 8,
            capitalLetter: /[A-Z]/.test(password),
            lowerCase: /[a-z]/.test(password),
            symbol: /[\W_]/.test(password),
            number: /\d/.test(password),
            matchingPasswords: password !== '' && password === passwordConfirmation,
        };

        if (req === "all") {
            return (
                checks.length &&
                checks.capitalLetter &&
                checks.lowerCase &&
                checks.symbol &&
                checks.number &&
                checks.matchingPasswords &&
                (includeAuth ? data.emailCode!== '' : true)
            );
        }

        if (req === "newAccount") {
            return (
                checks.length &&
                checks.capitalLetter &&
                checks.lowerCase &&
                checks.symbol &&
                checks.number &&
                checks.matchingPasswords &&
                (includeAuth ? ((data.authCode !== '' && authChecked) || (data.textCode !== '' && textChecked)) : true)
            );
        }

        if (req.includes("length") && !checks.length) {
            return false;
        }

        if (req.includes("capitalLetter") && !checks.capitalLetter) {
            return false;
        }

        if (req.includes("lowerCase") && !checks.lowerCase) {
            return false;
        }

        if (req.includes("symbol") && !checks.symbol) {
            return false;
        }

        if (req.includes("number") && !checks.number) {
            return false;
        }

        if (req.includes("matchingPasswords") && !checks.matchingPasswords) {
            return false;
        }

        return true;
    }

    function passwordRequirements() {
        return (
            <div className="prompt alt f cC g">
                <div className="f cC bold">
                    Password must include:
                </div>
                {requirements.map((requirement) => (
                    <div className="g f cL fR" key={requirement.key}>
                        <div className={`f cC${requirementStatus(requirement.key) ? " complete" : ''}`}>
                            {requirementStatus(requirement.key) ?
                                <CheckRoundedIcon />
                                :
                                <HighlightOffRoundedIcon />
                            }
                        </div>
                        <div className="f s cL">
                            {requirement.label}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    function requestNewAuth(accountCreation){
        setLoadingStatus(true);
        let paramVals = {
          "type" : accountCreation ? "accountCreation" : "default",
          "email" : accountCreation ? e : data?.email,
          "validationCode" : userData?.validationCode,
          "emailCode": accountCreation ? ac : data?.emailCode,
          "phoneNumber": data?.phoneNumber
        }
        session?.env?.functions?.buildFetchRequest("newAuthSecret", paramVals)
          .then(response => response.json())
          .then(resData => {
            
            if(resData.status === 200){
                setLoadingStatus(false);
                setAuthData({
                    qr: resData.qr,
                    secret: resData.secret,
                    step: 1
                });
                if(accountCreation && enterText){
                    requestTwoFactor(false, true);
                    setEnterText(false);
                }
                if(!accountCreation){
                    updateUseState(setActivity, "currentPage", "setupTwoFactor");
                }
            }else{
                setLoadingStatus(false);
                setErrorStatus(resData.message);
            }
          });
    }

    function requestTwoFactor(forgotPassword, text){
        !text && setLoadingStatus(true);
        text && setTextLoading(true);

        const paramVals = {
            email : e ? e : data?.email,
            type : forgotPassword ? "passwordReset" : text ? "text" : "email",
            ...(forgotPassword ? {} : { password : data?.password }),
            authCode: data?.authCode,
            textCode: data?.textCode
        }
        session?.env?.functions?.buildFetchRequest("twoFactor", paramVals)
            .then(response => response.json())
            .then(resData => {    
                setLoadingStatus(false);
                setTextLoading(false);
                if(resData.status === 200){
                    if(text){
                        setFirstText(true);
                        setTextSent(true);
                        setCountdown(true);
                    }   
                    setLastFour(resData.textResponse);
                    if(!text){
                        if(forgotPassword){
                            updateUseState(setActivity, "currentPage", "existingReset");
                        }else{
                            requestNewAuth(false);
                        }                       
                    }
                }else{
                    setErrorStatus(resData.message);
                }
        });
    }

    if (ac && e && activity.currentPage !== "create") {
        updateUseState(setActivity, "currentPage", "create");
        if(data?.password == ''){
            //requestNewAuth(true);
        } 
    }

    function attemptTwoFactor(authorization, email, reset, text){
        setLoadingStatus(true);
        let paramVals = {
            "authType" : !email ? "accountCreation" : e || reset ? "passwordReset" : (authData?.step === 1) ? "addAuth" : text ? "text" : "authenticator",
            "emailCode": authorization?.trim(),
            "authCode": data?.authCode,
            "textCode": data?.textCode,
            ...userData,
            ...((e || reset) && {
                "email": e, 
                "password":  data?.password,
                "retypePassword": data?.passwordConfirmation,
            }),
            ...(email && {
                "email": email,
            })
        };

        session?.env?.functions?.buildFetchRequest("verifyAuth", paramVals)
          .then(response => response.json())
          .then(resData => {
            setLoadingStatus(false);
            if(resData.status === 200){
                const sessionUser = {
                    // ...((userData && Object.keys(userData).length > 0) ? userData : resData.authReply),
                    ...resData.authReply,
                    sessionToken : resData.sessionToken,
                    sessionID: resData.sessionID,
                    application : "lT"
                }
                const sessionAccount = {
                    ...resData.accountData,
                }
                //setSuccessStatus(resData.message);
                if(hasTOS || reset){
                    setUserData(sessionUser);
                    session?.user?.functions?.loadUser(sessionUser);
                    session?.account?.functions?.loadAccount(sessionAccount);
                    session?.user?.functions?.updateUserData(["currentActivity"], ["dashboard"]);
                    navigate(sessionStorage.getItem('redirectLink') ?? "/dashboard");
                    sessionStorage.removeItem('redirectLink');
                    session?.user?.functions?.maintainSession();
                }
                else{
                    setUserData(sessionUser);
                    setAccountData(sessionAccount);
                    navigate("/");
                    updateUseState(setActivity, "currentPage", "termsOfService");
                    setTOSChecked(false);
                    
                }
                
            }else{
                setErrorStatus(resData.message);
            }
        });
    }

    function sendTOSChecked(){
        setLoadingStatus(true);
        let paramVals = {
          "TOS" : TOSChecked
        }
        session?.env?.functions?.buildFetchRequest("user/TOS", paramVals, null, null, null, userData?.sessionID)
          .then(response => response.json())
          .then(resData => {
            if(resData.status === 200){
                setLoadingStatus(false);
                session?.user?.functions?.loadUser(userData);
                session?.account?.functions?.loadAccount(accountData);
                session?.user?.functions?.updateUserData(["currentActivity"], ["dashboard"]);
                navigate(sessionStorage.getItem('redirectLink') ?? "/dashboard");
                sessionStorage.removeItem('redirectLink');
                session?.user?.functions?.maintainSession(new Date());
            }else{
                setLoadingStatus(false);
                setErrorStatus(resData.message);
            }
        });
    }

    // Direct existing user to 2 factor page
    function validateUser(){
        setLoadingStatus(true);
        let paramVals = {
            "email" : data?.email,
            "password" : data?.password,
        };

        session?.env?.functions?.buildFetchRequest("validate", paramVals)
          .then(response => response.json())
          .then(resData => {
            setLoadingStatus(false);
            if(resData.status === 200){
                setAuthData({
                    step: 0
                });  
                setTextStatus(resData.canSendText);
                setAuthStatus(!resData.needsAuthenticator);
                setHasTOS(resData.hasTOS);
                
                if(resData.canSendText){
                    updateUseState(setAuthPage, "currentPage", "textCode")
                }else if(!resData.needsAuthenticator){
                    updateUseState(setAuthPage, "currentPage", "authCode")
                }

                if(resData.hasSecret && (!resData.needsAuthenticator || resData.canSendText)){
                    setUserData({
                        ...resData.profileData,
                        validationCode : resData.validationCode,
                    });                    
                    updateUseState(setActivity, "currentPage", "twoFactor");
                }else{
                    setUserData({
                        ...resData.profileData,
                        validationCode : resData.validationCode,
                        step: 1,
                    });
                }
            }else{
                setErrorStatus(resData.message);
            }
        });
    }

    useEffect(() => {
        if (!ac && !e) {
            if (!["/", ""].includes(currentPath) || params.size > 0) {
                navigate("/");
            }
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [ac, e]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };   
    }, [data, activity]);

    useEffect(() => {
        setErrorStatus();
    }, [activity.currentPage]);

    useEffect(() => {
        const loadHandler = initialize?.loadHandler;
      
        if (loadHandler && Object.values(loadHandler).some((value) => value === true)) {
          initialize.resetLoaders(session);
        }

    }, [initialize?.loadHandler, session]);

    return (
        <div className="loginBoard g cC">
            <div className={"loginBlock moduleBlock bR fC g cC oH " + activity.currentPage}>
                <div className="header dG g cC">
                    <img className="f cC" src={Logo}/>
                    {/* <div className="f cL bold">
                        ABL Tech
                    </div> */}
                </div>
                <div className='messageGrid f g'>
                    <div className="message gC2 f cC">
                        {activity.currentPage === "login" && "Welcome"}
                        {activity.currentPage === "reset" && "Password Reset"}
                        {activity.currentPage === "create" && "Account Setup"}
                        {activity.currentPage === "twoFactor" && "Authorize"}
                        {activity.currentPage === "existingReset" && "Password Reset"}
                        {activity.currentPage === "setupTwoFactor" && "Set Up Two Factor"}
                        {activity.currentPage === "termsOfService" && "Terms of Service"}
                    </div>
                    <div className='gC3 cC '>
                        <a href="mailto:help@abltech.com" target="_blank" rel="noopener noreferrer" className='link helpLink p'>Help?</a>
                    </div>
                </div>
                <div className="divider">
                </div>
                <div className="wrap f">
                    <div className={"form g f cC fC dP " + activity.currentPage}>
                        {activity.currentPage === "login" &&
                            <>
                                <div className="fields g">
                                    <div className="field g cC">
                                        <div className={`f bC${eligibility(["email"]) ? " active" : ''}`}>
                                            <EmailRoundedIcon/>
                                        </div>
                                        <InputField
                                            value={data?.email}
                                            placeholder={"Email"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="email"
                                            setter={setData}
                                        />
                                    </div>
                                    <div className="field g cC">
                                        <div className={`f bC${eligibility(["password"]) ? " active" : ''}`}>
                                            <PasswordRoundedIcon/>
                                        </div>
                                        <InputField
                                            value={data?.password}
                                            isPassword={true}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            placeholder="Password"
                                            name="password"
                                            setter={setData}
                                        />
                                    </div>
                                </div>
                                {errorStatus &&
                                    <div className="errorStatus dP gR2">
                                        {errorStatus}
                                    </div>
                                }
                                {successStatus &&                   
                                    <div className="successStatus dP gR2">
                                        {successStatus}
                                    </div>
                                }
                                <div className="options f cC g gR3 fC">
                                    <div className="passwordReset cC dP p bR" onClick={()=>{updateUseState(setActivity, "currentPage", "reset");updateUseState(setAuthPage, "currentPage", "chooseTwoFactor")}}>
                                        Forgot password?
                                    </div>
                                    {loadingStatus ?
                                        <div className="loginBtn btn cC">
                                            <CircularProgress isIndeterminate size='45px' color='#104735'/>
                                        </div>
                                    :
                                        <div
                                            className={`loginBtn btnWIcon g bR e f p${eligibility(["password", "email"]) ? " active" : " inactive"}`}
                                            onClick={()=>{handleSubmission()}}
                                        >
                                            <div className="f cC gC2">
                                                Login
                                            </div>
                                            <div className="f cC">
                                                <LoginRoundedIcon/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }                     
                        {activity.currentPage === "reset" &&
                            <> 
                                <div className="f cL s g optionsRow">
                                    <div
                                        className="goBack g cC p s e gC1"
                                        onClick={()=>{authPage.currentPage === "chooseTwoFactor" ? updateUseState(setActivity, "currentPage", "login") : updateUseState(setAuthPage, "currentPage", "chooseTwoFactor"); navigate(location.pathname); setErrorStatus(null);}}
                                    >
                                        <div className="back btn cC gC1">
                                            <ArrowBackRoundedIcon/>
                                        </div>
                                        Go Back
                                    </div>    
                                    {/*Additional 2 factor options*/}

                                        {/* <div style={{ position: 'relative', width:'150px', marginRight:'15px'}} className='optionsStyle goBack g cC p s e gC3'>
                                            <div className='twoFactorOptions' style={{marginLeft:'33px'}} onClick={toggleOptions}>
                                                Alternate Two Factor Option
                                            </div>
                                            {showOptions && (
                                                <div className='optionsMenu f'>
                                                    {authPage.currentPage !== "textCode" && <a style={{marginBottom:'.5px'}} onClick={()=>{updateUseState(setAuthPage,"currentPage", "textCode");toggleOptions();setData({textCode:'', email:'', password:'', authCode:''});setErrorStatus(null);}}>Text message <br/><TextsmsIcon fontSize='small'/></a>}
                                                    {authPage.currentPage !== "authCode" && <a onClick={()=>{updateUseState(setAuthPage,"currentPage", "authCode");toggleOptions();setData({textCode:'', email:'', password:'', authCode:''});setErrorStatus(null);}}>Mobile authenticator <br/><PhonelinkLockIcon fontSize='small'/></a>}
                                                </div>
                                            )}
                                        </div> */}

                                </div>
                                {authPage.currentPage !== "chooseTwoFactor" && 
                                    <div className="fields g">
                                        <div className="field g cC">
                                            <div className={`f bC${eligibility(["email"]) ? " active" : " inactive"}`}>
                                                <EmailRoundedIcon/>
                                            </div>
                                            <InputField
                                                value={data?.email}
                                                placeholder={"Email"}
                                                functionPointer={session?.env?.functions?.updateInput}
                                                name="email"
                                                setter={setData}
                                            />
                                        </div>
                                    </div>
                                }
                                {authPage.currentPage === "textCode" && authPage.currentPage !== "chooseTwoFactor" &&
                                    <div className='f cC'>
                                        {(!firstText && !textLoading) ? 
                                            <>
                                                {eligibility(["email"]) ?
                                                    <div className='sendCode f g cC' onClick={()=>{requestTwoFactor(false, true);setErrorStatus(null);}}>
                                                        <div className='gC1'>Send Text</div> <div className='gC2 cC'><SendIcon style={{ fontSize: '18px' }}/></div>
                                                    </div>
                                                    :
                                                    <div className='sendCodeInactive f g cC'>
                                                        <div className='gC1'>Send Text</div> <div className='gC2 cC'><SendIcon style={{ fontSize: '18px' }}/></div>
                                                    </div>
                                                }                                                    
                                            </>
                                        :
                                            <div className='g f cC bR twoFactorSendCodeBox'> 
                                                <div className='gC1 gR1 f cC g' style={{fontSize:'18px'}}>
                                                    {/* {(!firstText && !textLoading) && ("Click to send text code:")} */}
                                                    <div className='gC1 gR1 f cR'>
                                                    {textLoading && ("Sending Text ")} 
                                                    </div> 
                                                    <div className='gC2 gR1 f cL'>
                                                        {textLoading && <CircularProgress  isIndeterminate size='15px' color='#104735'/>}
                                                    </div>
                                                    {(firstText && !textLoading) && <div style={{marginLeft: '15px'}}>Code sent to xxx-xxx-{numberEnding}</div>}
                                                </div>

                                            </div> 
                                        }
                                            
                                    </div>
                                }
                                {authPage.currentPage !== "chooseTwoFactor" && 
                                    <>
                                    {(authPage.currentPage === "textCode" && (textSent || firstText)) &&
                                        <div className="fields g">
                                            <div className="textField g cC">
                                                {(authPage.currentPage === "textCode" && (textSent || firstText)) && 
                                                    <div className={`f bC svgIcon${eligibility(["textCode"]) ? " active" : " inactive"}`}>
                                                        <TextsmsIcon/>
                                                    </div>
                                                }
                                                {(authPage.currentPage === "textCode" && (textSent || firstText)) && 
                                                    <InputField
                                                        value={data?.textCode}
                                                        placeholder={"Text Code"}
                                                        functionPointer={session?.env?.functions?.updateInput}
                                                        name="textCode"
                                                        setter={setData}
                                                        // readOnly={!textSent}
                                                    />
                                                }
                                                {(!firstText && !textLoading) ? 
                                                    <div className='f bC s'>
                                                        <div className='twoFactorSendCode f g cC' onClick={()=>{requestTwoFactor(false, true);setErrorStatus(null);}}>
                                                            <div className='gC1'>Send Text</div> <div className='gC2 cC'><SendIcon style={{ fontSize: '18px' }}/></div>
                                                        </div>
                                                    </div>
                                                :
                                                    <div className='f bC s'>
                                                        {(firstText && !textSent && !textLoading) && 
                                                            <div className='twoFactorSendCode f g cC' onClick={()=>{requestTwoFactor(false, true);setErrorStatus(null);}}>
                                                                <div className='gC1'>Resend</div> <div className='gC2 cC'><SendIcon style={{ fontSize: '18px' }}/></div>
                                                            </div>}
                                                        {textLoading && <div className='twoFactorSentCode f bR'>Code Sent!</div>}
                                                        {(textSent && !textLoading) && <div className='twoFactorResendCode f'>Resend in {timeLeft}s</div>}
                                                    </div> 
                                                } 
                                            </div>
                                        </div>
                                    }
                                    {authPage.currentPage === "authCode" &&
                                        <div className="fields g">
                                            
                                            <div className="field g cC">
                                                {authPage.currentPage == "authCode" && 
                                                    <div className={`f bC${eligibility(["authCode"]) ? " active" : ''}`}>
                                                        <PhonelinkLockIcon/>
                                                    </div>
                                                }
                                                {authPage.currentPage == "authCode" &&
                                                    <InputField
                                                        value={data?.authCode}
                                                        placeholder={"Authorization Code"}
                                                        functionPointer={session?.env?.functions?.updateInput}
                                                        name="authCode"
                                                        setter={setData}
                                                    />
                                                }                                           
                                            </div>
                                        </div>
                                    }
                                    </>
                                }
                                <div className="f cC prompt">
                                    {authPage.currentPage === "authCode" &&
                                        <div>
                                            Provide your email and mobile authentication code that is linked to your account.
                                            We'll send a confirmation email with a reset password code.
                                        </div>
                                    }
                                    {authPage.currentPage === "textCode" &&
                                        <div>
                                            Provide your email and then the text code that is sent to your phone number. We'll send a confirmation email with a reset password code.
                                        </div>
                                    }
                                    {authPage.currentPage === "chooseTwoFactor" &&
                                        <div>
                                            Select a Two Factor option to reset your password
                                        </div>
                                    }
                                </div>
                                {authPage.currentPage === "chooseTwoFactor" && 
                                    <div className='twoFactorChoices s e g'>
                                        <div className='twoFactorChoice gC1 dP dG g cC' onClick={()=>{updateUseState(setAuthPage, "currentPage", "textCode")}}>Text Message <br/><TextsmsIcon fontSize='large'/></div>
                                        <div className='twoFactorChoice gC2 dP dG g cC' onClick={()=>{updateUseState(setAuthPage, "currentPage", "authCode")}}>Authenticator App <PhonelinkLockIcon fontSize='large'/></div>
                                    </div>
                                }
                                {authPage.currentPage !== "chooseTwoFactor" && 
                                    <div className="f cC fR">
                                        <div className="lockIcon cC">
                                            {authPage.currentPage === "authCode" && 
                                                    (eligibility(["email", "authCode"]) ? 
                                                        <LockTwoToneIcon/>
                                                    :
                                                        <LockOpenRoundedIcon/>)
                                            }
                                            {authPage.currentPage === "textCode" && 
                                                (eligibility(["email", "textCode"]) ? 
                                                    <LockTwoToneIcon/>
                                                :
                                                    <LockOpenRoundedIcon/>)
                                            }
                                        </div>
                                    </div>
                                }
                                {errorStatus &&
                                    <div className="errorStatus dP">
                                        {errorStatus}
                                    </div>
                                }
                                {authPage.currentPage !== "chooseTwoFactor" && 
                                    <div className="options f cC g fC">
                                        {loadingStatus ?
                                            <div className="loginBtn btn cC">
                                                <CircularProgress  isIndeterminate size='45px' color='#104735'/>
                                            </div>
                                        :
                                            <div
                                                className={`loginBtn btnWIcon f g bR e 
                                                p${
                                                    (authPage.currentPage === "authCode" && eligibility(["email", "authCode"])) ||
                                                    (authPage.currentPage === "textCode" && eligibility(["email", "textCode"]))
                                                    ? " active"
                                                    : " inactive"
                                                }`}
                                                onClick={()=>{handleSubmission()}}
                                            >
                                                <div className="f cC gC2">
                                                    Send Email
                                                </div>
                                                <div className="f cC">
                                                    <ForwardToInboxRoundedIcon/>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </>
                        }
                        {(activity.currentPage === "existingReset") &&
                            <>
                                <div className="f cL s">
                                    <div
                                        className="goBack g cC p s e"
                                        onClick={()=>{updateUseState(setActivity, "currentPage", "login"); navigate(location.pathname);}}
                                    >
                                        <div className="back btn cC">
                                            <ArrowBackRoundedIcon/>
                                        </div>
                                        Go Back
                                    </div>
                                </div>
                                <div className="fields g">
                                    {activity.currentPage === "existingReset" &&
                                        <div className="field g cC">
                                            <div className={`f bC${eligibility(["emailCode"]) ? " active" : ''}`}>
                                                <MailLockRoundedIcon/>
                                            </div>
                                            <InputField
                                                value={data?.emailCode}
                                                placeholder={"Authorization Code"}
                                                functionPointer={session?.env?.functions?.updateInput}
                                                name="emailCode"
                                                setter={setData}
                                            />                                    
                                        </div>
                                    }
                                    <div className="field g cC">
                                        <div className={`f bC${eligibility(["password"]) ? " active" : ''}`}>
                                            <PasswordRoundedIcon/>
                                        </div>
                                        <InputField
                                            value={data?.password}
                                            isPassword={true}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            placeholder="Password"
                                            name="password"
                                            setter={setData}
                                        />
                                    </div>
                                    <div className="field g cC">
                                        <div className={`f bC${eligibility(["passwordConfirmation"]) ? " active" : ''}`}>
                                            <PasswordRoundedIcon/>
                                        </div>
                                        <InputField
                                            value={data?.passwordConfirmation}
                                            isPassword={true}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            placeholder="Confirm Password"
                                            name="passwordConfirmation"
                                            setter={setData}
                                        />
                                    </div>
                                </div>
                                {passwordRequirements()}
                                <div className="f cC fR">
                                    <div className="lockIcon cC">
                                        {
                                            requirementStatus("all", activity.currentPage === "existingReset") ? 
                                                <LockTwoToneIcon/>
                                            :
                                                <LockOpenRoundedIcon/>
                                        }
                                    </div>
                                </div>
                                {errorStatus &&
                                    <div className="errorStatus dP">
                                        {errorStatus}
                                    </div>
                                }
                                <div className="options f cC g fC">
                                    {loadingStatus ?
                                        <div className="loginBtn btn cC">
                                            <CircularProgress  isIndeterminate size='45px' color='#104735'/>
                                        </div>
                                    :
                                        <div 
                                            className={`loginBtn btnWIcon g bR e f p${eligibility(["emailCode","password", "confirmPassword"]) &&
                                            requirementStatus("all", activity.currentPage === "existingReset") ? " active" : " inactive"}`}
                                            onClick={()=>{handleSubmission()}}
                                        >
                                            <div className="f cC gC2">
                                                Login
                                            </div>
                                            <div className="f cC">
                                                <LoginRoundedIcon/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {(activity.currentPage === "setupTwoFactor") &&
                            <>  
                                {/* <div className="f cL s">
                                    <div
                                        className="goBack g cC p s e"
                                        onClick={()=>{updateUseState(setActivity, "currentPage", "login"); navigate(location.pathname);}}
                                    >
                                        <div className="back btn cC">
                                            <ArrowBackRoundedIcon/>
                                        </div>
                                        Go Back
                                    </div>
                                </div>    */}
                                {textStatus ?
                                    <div className='cC bold prompt' style={{fontSize: '16px', width:'349px'}}>
                                        Please set up your mobile authenticator or text messages for two factor login: 
                                    </div>
                                    :
                                    <div className='cC bold prompt' style={{fontSize: '16px', width:'349px'}}>
                                        Please set up your mobile authenticator for two factor login: 
                                    </div>
                                }
                                
                                <div className='cL bold' style={{fontSize: '15px', width:'349px'}}>
                                        Enter the code sent to your email: 
                                </div>
                                <div className="fields g" style={{marginBottom:'0px'}}>
                                    <div className="field g cC" style={{marginBottom:'40px'}}>
                                        <div className={`f bC${eligibility(["emailCode"]) ? " active" : ''}`}>
                                            <MailLockRoundedIcon/>
                                        </div>
                                        <InputField
                                            value={data?.emailCode}
                                            placeholder={"Email Code"}
                                            functionPointer={session?.env?.functions?.updateInput}
                                            name="emailCode"
                                            setter={setData}
                                        />                                    
                                    </div>
                                </div>

                                {textStatus ?
                                    <>
                                    <div className='cL bold' style={{fontSize: '15px', width:'349px'}}>
                                            Choose a two factor option: 
                                    </div>

                                    <div className='g cL'>
                                        <Checkbox isChecked={textChecked} onChange={handleTextChecked} >Text Message/SMS</Checkbox>                               
                                    </div>

                                    {textChecked &&
                                            <>
                                                <div className='f cC dP'>
                                                    {(!firstText && !textLoading) ? 
                                                        <>
                                                            <div className='sendCode f g cC' onClick={()=>{requestTwoFactor(false, true);setErrorStatus(null);}}>
                                                                <div className='gC1'>Send Code</div> <div className='gC2 cC'><SendIcon style={{ fontSize: '18px' }}/></div>
                                                            </div>                                            
                                                        </>
                                                    :
                                                        <div className='g f cC bR twoFactorSendCodeBox'> 
                                                            <div className='gC1 gR1 f cC g' style={{fontSize:'18px'}}>
                                                                {/* {(!firstText && !textLoading) && ("Click to send text code:")} */}
                                                                <div className='gC1 gR1 f cR'>
                                                                    {textLoading && ("Sending Text ")} 
                                                                </div> 
                                                                <div className='gC2 gR1 f cL'>
                                                                    {textLoading && <CircularProgress  isIndeterminate size='15px' color='#104735'/>}
                                                                </div>
                                                                {(firstText && !textLoading) && <div style={{marginLeft: '15px'}}>Code sent to xxx-xxx-{numberEnding}</div>}
                                                            </div>
            
                                                        </div> 
                                                    }
                                                        
                                                </div>
                                            
                                                {(textSent || firstText) &&
                                                    <div className="fields g">
                                                        <div className="textField g cB">
                                                            {(textSent || firstText) && 
                                                                <div className={`f bC svgIcon${eligibility(["textCode"]) ? " active" : " inactive"}`}>
                                                                    <TextsmsIcon/>
                                                                </div>
                                                            }
                                                            {(textSent || firstText) && 
                                                                <InputField
                                                                    value={data?.textCode}
                                                                    placeholder={"Text Code"}
                                                                    functionPointer={session?.env?.functions?.updateInput}
                                                                    name="textCode"
                                                                    setter={setData}
                                                                    // readOnly={!textSent}
                                                                />
                                                            }
                                                            {(!firstText && !textLoading) ? 
                                                                <div className='f bC s'>
                                                                    <div className='twoFactorSendCode f g cC' onClick={()=>{requestTwoFactor(false, true);setErrorStatus(null);}}>
                                                                        <div className='gC1'>Send Text</div> <div className='gC2 cC'><SendIcon style={{ fontSize: '18px' }}/></div>
                                                                    </div>
                                                                </div>
                                                            :
                                                                <div className='f bC s'>
                                                                    {(firstText && !textSent && !textLoading) && 
                                                                        <div className='twoFactorSendCode f g cC' onClick={()=>{requestTwoFactor(false, true);setErrorStatus(null);}}>
                                                                            <div className='gC1'>Resend</div> <div className='gC2 cC'><SendIcon style={{ fontSize: '18px' }}/></div>
                                                                        </div>}
                                                                    {textLoading && <div className='twoFactorSentCode f bR'>Code Sent!</div>}
                                                                    {(textSent && !textLoading) && <div className='twoFactorResendCode f'>Resend in {timeLeft}s</div>}
                                                                </div> 
                                                            } 
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }

                                    <>
                                        <div className="cL" style={{fontSize:'16px', color:'grey', marginTop: marginValue, marginBottom: marginValue}}>- OR -</div>
                                        <div className='g cL'>
                                            <Checkbox isChecked={authChecked} onChange={handleAuthChecked}>Mobile Authenticator</Checkbox>                                   
                                        </div>   
                                    </>

                                    {authChecked && 
                                        <div className='twoFactorSetup g'>
                                            <div className='cC qrInfo' onClick={handleQrInfo}>
                                                How to set up mobile authenticator {qrInfoToggle ? <ArrowDropUpIcon fontSize='medium'/> : <ArrowDropDownIcon fontSize='medium'/>}
                                            </div>
                                            {qrInfoToggle && 
                                                <div className='qrInfoBox cC g'>
                                                    <div className='cL'>
                                                        1. Download an authenticator app on your mobile device
                                                        <br/>2. Scan the following QR code in your authenticator app
                                                        <br/>3. Enter in the numbered code that you receive
                                                        <br/><br/>NOTE: Keep this code safe in your app for future logins
                                                    </div>
                                                    <br/>
                                                    <div className='bold'>Recommended authenticator apps:</div>
                                                    <div className='g'>
                                                        
                                                        <div className='gC1' style={{marginRight:'20px'}}>Microsoft Authenticator</div>
                                                        <div className='gC2'>Google Authenticator</div>
                                                    </div>
                                                    <div className='g'>
                                                        <a className="gC1" href="https://www.microsoft.com/en-us/security/mobile-authenticator-app#overview" target="_blank" rel="noopener noreferrer">
                                                            <img className="microsoftLogo" src={MicrosoftLogo} style={{ width: '50px', height: '50px', marginRight:'90px'}}></img>
                                                        </a>
                                                        <a className="gC2" href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer">
                                                            <img className="googleLogo" src={GoogleLogo} style={{ width: '50px', height: '50px'}}></img>
                                                        </a>                               
                                                    </div>
                                                </div>
                                            }

                                            <div className='f qrBox g cC'>
                                                <img src={authData?.qr} style={{ width: '180px', height: '180px', marginTop:'10px'}}></img> 
                                                <div className='bold' style={{fontSize:'12px', paddingBottom:'2px'}}>
                                                        Setup Key: {authData?.secret}
                                                </div>
                                                <br/>
                                                <p style={{fontSize:'15px'}}>Scan the QR code in your authenticator app <br/> to receive an authentication code</p>                                     
                                            </div>
        
                                            <div className="fields g" style={{marginTop:'0px'}}>   
                                                <div className="field g cC">
                                                    <div className={`f bC${eligibility(["authCode"]) ? " active" : ''}`}>
                                                        <PhonelinkLockIcon/>
                                                    </div>
                                                    <InputField
                                                        value={data?.authCode}
                                                        functionPointer={session?.env?.functions?.updateInput}
                                                        placeholder="Authentication Code"
                                                        name="authCode"
                                                        setter={setData}
                                                    />
                                                </div>
                                            </div>           
                                        </div>
                                    }
                                    </>
                                    :
                                    <>
                                        <div className='twoFactorSetup g'>
                                            <div className='cC qrInfo' onClick={handleQrInfo}>
                                                How to set up mobile authenticator {qrInfoToggle ? <ArrowDropUpIcon fontSize='medium'/> : <ArrowDropDownIcon fontSize='medium'/>}
                                            </div>
                                            {qrInfoToggle && 
                                                <div className='qrInfoBox cC g'>
                                                    <div className='cL'>
                                                        1. Download an authenticator app on your mobile device
                                                        <br/>2. Scan the following QR code in your authenticator app
                                                        <br/>3. Enter in the numbered code that you receive
                                                        <br/><br/>NOTE: Keep this code safe in your app for future logins
                                                    </div>
                                                    <br/>
                                                    <div className='bold'>Recommended authenticator apps:</div>
                                                    <div className='g'>
                                                        
                                                        <div className='gC1' style={{marginRight:'20px'}}>Microsoft Authenticator</div>
                                                        <div className='gC2'>Google Authenticator</div>
                                                    </div>
                                                    <div className='g'>
                                                        <a className="gC1" href="https://www.microsoft.com/en-us/security/mobile-authenticator-app#overview" target="_blank" rel="noopener noreferrer">
                                                            <img className="microsoftLogo" src={MicrosoftLogo} style={{ width: '50px', height: '50px', marginRight:'90px'}}></img>
                                                        </a>
                                                        <a className="gC2" href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer">
                                                            <img className="googleLogo" src={GoogleLogo} style={{ width: '50px', height: '50px'}}></img>
                                                        </a>                               
                                                    </div>
                                                </div>
                                            }
                                            <div className='f qrBox g cC'>
                                                <img src={authData?.qr} style={{ width: '180px', height: '180px', marginTop:'10px'}}></img> 
                                                <div className='bold' style={{fontSize:'12px', paddingBottom:'2px'}}>
                                                        Setup Key: {authData?.secret}
                                                </div>
                                                <br/>
                                                <p style={{fontSize:'15px'}}>Scan the QR code in your authenticator app <br/> to receive an authentication code</p>                                     
                                            </div>
                                            <div className="fields g" style={{marginTop:'0px'}}>   
                                                <div className="field g cC">
                                                    <div className={`f bC${eligibility(["authCode"]) ? " active" : ''}`}>
                                                        <PhonelinkLockIcon/>
                                                    </div>
                                                    <InputField
                                                        value={data?.authCode}
                                                        functionPointer={session?.env?.functions?.updateInput}
                                                        placeholder="Authentication Code"
                                                        name="authCode"
                                                        setter={setData}
                                                    />
                                                </div>
                                            </div>           
                                        </div>
                                    </>
                                }
                                <div className="f cC fR g">
                                    <div className="lockIcon cC ">     
                                        { ((eligibility(["authCode", "emailCode"])) || (eligibility(["textCode", "emailCode"]))) ? 
                                            <LockTwoToneIcon/>
                                        :
                                            <LockOpenRoundedIcon/>}
                                    </div>
                                </div>
                                {errorStatus &&
                                    <div className="errorStatus dP">
                                        {errorStatus}
                                    </div>
                                }
                                <div className="options f cC g fC">
                                    {loadingStatus ?
                                        <div className="loginBtn btn cC">
                                            <CircularProgress  isIndeterminate size='45px' color='#104735'/>
                                        </div>
                                    :
                                        <div
                                            className={`loginBtn btnWIcon f g bR e p${
                                                ((eligibility(["authCode", "emailCode"])) || (eligibility(["textCode", "emailCode"]))) 
                                                ? " active"
                                                : " inactive"
                                            }`}
                                            onClick={()=>{handleSubmission()}}
                                        >
                                            <div className="f cC gC2">
                                                Log In
                                            </div>
                                            <div className="f cC">
                                                <KeyRoundedIcon/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {(activity.currentPage === "create") &&
                            <>  
                                {/* <div className="f cL s">
                                    <div
                                        className="goBack g cC p s e"
                                        onClick={()=>{updateUseState(setActivity, "currentPage", "login"); navigate(location.pathname);}}
                                    >
                                        <div className="back btn cC">
                                            <ArrowBackRoundedIcon/>
                                        </div>
                                        Go Back
                                    </div>
                                </div>    */}

                                {createPassword &&
                                <div className='spanAllRows'>
                                    <div className="f cC createPasswordTitle">
                                        Create a Password
                                    </div>
                                    <div className='g gR3 createPasswordForm'>
                                        <div className="fields g">   
                                            <div className="field g cC">
                                                <div className={`f bC${eligibility(["password"]) ? " active" : ''}`}>
                                                    <PasswordRoundedIcon/>
                                                </div>
                                                <InputField
                                                    value={data?.password}
                                                    isPassword={true}
                                                    functionPointer={session?.env?.functions?.updateInput}
                                                    placeholder="Password"
                                                    name="password"
                                                    setter={setData}
                                                />
                                            </div>
                                            <div className="field g cC">
                                                <div className={`f bC${eligibility(["passwordConfirmation"]) ? " active" : ''}`}>
                                                    <PasswordRoundedIcon/>
                                                </div>
                                                <InputField
                                                    value={data?.passwordConfirmation}
                                                    isPassword={true}
                                                    functionPointer={session?.env?.functions?.updateInput}
                                                    placeholder="Confirm Password"
                                                    name="passwordConfirmation"
                                                    setter={setData}
                                                />
                                            </div>      
                                            </div>
                                         
                                            {(data?.password || data?.passwordConfirmation) &&
                                                <div className="">{passwordRequirements()}</div>    
                                            }                          
                                            <div className="f cC fR dP">
                                                <div className="lockIcon cC">
                                                    {
                                                        requirementStatus("newAccount", false) ? 
                                                            <LockTwoToneIcon/>
                                                        :
                                                            <LockOpenRoundedIcon/>
                                                    }
                                                </div>
                                            </div>

                                            {errorStatus &&
                                                <div className="errorStatus dP">
                                                    {errorStatus}
                                                </div>
                                            }
                                            <div className="options f cC g fC">
                                            {loadingStatus ?
                                                <div className="loginBtn btn cC">
                                                    <CircularProgress  isIndeterminate size='45px' color='#104735'/> 
                                                </div>
                                            :
                                                <div
                                                    className={`loginBtn btnWIcon f g bR e ${
                                                        eligibility(["password", "passwordConfirmation"])
                                                        && requirementStatus("newAccount", false) ?
                                                        " active p"
                                                        : " inactive"}`}
                                                    onClick={()=>{handleSubmission()}}
                                                >
                                                    <div className="f cC gC2">
                                                        Continue
                                                    </div>
                                                    <div className="f cC">
                                                        <LoginRoundedIcon/>
                                                    </div>
                                                </div>
                                            }
                                        </div>    
                                    </div>                                 
                                </div>
                                }

                                {enterText && !createPassword &&
                                    <div className='g gR2 dG spanAllRows'>
                                        {/* <div className='twoFactorSetup g'></div> */}
                                        
                                        <div className='cL bold ' style={{fontSize: '15px', width:'349px'}}>
                                            Choose At Least One Two Factor Option To Setup: 
                                        </div>
                                    
                                        <div className='g cL dP'>
                                            <Checkbox isChecked={textChecked} onChange={handleTextChecked} >Text Message/SMS</Checkbox>                               
                                        </div>

                                        {textChecked &&
                                            <>
                                            
                                                {(true) &&
                                                    <div className="fields g cC">
                                                        <div className="textField g cC">
                                                            
                                                            <div className={`f bC svgIcon${eligibility(["phoneNumber"]) ? " active" : " inactive"}`}>
                                                                <TextsmsIcon/>
                                                            </div>
                                                                                                               
                                                            <InputField
                                                                value={data?.phoneNumber}
                                                                placeholder={"Enter Phone Number"}
                                                                functionPointer={session?.env?.functions?.updateInput}
                                                                name="phoneNumber"
                                                                setter={setData}
                                                                // readOnly={!textSent}
                                                            />

                                                        </div>
                                                    </div>
                                                 
                                                }
                                            </>
                                        }
                                        <div className="cL" style={{fontSize:'16px', color:'grey', marginTop: marginValue, marginBottom: marginValue}}>- OR -</div>
                                        <div className='g cL dP'>
                                            <Checkbox isChecked={authChecked} onChange={handleAuthChecked}>Mobile Authenticator</Checkbox>                                   
                                        </div>   

                                        {errorStatus &&
                                            <div className="errorStatus dP">
                                                {errorStatus}
                                            </div>
                                        }
                                        <div className="options f cC g fC">
                                            {loadingStatus ?
                                                <div className="loginBtn btn cC">
                                                    <CircularProgress  isIndeterminate size='45px' color='#104735'/> 
                                                </div>
                                            :
                                                <div
                                                    className={`loginBtn btnWIcon f g bR e${
                                                        ((textChecked && eligibility(["phoneNumber"])) || authChecked)
                                                        ?
                                                        " active p"
                                                        : " inactive"}`}
                                                    onClick={()=>{((textChecked && eligibility(["phoneNumber"])) || authChecked) && handleSubmission()}}
                                                >
                                                    <div className="f cC gC2">
                                                        Next
                                                    </div>
                                                    <div className="f cC">
                                                        <LoginRoundedIcon/>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                           
                                    </div>
                                }

                                {!enterText && !createPassword &&
                                <div className='spanAllRows'>
                                    <div className='twoFactorSetup dP g'>

                                        <div className='cL bold' style={{fontSize: '15px', width:'349px'}}>
                                            Complete Two Factor Setup: 
                                        </div>      
                                            
                                        {textChecked && !authChecked &&
                                            <>
                                                {/* <div className='f cL dP'> */}
                                                    {/* {(!firstText && !textLoading) ? 
                                                        <>
                                                            <div className='sendCode f g cC' onClick={()=>{requestTwoFactor(false, true);setErrorStatus(null);}}>
                                                                <div className='gC1'>Send Code</div> <div className='gC2 cC'><SendIcon style={{ fontSize: '18px' }}/></div>
                                                            </div>                                            
                                                        </>
                                                    : */}
                                                        <div className='g f cC bR twoFactorSendCodeBox'> 
                                                            <div className='gC1 gR1 f cC g' style={{fontSize:'18px'}}>
                                                                {/* {(!firstText && !textLoading) && ("Click to send text code:")} */}
                                                                <div className='gC1 gR1 f cR'>
                                                                    {textLoading && ("Sending Text ")} 
                                                                </div> 
                                                                <div className='gC2 gR1 f cL'>
                                                                    {textLoading && <CircularProgress  isIndeterminate size='15px' color='#104735'/>}
                                                                </div>
                                                                {(firstText && !textLoading) && <div style={{marginLeft: '15px'}}>Code sent to xxx-xxx-{numberEnding}</div>}
                                                            </div>
                                                        </div> 
                                                {/* //      }
                                                        
                                                // </div> */}
                                            
                                               
                                                <div className="fields dP g">
                                                    <div className="textField g cB">
                                                        
                                                        <div className={`f bC svgIcon${eligibility(["textCode"]) ? " active" : " inactive"}`}>
                                                            <TextsmsIcon/>
                                                        </div>
                                                                                                          
                                                        <InputField
                                                            value={data?.textCode}
                                                            placeholder={"Text Code"}
                                                            functionPointer={session?.env?.functions?.updateInput}
                                                            name="textCode"
                                                            setter={setData}
                                                            // readOnly={!textSent}
                                                        />
                                                                                                               
                                                        <div className='f bC s'>
                                                            {(firstText && !textSent && !textLoading) && 
                                                                <div className='twoFactorSendCode f g cC' onClick={()=>{requestTwoFactor(false, true);setErrorStatus(null);}}>
                                                                    <div className='gC1'>Resend</div> <div className='gC2 cC'><SendIcon style={{ fontSize: '18px' }}/></div>
                                                                </div>}
                                                            {textLoading && <div className='twoFactorSentCode f bR'>Code Sent!</div>}
                                                            {(textSent && !textLoading) && <div className='twoFactorResendCode f'>Resend in {timeLeft}s</div>}
                                                        </div> 
                                                        
                                                    </div>
                                                </div>
                                                
                                            </>
                                        }
                                        {/* { textChecked && authChecked &&
                                            <>
                                            <div className="cC" style={{fontSize:'16px', color:'grey', marginTop: marginValue, marginBottom: marginValue}}>
                                                <br/>
                                                ---------------------- AND ----------------------
                                            </div>
                                            <br/>
                                            </>
                                        } */}

                                        {/* { (t !== "true") &&
                                            <div className="cL bold" style={{fontSize:'16px', color:'grey', marginTop: marginValue, marginBottom: marginValue}}>Mobile Authenticator</div>
                                        } */}
                                        
                                        {authChecked &&   
                                            <div className='cC qrInfo' onClick={handleQrInfo}>
                                                How to set up mobile authenticator {qrInfoToggle ? <ArrowDropUpIcon fontSize='medium'/> : <ArrowDropDownIcon fontSize='medium'/>}
                                            </div>
                                        }
                                        {(authChecked && qrInfoToggle) && 
                                            <div className='qrInfoBox cC g'>
                                                <div className='cL'>
                                                    1. Download an authenticator app on your mobile device
                                                    <br/>2. Scan the following QR code in your authenticator app
                                                    <br/>3. Enter in the numbered code that you receive
                                                    <br/><br/>NOTE: Keep this code safe in your app for future logins
                                                </div>
                                                <br/>
                                                <div className='bold'>Recommended authenticator apps:</div>
                                                <div className='g'>
                                                    
                                                    <div className='gC1' style={{marginRight:'20px'}}>Microsoft Authenticator</div>
                                                    <div className='gC2'>Google Authenticator</div>
                                                </div>
                                                <div className='g'>
                                                    <a className="gC1" href="https://www.microsoft.com/en-us/security/mobile-authenticator-app#overview" target="_blank" rel="noopener noreferrer">
                                                        <img className="microsoftLogo" src={MicrosoftLogo} style={{ width: '50px', height: '50px', marginRight:'90px'}}></img>
                                                    </a>
                                                    <a className="gC2" href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer">
                                                        <img className="googleLogo" src={GoogleLogo} style={{ width: '50px', height: '50px'}}></img>
                                                    </a>                               
                                                </div>
                                            </div>
                                        }  
                                        {authChecked &&
                                            <div style={{marginTop:'10px'}} className='f qrBox g cC'>
                                                {loadingStatus ?
                                                <CircularProgress  isIndeterminate size='45px' color='#104735'/> 
                                                :
                                                <img src={authData?.qr} style={{ width: '180px', height: '180px'}}></img>
                                                }
                                                <div className='bold' style={{fontSize:'12px', paddingBottom:'2px'}}>
                                                    Setup Key: {authData?.secret}
                                                </div>
                                                <br/>
                                                <p style={{fontSize:'15px'}}>
                                                    Scan the QR code in your authenticator app to receive<br/> an authentication code or use the Setup Key <br/>
                                                </p>
                                                
                                            </div>
                                        }          
                                        {authChecked &&             
                                            <div className="fields dP g">   
                                                <div className="field g cC">
                                                    <div className={`f bC${eligibility(["authCode"]) ? " active" : ''}`}>
                                                        <PhonelinkLockIcon/>
                                                    </div>
                                                    <InputField
                                                        value={data?.authCode}
                                                        functionPointer={session?.env?.functions?.updateInput}
                                                        placeholder="Enter Authorization Code"
                                                        name="authCode"
                                                        setter={setData}
                                                    />
                                                </div>
                                            </div>                                       
                                        }
                                        
                                    </div>
                                    
                                    {errorStatus &&
                                        <div className="errorStatus dP">
                                            {errorStatus}
                                        </div>
                                    }
                                    <div className="options f cC g fC">
                                        {loadingStatus ?
                                            <div className="loginBtn btn cC">
                                                <CircularProgress  isIndeterminate size='45px' color='#104735'/> 
                                            </div>
                                        :
                                            <div
                                                className={`loginBtn btnWIcon f g bR e${
                                                    eligibility(["password", "passwordConfirmation"]) &&
                                                    (data?.authCode != '' || data?.textCode != '') 
                                                    && requirementStatus("newAccount", activity.currentPage === "create") ?
                                                    " active p"
                                                    : " inactive"}`}
                                                onClick={()=>{handleSubmission()}}
                                            >
                                                <div className="f cC gC2">
                                                    Create Account
                                                </div>
                                                <div className="f cC">
                                                    <LoginRoundedIcon/>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                }
                                
                            </>
                        }
                        {activity.currentPage === "twoFactor" &&
                            <>    
                                <div className="f cL s g optionsRow">
                                    <div
                                        className="goBack g cC p s e gC1"
                                        onClick={()=>{updateUseState(setActivity, "currentPage", "login"); setData({textCode:'', email:'', password:'', authCode:''});navigate(location.pathname);}}
                                    >
                                    <div className="back btn cC gC1">
                                            <ArrowBackRoundedIcon/>
                                        </div>
                                        Go Back
                                    </div>
                                    {/*Additional 2 factor options*/}
                                    {(authStatus && textStatus) && (
                                        <div style={{ position: 'relative', width:'150px', marginRight:'15px' }} className='optionsStyle goBack g cC p s e gC3'>
                                            
                                            <div 
                                                className='twoFactorOptions' 
                                                style={{marginLeft:'33px'}} 
                                                onClick={()=>{ twoFactorPageToggle()}}
                                            >
                                                {authPage.currentPage === "textCode" && <div>Authenticator Sign in <PhonelinkLockIcon /></div>}
                                                {authPage.currentPage === "authCode" && <div>Text Message Sign in <TextsmsIcon /></div>}
                                            </div>
                                            
                                            {showOptions && (
                                                <div className='optionsMenu f'>
                                                    {authPage.currentPage !== "textCode" && <a style={{marginBottom:'.5px'}} onClick={()=>{updateUseState(setAuthPage,"currentPage", "textCode");toggleOptions();setErrorStatus(null);}}>Text message <br/><TextsmsIcon fontSize='small'/></a>}
                                                    {authPage.currentPage !== "authCode" && <a onClick={()=>{updateUseState(setAuthPage,"currentPage", "authCode");toggleOptions();setErrorStatus(null);}}>Mobile authenticator<br/><PhonelinkLockIcon fontSize='small'/></a>}
                                                </div>
                                            )}
                                        </div>  
                                    )}
                                </div>
                                {authPage.currentPage === "textCode" && 
                                    <>
                                    {!(!firstText && !textLoading) && 
                                        <div className='g f cC bR twoFactorSendCodeBox'>
                                            <div className='gC1 gR1 f cR g' style={{fontSize:'18px'}}>
                                                {/* {(!firstText && !textLoading) && ("Click to send text code:")} */}
                                                <div className='gC1 gR1 f cR'>
                                                {textLoading && ("Sending Text ")} 
                                                </div> 
                                                <div className='gC2 gR1 f cL'>
                                                    {textLoading && <CircularProgress  isIndeterminate size='15px' color='#104735'/>}
                                                </div>
                                                {(firstText && !textLoading) && <div style={{marginLeft: '15px'}}>Code sent to xxx-xxx-{numberEnding}</div>}
                                            </div>
                                        </div>  
                                    }
                                    </>                                 
                                }  
                                <div className="fields g">
                                    {authPage.currentPage === "authCode" &&
                                        <div className="field g cC" style={{paddingBottom:'30px'}}>
                                            <div
                                                className={`f bC${
                                                    (authPage.currentPage === "authCode" && eligibility(["authCode"])) 
                                                    ? " active"
                                                    : " inactive"
                                                }`}>
                                                {authPage.currentPage === "authCode" && <PhonelinkLockIcon />}
                                            </div>

                                            {authPage.currentPage === "authCode" && 
                                                <InputField
                                                    value={data?.authCode}
                                                    placeholder={authPage.currentPage === "authCode" && "Authorization Code"}
                                                    functionPointer={session?.env?.functions?.updateInput}
                                                    name="authCode"
                                                    setter={setData}
                                                />
                                            }  
                                        </div>
                                    }
                                    {authPage.currentPage === "textCode" &&
                                        <div className="textField g cC" style={{paddingBottom:'30px'}}>
                                            <div
                                                className={`f bC svgIcon${
                                                    (authPage.currentPage === "textCode" && eligibility(["textCode"]))
                                                    ? " active"
                                                    : " inactive"
                                                }`}>
                                                {authPage.currentPage === "textCode" && <TextsmsIcon/>}
                                            </div>
                                            {authPage.currentPage === "textCode" &&
                                                <InputField
                                                    value={data?.textCode}
                                                    placeholder={authPage.currentPage === "textCode" && "Text Message Code"}
                                                    functionPointer={session?.env?.functions?.updateInput}
                                                    name="textCode"
                                                    setter={setData}
                                                    readOnly={(!firstText && !textLoading)}
                                                />
                                            }

                                                {(!firstText && !textLoading) ? 
                                                    <div className='f bC s'>
                                                        <div className='twoFactorSendCode f g cC' onClick={()=>{requestTwoFactor(false, true);setErrorStatus(null);}}>
                                                            <div className='gC1'>Send Text</div> <div className='gC2 cC'><SendIcon style={{ fontSize: '18px' }}/></div>
                                                        </div>
                                                    </div>

                                                :
                                                    <div className='f bC s'>
                                                        {(firstText && !textSent && !textLoading) && 
                                                            <div className='twoFactorSendCode f g cC' onClick={()=>{requestTwoFactor(false, true);setErrorStatus(null);}}>
                                                                <div className='gC1'>Resend</div> <div className='gC2 cC'><SendIcon style={{ fontSize: '18px' }}/></div>
                                                            </div>}
                                                        {textLoading && <div className='twoFactorSentCode f bR'>Code Sent!</div>}
                                                        {(textSent && !textLoading) && <div className='twoFactorResendCode f'>Resend in {timeLeft}s</div>}
                                                    </div> 
                                                }     
                                            
                                        </div>
                                    }
                                </div>
                                <div className="f cC prompt">
                                    {authPage.currentPage === "textCode" && <div>We are texting an authorization code to your phone. Please provide the code to proceed with logging in.</div>}
                                    {authPage.currentPage === "authCode" && <div>Your authorization code is in your authenticator app. Please provide the code to proceed with logging in.</div>}
                                </div>
                                <div className="f cC fR g">
                                    
                                    <div className="lockIcon cC ">
                                        {authPage.currentPage === "authCode" && 
                                            (eligibility(["authCode"]) ? 
                                                <LockTwoToneIcon/>
                                            :
                                                <LockOpenRoundedIcon/>)
                                        }
                                        {authPage.currentPage === "textCode" && 
                                            
                                            (eligibility(["textCode"]) ? 
                                                <LockTwoToneIcon/>
                                            :
                                                <LockOpenRoundedIcon/>)
                                        }
                                    </div>
                                        
                                </div>
                                {errorStatus &&
                                    <div className="errorStatus dP">
                                        {errorStatus}
                                    </div>
                                }
                                <div className="options f cC g fC">
                                    {loadingStatus ?
                                        <div className="loginBtn btn cC">
                                            <CircularProgress  isIndeterminate size='45px' color='#104735'/>
                                        </div>
                                    :
                                        <div
                                            className={`loginBtn btnWIcon f g bR e p${
                                                (authPage.currentPage === "authCode" && eligibility(["authCode"])) ||
                                                (authPage.currentPage === "textCode" && eligibility(["textCode"]))
                                                ? " active"
                                                : " inactive"
                                            }`}
                                            onClick={()=>{handleSubmission()}}
                                        >
                                            <div className="f cC gC2">
                                                Authorize
                                            </div>
                                            <div className="f cC">
                                                <KeyRoundedIcon/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        
                        {(activity.currentPage === "termsOfService") &&
                            <>  
                                <div className="f cL s">
                                    <div
                                        className="goBack g cC p s e"
                                        onClick={()=>{updateUseState(setActivity, "currentPage", "login"); setData({textCode:'', email:'', password:'', authCode:''}); navigate(location.pathname);}}
                                    >
                                        <div className="back btn cC">
                                            <ArrowBackRoundedIcon/>
                                        </div>
                                        Go Back
                                    </div>
                                </div>

                                {/* <div className='cC bold prompt' style={{fontSize: '16px', width:'349px'}}>
                                        Please review the following Terms of Service: 
                                </div> */}

                                <div className='tosCheckBoxRow g'>
                                    <div className='tosContents g cL f gC2'>
                                        <div className='tosContainer oA oH'>
                                            <div className='tC bold tosHeader'>Terms of Service</div>
                                            <div className='cL'>
                                                &emsp;“Authorized Users” are the personnel of Client authorized by Client to access the ABL Tech Reporting Platform on Client's behalf. 
                                                Client shall ensure that its Authorized Users only access the ABL Tech Reporting Platform consistent with the Permissible Use identified
                                                 on the applicable Mortality Verification Service Agreement. Login credentials are for a single, Authorized User and Client shall ensure 
                                                 that its Authorized Users do not share login credentials. ABL Tech may deactivate shared Authorized User profiles. Authorized Users must 
                                                 have a Client email address. Client shall be responsible for its Authorized Users' compliance with the terms set forth on the Mortality 
                                                 Verification Service Agreement and the Terms and Conditions. Client shall promptly notify ABL Tech when an Authorized User no longer 
                                                 requires access to the ABL Tech Reporting Platform.
                                            </div>
                                        </div> 
                                        <div className='cL'>
                                            <input
                                                type="checkbox" 
                                                style={{ transform: 'scale(1.5)' }}
                                                checked={TOSChecked}
                                                onChange={handleTOSCheckbox}
                                            />
                                            &emsp;I accept and agree to the&nbsp; 
                                            <Link to="https://abltech.com/terms-and-conditions/" className='blueLink' target="_blank"> Terms and Conditions</Link>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="f cC fR g">
                                    <div className="lockIcon cC ">     
                                        {(TOSChecked ? 
                                            <LockTwoToneIcon/>
                                        :
                                            <LockOpenRoundedIcon/>)}
                                    </div>
                                </div> */}
                                {errorStatus &&
                                    <div className="errorStatus dP">
                                        {errorStatus}
                                    </div>
                                }
                                <div className="options f cC g fC">
                                    {loadingStatus ?
                                        <div className="loginBtn btn cC">
                                            <CircularProgress  isIndeterminate size='45px' color='#104735'/>
                                        </div>
                                    :
                                        <div
                                            className={`loginBtn btnWIcon f g bR e p${
                                                TOSChecked 
                                                ? " active"
                                                : " inactive"
                                            }`}
                                            onClick={()=>{handleSubmission()}}
                                        >
                                            <div className="f cC gC2">
                                                Continue
                                            </div>
                                            <div className="f cC">
                                                <KeyRoundedIcon/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Login;