import React, { useEffect, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function SFTPSuccess(props){

    const session = props?.session;
    const viewType = session?.env?.viewport?.viewType;

    function printOverlayContent() {
        return (
                <div className="SFTPSuccessContainer f g fC dP tO gR2 bR">
                    <div className="gR1 cC gCW greenText successMessage bold"><div><ErrorOutlineIcon/></div><div>SFTP Upload Success!</div></div>
                    {viewType === "mobile" ?
                        <div className="gR2 cC greenText successMessage bold">Your file has been successfully uploaded.</div> 
                        :
                        <div className="gR2 cC gCW greenText successMessage bold">Your file has been successfully uploaded to our server.</div>
                    }
                </div>         
        );
    }

    return (
        <>
            <div className="overlayBoard g f fC oH dP">
                <div className='uploadFailure g'>
                    {printOverlayContent()} 
                </div>
            </div>  
        </>
    );
}; 

export default SFTPSuccess;