import React, { useEffect, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function ClearWarning(props){

    const session = props?.session;
    const viewType = session?.env?.viewport?.viewType;
    const clearEditList = session?.edit?.functions?.clearEditList;

    function printOverlayContent() {
        return (
                <div className="removeFileContainer f g fC dP tO gR2 bR">
                    <div className="gR1 cC gCW successMessage bold"><div><ErrorOutlineIcon/></div><div>Warning</div></div>
                    <div className="gR2 cC gCW successMessage bold">{viewType === "mobile" ? "Any edits will not be saved" : "Any edits you have made to your file will not be saved"}</div>          
                </div>         
        );
    }

    return (
        <>
            <div className="overlayBoard g f fC oH dP">
                <div className='uploadFailure g'>
                    {printOverlayContent()}
                    
                </div>
                <div className='cC'>
                    <div className='btnWIcon p bR cC' style={{width:'30%', height:'50px'}} onClick={() => {clearEditList(session?.upload?.setData, session?.upload?.data?.initialErrorCount); props?.setter(false);}}>Confirm</div>
                </div>
            </div>  
        </>
    );
};

export default ClearWarning;