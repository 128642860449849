import React, { useEffect, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function UploadFailure(props){

    const session = props?.session;
    const viewType = session?.env?.viewport?.viewType;

    function printOverlayContent() {
        return (
                <div className="failureContainer f g fC dP tO gR2 bR">
                    <div className="gR1 cC gCW redText successMessage bold"><div><ErrorOutlineIcon/></div><div>Error</div></div>
                    {viewType === "mobile" ?
                        <div className="gR2 cC redText successMessage bold">Your file could not be uploaded. Please contact customer support for help.</div> 
                        :
                        <div className="gR2 cC gCW redText successMessage bold">Your file could not be uploaded.<br/> Please contact customer support for help.</div>
                    }
                </div>         
        );
    }

    return (
        <>
            <div className="overlayBoard g f fC oH dP">
                <div className='uploadFailure g'>
                    {printOverlayContent()}
                </div>
            </div>  
        </>
    );
};

export default UploadFailure;