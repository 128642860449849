import React, { useEffect, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import TableRowsIcon from '@mui/icons-material/TableRows';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import PasswordIcon from '@mui/icons-material/Password';
import Initialization from '../../SessionVars/Initialization.js';

import Dropdown from '../Dropdown.js';
import { EditCalendar } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

function ReportOptions(props){
    const session = props?.session;
    const sessionUser = props?.sessionUser;
    const initialize = Initialization();
    const reporting = session?.reporting;
    const currentAccountID = session?.handler?.data?.currentAccountID;
    const updateReporting = session?.reporting?.setData;
    const selectedReport = reporting?.data[currentAccountID]?.selectedReport;
    const showSocials = sessionStorage.getItem("showSocials")?.toLowerCase() === "true";

    const [updatePrompt, setUpdatePrompt] = useState("Update");
    const [loading, setLoadingStatus] = useState(false);
    const [toggle, setToggle] = useState({});
    const toggleableAttributes = ['rowIndex', 'select'];

    const currentPreferences = reporting?.data[currentAccountID]?.specialColumns;
    const viewableReport = selectedReport?.query && selectedReport?.columns?.length > 0;
    const reportType = reporting?.functions?.reportType(selectedReport?.stem);
    const groupID = selectedReport?.groupID?.value;
    const recordID = selectedReport?.recordID;

    const [dropdownHandler, setDropdownHandler] = useState({ showMenu : undefined });

    const [preferences, setPreferences] = useState({
        ...currentPreferences,
    });

    const summaryEligable = !(preferences?.rowIndex && preferences?.emailFrequency === preferences?.select);

    const frequencyOptions = [
        { value: "Live", label: "Live" },
        { value: "Daily", label: "Daily" },
        { value: "Weekly", label: "Weekly" },
        { value: "Monthly", label: "Monthly" },
    ];

    const frequencySummaryOptions = [
        { value: "Daily", label: "Daily" },
        { value: "Weekly", label: "Weekly" },
        { value: "Monthly", label: "Monthly" },
    ];

    const [data, setData] = useState({
        password : '',
        passwordConfirmation : ''
    });

    function attrHandler(attr) {
        return preferences?.[attr] !== currentPreferences?.[attr]
        ? { [attr]: preferences?.[attr] }
        : {};
    }

    function generateUpdateArrays() {
        const attrPaths = [];
        const attrVals = [];
        
        for (const attr in preferences) {
            if (preferences?.[attr] !== currentPreferences?.[attr]) {
                    attrPaths.push("preferences." + attr);
                    attrVals.push(preferences?.[attr]);
            }
        }

        return { attrPaths, attrVals };
    }

    function downloadSelectedRowStatus(){
        if(preferences?.select && reporting?.data[currentAccountID]?.selectionState?.selectedRows?.length > 0){
            return true
        }

        return false;
    }

    function ToggleOption(key, label, Icon, onClick) {
        const isLoading = (onClick ?? false) && sessionStorage.getItem("showSocials")?.toLowerCase() === "loading";
        const activeSocials = onClick ? showSocials : undefined;
        return (
            <div className="option g dG cC">
                <div
                    className={`toggle s e${isLoading ? " loading" : " p"}${preferences?.[key] || ( activeSocials ? !isLoading : false ) ? " active cL" : " cR"}`}
                    onClick={() => {
                        (onClick ??
                            (() => {
                                updateReporting(
                                    `${session?.handler?.data?.currentAccountID}.specialColumns`,
                                    key,
                                    !reporting?.data[currentAccountID]?.specialColumns?.[key]
                                );
                            }))();
                    }}
                >
                    <div className="status g cC pR">
                        {isLoading &&
                            <CircularProgress/>
                        }
                    </div>
                </div>
                <div className="f cL g">
                    <div className="prompt f g dG">
                        <div className="f cC">
                            <Icon />
                        </div>
                        <div className="f cL bold">
                            {label}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const resetDropdowns = () => {
        if(dropdownHandler?.showMenu){
            resetShowMenu(setDropdownHandler);
        }
    };

    const resetShowMenu = (setDropdownState) => {
        setDropdownState((prev) => {
            if (prev?.showMenu) {
                return { ...prev, showMenu: false };
            }
            return prev;
        });
    };

    function updatePreferences(forgotPassword){
        setLoadingStatus(true);

        const paramVals = {
            userID: sessionUser?.userID,
            ...attrHandler('emailFrequency'),
            ...attrHandler('emailSummaryFrequency'),
            ...(Object.keys(toggle).length > 0 ? { toggle } : {}),
        };

        session?.env?.functions?.buildFetchRequest("updateUserProfile", paramVals)
            .then(response => response.json())
            .then(resData => {    
                setLoadingStatus(false);
                if(resData.status === 200){
                    const {attrPaths, attrVals } = generateUpdateArrays();
                    session?.user?.functions?.updateUserData(attrPaths, attrVals);
                    session?.env?.setOverlay();
                }else{
                    setUpdatePrompt("Update Error");
                }
        });
    }

    useEffect(() => {
        if(session?.env?.overlay !== "passwordChange"){
            setData({
                password : '',
                passwordConfirmation : ''
            })
        }
    }, [session?.env?.overlay]);

    useEffect(() => {
        const updatedToggle = {};

        // toggleableAttributes.forEach(attribute => {
        //   if (currentPreferences?.[attribute] !== preferences?.[attribute]) {
        //     updatedToggle[attribute] = preferences?.[attribute];
        //   }
        // });

        // setToggle(updatedToggle);
        setPreferences(reporting?.data[currentAccountID]?.specialColumns);
    }, [reporting?.data[currentAccountID]?.specialColumns]);

    function deleteReport(recordID){
        const sessionUserString = localStorage.getItem('sessionUser');
        let sessionUser = null;
        if (sessionUserString) {
            sessionUser = JSON.parse(sessionUserString);
        }
        const paramVals = {
            recordID: recordID,  
        };
        session?.env?.functions?.buildFetchRequest("/user/report", paramVals, null, null, "DELETE")
            .then(response => response.json())
            .then(resData => {
            
            if(resData.status === 200){
                props?.setter(false); 
                updateReporting(`${session?.handler?.data?.currentAccountID}`, 'selectedReport', null);
            }else{
                //setErrorStatus(resData.message);
            }
        });
    }

    return (
        // <div className="preferences g f oH dP nS tC fC">
        //     <div className="toggles f g dG">
        <div className="preferences g f dP nS tC fC" onClick={()=>{resetDropdowns()}}>
            <div className="downloads f g dG">
                <div className="header f cL bold">
                    Downloads
                </div>
                {/* <div className="spacer">
                </div> */}
                {reporting?.data[currentAccountID]?.selectedReport.stem === "deceased" &&
                    <div className="option g dG">
                        <div
                            className={`btnWIcon bold bR f s e cC ns${viewableReport && selectedReport?.list?.length ? " p" : " inactive"}`}
                            onClick={viewableReport && selectedReport?.list?.length ? ()=>{reporting?.functions?.downloadReport(session)} : null}
                        >
                            <div className="f cC lH">
                                Download
                            </div>
                            <DownloadIcon/>
                        </div>
                        <div className="f cC">
                            <ListAltIcon/>
                        </div>
                        <div className="f cL bold">
                            Report Sheet
                        </div>
                    </div>
                }
                <div className="option g dG">
                    <div
                        className={`btnWIcon bold bR f s e cC ns p${!downloadSelectedRowStatus() ? " inactive" : ''}`}
                        // onClick={() => downloadSelectedRowStatus() && session?.reporting?.functions?.downloadSelectedFiles(
                        //     session?.reporting?.data?.selectionState?.selectedRows, session
                        // )}
                    >
                        <div className="f cC lH">
                            Download
                        </div>
                        <DownloadIcon/>
                    </div>
                    <div className="f cC">
                        <FolderZipIcon/>
                    </div>
                    <div className="f cL bold">
                        {`Files of Selected Rows`}
                    </div>
                    <div className="f cR">
                        {`${preferences?.select ? " ( " + reporting?.data[currentAccountID]?.selectionState?.selectedRows?.length + " ) Rows Selected" : ''}`}
                    </div>
                </div>
                <div className="option g dG fileTypeOption">
                    <Dropdown
                        key={`downloadFileType`}
                        setShowMenu={(input) => {
                            const newState = input ?? (dropdownHandler?.showMenu ? undefined : `downloadFileType`);
                            setDropdownHandler({
                                ...dropdownHandler,
                                showMenu: newState
                            });
                        }}
                        showMenu={dropdownHandler?.showMenu === `downloadFileType`}
                        default={reporting?.data[currentAccountID]?.downloadFileType ?? "XLSX"}
                        list={["XLSX", "CSV", "TXT"]}
                        onClick={(value) => {
                            updateReporting(`${session?.handler?.data?.currentAccountID}`, 'downloadFileType', value);
                            window.localStorage.removeItem('downloadFileType');
                            window.localStorage.setItem('downloadFileType', value);
                        }}
                        reset={resetDropdowns}
                    />
                    <div className="f cC">
                        <FileOpenIcon/>
                    </div>
                    <div className="f cL bold">
                        Download File Type
                    </div>
                </div>
                <div className="option g dG fileTypeOption">
                    <Dropdown
                        key={`downloadDateType`}
                        setShowMenu={(input) => {
                            const newState = input ?? (dropdownHandler?.showMenu ? undefined : `downloadDateType`);
                            setDropdownHandler({
                                ...dropdownHandler,
                                showMenu: newState
                            });
                        }}
                        showMenu={dropdownHandler?.showMenu === `downloadDateType`}
                        default={reporting?.data[currentAccountID]?.downloadDateType ?? "MM/DD/YYYY"}
                        list={["MM/DD/YYYY", "YYYY-MM-DD"]}
                        onClick={(value) => {
                            updateReporting(`${session?.handler?.data?.currentAccountID}`, 'downloadDateType', value);
                            window.localStorage.removeItem('downloadDateType');
                            window.localStorage.setItem('downloadDateType', value);
                        }}
                        reset={resetDropdowns}
                    />
                    <div className="f cC">
                        <EditCalendarIcon/>
                    </div>
                    <div className="f cL bold">
                        Download Date Type
                    </div>
                </div>
            </div>
            {/* {selectedReport?.recordID && (selectedReport?.subReport?.name ? selectedReport?.referenceBranch !== "summary" : selectedReport?.branch !== "summary") && */}
                <div className="toggles f g dG">
                    <div className="header f cL bold">
                        Toggles
                    </div>
                    {selectedReport?.recordID &&
                        (selectedReport?.subReport?.name
                            ? selectedReport?.referenceBranch !== "summary"
                            : selectedReport?.branch !== "summary") && (
                            <>
                                {ToggleOption("rowIndex", "Row Index", TableRowsIcon)}
                                {reporting?.data[currentAccountID]?.reportDataHandler?.[
                                    selectedReport?.subReport?.name
                                        ? selectedReport?.referenceStem
                                        : selectedReport?.stem
                                ]?.folderName &&
                                    ToggleOption("select", "Row Select", HighlightAltIcon)}
                            </>
                        )
                    }
                    {(selectedReport?.stem === "deceased" || selectedReport?.referenceStem === "deceased" ) && ToggleOption(
                        "showSocials",
                        "Social Security Numbers",
                        PasswordIcon,
                        () => {
                            initialize?.loadDeceased(
                                session,
                                session?.handler?.data?.currentAccountID,
                                !showSocials
                            );
                        }
                    )}
                </div>
            {/* } */}
            <div className="spacer"></div>

            {( groupID === 0 || groupID === undefined) &&
                <div className="downloads f g dG">
                    <div className="header f cL bold">
                            Delete Report
                    </div>

                    <div className="option g dG">
                        <div
                            className={`btnWIcon deleteReportBtn bold bR f s e cC ns${viewableReport && selectedReport?.list?.length ? " p" : " inactive"}`}
                            onClick={() => {
                                (viewableReport && selectedReport?.list?.length) && 
                                session?.env?.setNestedOverlay({
                                    name: 'deleteReport',
                                    params: {deleteReport: deleteReport,
                                            recordID: recordID
                                    }
                                });
                            }}
                        >
                            <div className="f cC lH">
                                Delete
                            </div>
                            <DeleteForeverIcon/>
                        </div>
                        <div className="f cC">
                            <ListAltIcon/>
                        </div>
                        <div className="f cL bold">
                            Permanently Delete Report
                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export default ReportOptions;