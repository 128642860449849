import { useState } from 'react';

function SessionAccount(props){
    const [data, setData] = useState(localStorage.getItem('sessionAccount') &&
        JSON.parse(localStorage.getItem('sessionUser'))?.application === "lT" ?
            JSON.parse(localStorage.getItem('sessionAccount'))
        :
            {}
    );

    const updateAccountData = (propertyPaths, values) => {
        const paths = Array.isArray(propertyPaths) ? propertyPaths : [propertyPaths];
        const vals = Array.isArray(values) ? values : [values];   

        setData((prev) => {
            const newData = { ...prev };
            for (let i = 0; i < paths.length; i++) {
                const propertyPathParts = paths[i].split('.');
                let currentNested = newData;

                for (let j = 0; j < propertyPathParts.length - 1; j++) {
                    const propertyPart = propertyPathParts[j];
                    currentNested[propertyPart] = currentNested[propertyPart] || {};
                    currentNested = currentNested[propertyPart];
                }

                currentNested[propertyPathParts[propertyPathParts.length - 1]] = vals[i];
            }

            window.localStorage.removeItem("sessionAccount");
            window.localStorage.setItem('sessionAccount', JSON.stringify(newData));
            return newData;
        });
    };

    const logout = () => {
      window.localStorage.clear();
      setData();
    };

    function loadAccount(sessionAccount){
        window.localStorage.clear();
        window.localStorage.setItem('sessionAccount', JSON.stringify(sessionAccount));
        setData(sessionAccount);
    }

    const functions = {
        loadAccount,
        updateAccountData,
        logout
    }

    const sessionAccount = {
        data,
        functions
    }

    return sessionAccount;
};

export default SessionAccount;