import { useState, useEffect, useRef } from 'react';

function SessionUser(props){

    const [data, setData] = useState(localStorage.getItem('sessionUser') &&
        JSON.parse(localStorage.getItem('sessionUser')).application === "lT" ?
            JSON.parse(localStorage.getItem('sessionUser'))
        :
            {}
    );
    const [activity, setActivity] = useState([]);
    const activityRef = useRef(null);

    const updateUserData = (propertyPaths, values) => {
        const paths = Array.isArray(propertyPaths) ? propertyPaths : [propertyPaths];
        const vals = Array.isArray(values) ? values : [values];   

        setData((prev) => {
            const newData = { ...prev };
            for (let i = 0; i < paths.length; i++) {
                const propertyPathParts = paths[i].split('.');
                let currentNested = newData;

                for (let j = 0; j < propertyPathParts.length - 1; j++) {
                    const propertyPart = propertyPathParts[j];
                    currentNested[propertyPart] = currentNested[propertyPart] || {};
                    currentNested = currentNested[propertyPart];
                }

                currentNested[propertyPathParts[propertyPathParts.length - 1]] = vals[i];
            }

            window.localStorage.removeItem("sessionUser");
            window.localStorage.setItem('sessionUser', JSON.stringify(newData));
            return newData;
        });
    };

    const logout = () => {
        window.localStorage.clear();
        setData();
        //   window.location.reload();
    };

    function loadUser(sessionUser){
        window.localStorage.clear();
        window.localStorage.setItem('sessionUser', JSON.stringify(sessionUser));
        setData(sessionUser);
    }

    function maintainSession(recall) {
        const readableDate = new Date().toLocaleString();
        activityRef.current = readableDate;
        if(recall){
            recall("user/session", undefined, undefined, undefined, "GET")
            .then(response => response.json())
            .then(resData => {
                if(resData.status !== 200){
                    console.log("Session time has exceeded.")
                    logout();
                }
            });
        }
    }

    const functions = {
        loadUser,
        updateUserData,
        logout,
        maintainSession
    }

    const sessionUser = {
        data,
        functions,
        activity : activityRef
    }

    useEffect(() => {
        activityRef.current = activity; // Step 2: Update the ref whenever activity changes
    }, [activity]); // This useEffect will run every time activity changes

    // useEffect(() => {
    //     if (data?.sessionToken) {
    //         const interval = setInterval(() => {
    //             const lastTimestamp = new Date(activityRef.current);
    //             const secondsDifference = (new Date() - lastTimestamp) / 1000;
    
    //             if (secondsDifference > 20) {
    //                 console.log("User inactive for over 20 seconds.");
    //                 //logout();
    //             }else{

    //             }
    //         }, 10000); // Checks every 10 seconds
    
    //         return () => clearInterval(interval);
    //     }
    // }, [data]); // Rerun if 'data' changes
    
    return sessionUser;
};

export default SessionUser;