import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function Rerouter(){
    const currentPath = window.location.pathname.replace(/\//g, '');
    const navigate = useNavigate();

    useEffect(() => {
        if (!["/", ""].includes(currentPath)) {
            navigate("/");
        }
    }, [currentPath]);
};

export default Rerouter;