import React from 'react';
import {useEffect, useState} from 'react';
import ProfileCard from './ProfileCard'; 
import '../../CSS/ProfileCard.css';

import { Button, Text, useDisclosure } from '@chakra-ui/react';

import SaveIcon from '@mui/icons-material/Save';

import UploadSuccess from '../Overlays/UploadSuccess';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'

function CardsPreview({session, fileRows, allHeaders, rowErrors, updateRows, errorCountData, setFileRows}) {

    const OverlayOne = () => (
        <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(7px)'
        />
    )

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [overlay, setOverlay] = React.useState(<OverlayOne />)

    const [uploadSubmitted, setUploadSubmitted] = useState(false); 

    const handleSaveChanges = (index, newValue) => {
        updateRows(index, newValue) 
    }

    useEffect(() => {
        if(uploadSubmitted){
            //send errorCountData to getMany() here
            console.log("Error Count Object in rows:",errorCountData)
        }
        
    },[errorCountData]); 

    const isAllFieldsZero = Object.keys(errorCountData).slice(1) .every((field) => errorCountData[field] === 0);

    function toggleOverlay(overlay){
        session?.env?.setOverlay(session?.env?.overlay === overlay ? undefined : overlay);
    }

    function uploadMany(){
        let paramVals = {
            "individuals" : fileRows,
            "uploadHistory" : errorCountData
        };
        session?.env?.functions?.buildFetchRequest("individual/addMany", paramVals)
          .then(response => response.json())
          .then(resData => {

            if(resData.status === 200){
                console.log("Upload successful!!")
            }else{
                //setErrorStatus(resData.message);
            }
        });
    }

  return (
    <>
        <div className='btnWIcon cC f bR'
            onClick={() => {
                setOverlay(<OverlayOne />)
                onOpen()
                console.log("rowErrors: ",rowErrors);
                console.log("Error count: ",errorCountData);
            }}
            >
            <div className='f cC'>Save Upload </div>
            <SaveIcon/>
        </div>

        <Modal isCentered isOpen={isOpen} size={'sm'} onClose={onClose} motionPreset='slideInBottom'>
            {overlay}
            <ModalContent top={'90px'} width={'50%'} left={'25%'} className='cC g'>
                <div className='uploadInfo'>
                    <ModalHeader className='bold'>Upload Preview</ModalHeader>
                                    
                    The accuracy of your data is key to ensuring our monitoring can be as accurate as possible. Your file had the following issues:<br/>
                    {isAllFieldsZero ? <div className='greenText'>No issues found!</div> : <div className='redText'>Number of missing/invalid entries:</div>} 

                    <table className='cC'>
                        <tbody>
                            <tr className='g'>
                                {Object.keys(errorCountData)
                                    .slice(1) // Exclude the first field
                                    .map((field) => {
                                        if (errorCountData[field] !== 0) {
                                            // Function to capitalize the first letter of each word
                                            const capitalizeWords = (str) => {
                                                return str
                                                    .split('_')
                                                    .map((word) => word.charAt(0)?.toUpperCase() + word.slice(1))
                                                    .join(' ');
                                            };

                                            // Conditionally change "ssn" to "SSN"
                                            const fieldName = field.toLowerCase() === 'ssn' ? 'SSN' : capitalizeWords(field);

                                            return (
                                                <>
                                                    <td className='gR1' key={`field-${field}`} style={{ border: '1px solid black' }}>
                                                        <div className="field bold s e">{fieldName}</div>
                                                    </td>
                                                    <td className='gR2' key={`value-${field}`} style={{ border: '1px solid black' }}>
                                                        <div className="value  redText">{errorCountData[field]}</div>
                                                    </td>
                                                </>
                                            );
                                        }
                                        return null;
                                    })}
                            </tr>
                        </tbody>
                    </table>
                </div>
                

                <div className='profile-card-container tO g '>
                    
                    <div className='gC1'>

                        {/* 
                            If no errors: Output first 10 cards?
                            Currently set to output all cards if no errors
                            If errors: Output first 20 cards
                        */}
                        
                        { rowErrors.length !== 0 ?
                            fileRows.map((row, index) => (
                                (rowErrors.some(error => error.row === index)) &&(
                                    <ProfileCard key={index} fileRows={fileRows} row={row} rowNum={index} allHeaders={allHeaders} rowErrors={rowErrors} updateRows={updateRows} className='gC1'/>
                                )         
                            ))
                            :
                            // If No Errors
                            fileRows.map((row, index) => (
                                (index < 20) &&
                                (
                                    <ProfileCard key={index} fileRows={fileRows} row={row} rowNum={index} allHeaders={allHeaders} rowErrors={rowErrors} updateRows={updateRows} className='gC1'/>
                                )                                
                            ))
                        } 
                    </div>
                </div>

                Make any necessary changes and confirm upload 
            
                <ModalBody>
                    
                </ModalBody>
                <ModalFooter >
                {/* handleSaveChanges(); */}
                    <div className='btnWIcon cC bold savePreviewBtn dP' onClick={() => { handleSaveChanges(); session?.env?.setOverlay("uploadSuccess"); uploadMany(); console.log("Send Row Objects here:",fileRows); setUploadSubmitted(true); setFileRows([]); }}>Save & Upload</div> 
                    <div className='btnWIcon cC bold savePreviewBtn dP' onClick={onClose}>Close</div>
                </ModalFooter>

                
            </ModalContent>
        </Modal>
    </>
    
  );
}

export default CardsPreview;