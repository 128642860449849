import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LastPageIcon from '@mui/icons-material/LastPage';
import { CircularProgress } from '@mui/material';

import Dropdown from '../Dropdown.js';

function ReportActionBar(props){
    const session = props?.session;
    const reporting = session?.reporting;
    const currentAccountID = session?.handler?.data?.currentAccountID;
    const selectedReport = reporting?.data[currentAccountID]?.selectedReport;
    const importedList = props?.importedList;
    const requestingBatch = props?.requestingBatch;
    const currentStructure = props.importedList ? importedList : selectedReport;
    const viewableReport = props?.importedList ? true : (currentStructure?.query && currentStructure?.columns?.length > 0);
    const floater = props?.floater?.data;
    const pagination = props?.pagination?.data;
    const setPagination = props?.pagination?.set;
    const search = props?.search?.data;

    const currentStorage = JSON.parse(localStorage.getItem("storage")) || {};

    const viewport = session?.env?.viewport;
    const viewType = viewport?.viewType;
    const reportType = reporting?.functions?.reportType(currentStructure?.stem);

    const paginationFriendly = currentStructure?.stem !== "summary" || currentStructure?.subReport?.list;

    const paginationClick = (value) => {
        if (currentStorage?.reporting?.rowMax !== value) {
            currentStorage.reporting = {
                ...currentStorage.reporting,
                rowMax: value,
            };

            setPagination((prev) => ({
                ...prev,
                rowMax: value,
            }));

            localStorage.setItem("storage", JSON.stringify(currentStorage));

            if (reportType === "generated") {
                reporting?.functions?.requestResultBlock(
                    {
                        newLimit: value,
                        newOffset: 1,
                    },
                    props?.requestObject,
                    session?.handler?.data?.currentAccountID
                );
            }
        }
    };

    function paginationHandler() {
        const totalRecords =
            (reportType !== "generated" && search?.searchValue) ?
            selectedReport?.sortedListResults?.length :
                currentStructure?.subReport?.list?.length ?? currentStructure?.totalFound ?? currentStructure?.list?.length;

        if(totalRecords === 0 || !totalRecords || totalRecords < pagination?.rowMax || !currentStructure?.query){
            return;
        }

        const totalPages = Math.ceil(totalRecords / pagination?.rowMax);

        const handlePageChange = (newOffset) => {
          if (newOffset >= 1 && newOffset <= totalPages) {
            setPagination((prev) => ({
                ...prev,
                index: newOffset,
            }));
          }

          if(reportType === "generated"){
            reporting?.functions?.requestResultBlock({newOffset : newOffset}, props?.requestObject, session?.handler?.data?.currentAccountID);
          }
        };

        const maxVisiblePages = 5;
        const halfMaxVisiblePages = Math?.floor(maxVisiblePages / 2);
        const firstPage = Math?.max(1, pagination?.index - halfMaxVisiblePages);
        const lastPage = Math?.min(totalPages, firstPage + maxVisiblePages - 1);

        const showFirstPageButton = pagination?.index > halfMaxVisiblePages + 1;
        const showLastPageButton = totalPages > maxVisiblePages && lastPage < totalPages;

        const numColumns = maxVisiblePages;
        const paginationCols = { gridTemplateColumns: `repeat(${numColumns + 6}, 1fr)` };        

        return (
            <div className={`pagination f g cR nS lH fR bold${requestingBatch ? " inactive" : ''}`} style={paginationCols}>
                {showFirstPageButton ?
                    <div
                        className="btn f cC bR p"
                        onClick={() => handlePageChange(1)}
                    >
                        <FirstPageIcon/>
                    </div>
                :
                    <div className="spacer">
                    </div>
                }

                {pagination?.index !== 1 ?
                    <div
                        className="btn f cC bR p"
                        onClick={() => handlePageChange(pagination.index - 1)}
                    >
                        <NavigateBeforeIcon/>
                    </div>
                :
                    <div className="spacer">
                    </div>
                }

                {showFirstPageButton &&
                    <div className="f cC">
                        <span>...</span>
                    </div>
                }

                {Array.from({ length: lastPage - firstPage + 1 }, (_, index) => (
                    <div
                        key={firstPage + index}
                        onClick={() => handlePageChange(firstPage + index)}
                        className={`btn f cC bR p${pagination?.index === firstPage + index ? ' active' : ''}`}
                    >
                        {firstPage + index}
                    </div>
                ))}

                {showLastPageButton &&
                    <div className="f cC">
                        <span>...</span>
                    </div>
                }

                {totalPages !== pagination?.index &&
                    <div
                        className="btn f cC bR p gC10"
                        onClick={() => handlePageChange(pagination?.index + 1)}
                    >
                        <NavigateNextIcon/>
                    </div>
                }

                {showLastPageButton && (
                    <div
                    className="btn f cC bR p gC11"
                        onClick={() => handlePageChange(totalPages)}
                    >
                        <LastPageIcon/>
                    </div>
                )}
            </div>
        );
    }

    function recordsMatched() {
        const list = reportType !== "generated" && search?.searchValue ? selectedReport?.sortedListResults : currentStructure?.subReport?.list ?? currentStructure?.list;
        const totalFound = currentStructure?.totalFound;
    
        if (list?.length === 1 && currentStructure?.query) {
            return viewType === "full" ? "1 record matched" : "1 record";
        } else if ((list?.length || totalFound) && currentStructure?.query) {
            return `${totalFound?.toLocaleString() ?? list?.length?.toLocaleString()} records ${viewType === "full" ? "matched" : ""}`;
        } else {
            return "No records to show";
        }
    }

    return (
        <>
          {viewType === "mobile" && floater?.bottom && paginationFriendly ? (
            <div className="floatingDetailBar bR g s e cC dG">
                {currentStructure?.details?.scrollType === "pagination" && (
                    <>
                        <Dropdown
                            setShowMenu={(input) => {
                                setPagination((prev) => ({
                                    ...prev,
                                    showMenu: input ?? !prev.showMenu,
                                }));
                            }}
                            showMenu={pagination?.showMenu}
                            default={currentStorage?.reporting?.rowMax ?? 100}
                            list={[100, 250, 500, 1000]}
                            onClick={(value) => paginationClick(value)}
                            inactive={!viewableReport || !currentStructure?.list?.length}
                            leadingPrompt={"Show"}
                            reset={props?.resetDropdowns}
                        />
                        {paginationHandler()}
                    </>
                )}
            </div>
          ) : (
            <div className="detailBar bR g fR dG" style={{ zIndex: currentStructure?.columns?.length || 3 }}>
                {currentStructure?.details?.scrollType === "pagination"  && paginationFriendly && (
                    <div className="section left g dG">
                        <Dropdown
                            setShowMenu={(input) => {
                                setPagination((prev) => ({
                                    ...prev,
                                    showMenu: input ?? !prev.showMenu,
                                }));
                            }}
                            showMenu={pagination?.showMenu}
                            default={currentStorage?.reporting?.rowMax ?? 100}
                            list={[100, 250, 500, 1000]}
                            onClick={(value) => paginationClick(value)}
                            inactive={!viewableReport || !currentStructure?.list?.length}
                            leadingPrompt={"Show"}
                            reset={props?.resetDropdowns}
                        />
                    </div>
                )}
                {viewType !== "mobile" && (
                    <div className="f cC lH gC2">
                        {recordsMatched()}
                    </div>
                )}
                {currentStructure?.details?.scrollType === "pagination"  && paginationFriendly && paginationHandler()}
            </div>
          )}
        </>
    );
}

export default ReportActionBar;