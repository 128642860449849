import React, { useEffect, useState } from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';

import PasswordReset from "./PasswordReset.js";
import Preferences from "./Preferences.js";
import Notifications from "./Notifications.js";
import CriteriaBoard from "./CriteriaBoard.js";
import ReportErrors from "./ReportErrors.js";
import IndividualProfile from "./IndividualProfile.js";
import ActionBar from "./ActionBar.js";
import UploadSuccess from "./UploadSuccess.js";
import UploadHistory from "./UploadHistory.js";
import UploadFailure from "./UploadFailure.js";
import UserAgreementOverlay from "./UserAgreementOverlay.js";
import ContractLimitOverlay from "./ContractLimitOverlay.js";
import Settings from "./Settings.js";
import AdminBoard from "./AdminBoard.js";
import ReportOptions from "./ReportOptions.js";
import RemoveWarning from "./RemoveWarning.js";
import ClearWarning from "./ClearWarning.js";
import SFTPConfirmation from './SFTPConfirmation.js';
import SFTPSuccess from './SFTPSuccess.js';

function OverlayHandler(props){
    const session = props?.session;
    const envFunctions = session?.env?.functions;
    const individualFunctions = session?.individual?.functions;
    const policyHandler = session?.individual?.policyHandler;
    const policyList = session?.individual?.data?.policy;
    const sessionUser = props?.session?.user?.data;
    const viewType = session?.env?.viewport?.viewType;

    let overlayName;
    let overlayProps;

    // Check if overlay contains props 
    if (typeof props?.overlay === 'object'){
        overlayName = props?.overlay?.name;
        overlayProps = props?.overlay?.props;
    }else if((typeof props?.overlay === 'string')){
        overlayName = props?.overlay;
    }

    // Close with 'Esc'
    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.keyCode === 27) { 
            props?.setter(false); 
          }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);
 
    const headerHandler = {
        "policyScroller" : "My Policies",
        "settings" : "User Options",
        "passwordChange" : "Password Change",
        "notifications" : "Notifications",
        "emailPreferences" : "Email Preferences",
        "reportErrorHandler" : "Warning",
        "individualProfile" : "Individual Profile",
        "criteriaHandler" : "Report Builder",
        "uploadSuccess" : "Upload Success",
        "uploadHistory" : "Upload History",
        "uploadFailure" : "Upload Failure",
        "SFTPSuccess" : "SFTP Upload",
        "adminBoard" : "Admin Board",
        "reportOptions" : "Report Options",
        "removeWarning" : "Remove File Warning",
        "clearWarning" : "Clear Edits Warning",
        "userAgreement" : "User Agreement",
        "contractLimit" : "Contract Limit Exceeded",
        "sftpConfirmation" : "SFTP Upload Confirmation"
    }

    const overflowAuto = ["criteriaHandler"];

    const moduleBlockType = {
        "settingsMenu" : " inline",
        "criteriaHandler" : " default",
        "uploadHistory" : " default",
        "individualProfile" : " wAction",
        "uploadSuccess" : " wAction",
        "reportOptions" : " default",
        "uploadFailure" : " default",
        "SFTPSuccess" : " default",
        "removeWarning" : " default",
        "clearWarning" : " default",
        "userAgreement" : " default",
        "contractLimit" : " default",
    }

    return (
        overlayName ?
            <div
                className="overlay cC"
                onClick={(e)=>{
                    props?.handleClose(e, overlayName, props?.setter);
                    session?.user?.functions?.maintainSession();
                }}
            >
                <div className={`moduleBlock${moduleBlockType[overlayName] ?? " default"} g oH ${overlayName} ${session?.env?.nestedOverlay ? " overlayBlur" : ''}`}>
                    <div className="header g f fR cC">
                        <div className={`gC2 f cC ${overlayName === "contractLimit" ? 'redText' : ''}`}>
                            {headerHandler[overlayName]}
                        </div>
                        <div className="btn close f p cC" onClick={()=>{props?.setter(false)}}>
                            <CloseRoundedIcon/>
                        </div>
                    </div>
                    <div className={`format g f pR fC${overflowAuto?.includes(overlayName) ? " oA" : " oH"}`}>
                        {overlayName === "notifications" &&
                            <Notifications
                                session={session}
                                policyCount={0}
                            />
                        }
                        {overlayName === "settings" &&
                            <Settings
                                session={session}
                            />
                        }
                        {overlayName === "passwordChange" &&
                            <PasswordReset
                                session={session}
                                sessionUser={sessionUser}
                            />
                        }
                        {overlayName === "emailPreferences" &&
                            <Preferences
                                session={session}
                                sessionUser={sessionUser}
                            />
                        }
                        {overlayName === "criteriaHandler" &&
                            <CriteriaBoard
                                session={session}
                                sessionUser={sessionUser}
                            />
                        }
                        {overlayName === "reportErrorHandler" &&
                            <ReportErrors
                                session={session}
                                sessionUser={sessionUser}
                            />
                        }
                        {overlayName === "individualProfile" &&
                            <IndividualProfile
                                session={session}
                                sessionUser={sessionUser}
                            />
                        }
                        {overlayName === "uploadSuccess" &&
                            <UploadSuccess
                                session={session}
                                sessionUser={sessionUser}
                            />
                        }
                        {overlayName === "uploadHistory" &&
                            <UploadHistory
                                session={session}
                                sessionUser={sessionUser}
                            />
                        }
                        {overlayName === "uploadFailure" &&
                            <UploadFailure
                                session={session}
                                sessionUser={sessionUser}
                            />
                        }
                        {overlayName === "SFTPSuccess" &&
                            <SFTPSuccess
                                session={session}
                                sessionUser={sessionUser}
                            />
                        }
                        {overlayName === "removeWarning" &&
                            <RemoveWarning
                                session={session}
                                sessionUser={sessionUser}
                                setter={props?.setter}
                            />
                        }
                        {overlayName === "clearWarning" &&
                            <ClearWarning
                                session={session}
                                sessionUser={sessionUser}
                                setter={props?.setter}
                            />
                        }
                        {overlayName === "userAgreement" &&
                            <UserAgreementOverlay
                                session={session}
                                sessionUser={sessionUser}
                                setter={props?.setter}
                            />
                        }
                        {overlayName === "contractLimit" &&
                            <ContractLimitOverlay
                                session={session}
                                sessionUser={sessionUser}
                                setter={props?.setter}
                            />
                        }
                        {overlayName === "adminBoard" &&
                            <AdminBoard
                                session={session}
                                tab = {overlayProps[0]}
                            />
                        }
                        {overlayName === "reportOptions" &&
                            <ReportOptions
                                session={session}
                                sessionUser={sessionUser}
                                setter={props?.setter}
                            />
                        }
                        {overlayName === "sftpConfirmation" &&
                            <SFTPConfirmation
                                session={session}
                                sessionUser={sessionUser}
                                setter={props?.setter}
                                file={overlayProps}
                            />
                        }
                    </div>
                    {moduleBlockType[overlayName] === " wAction" &&
                        <ActionBar
                            session={session}
                            sessionUser={sessionUser}
                            setter={props?.setter} 
                        />
                    }
                </div>
            </div>
        :
            false
    )
};

export default OverlayHandler