import React from 'react';
import { useState, useEffect, useRef } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import EventRepeatTwoToneIcon from '@mui/icons-material/EventRepeatTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CircleIcon from '@mui/icons-material/Circle';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import TodayIcon from '@mui/icons-material/Today';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';

import { CircularProgress } from '@mui/material';
import Dropdown from '../Dropdown.js';

function ReportList(props){
    const selectedFolder = props?.selectedFolder?.data;
    const setSelectedFolder = props?.selectedFolder?.set;

    const [existingReports, setExistingReports] = useState([]);
    const [expandedFolders, setExpandedFolders] = useState({});
    const [expandedYears, setExpandedYears] = useState({});
    const [expandedMonths, setExpandedMonths] = useState({});

    const session = props?.session;
    const viewType = session?.env?.viewport?.viewType;
    const reporting = session?.reporting;
    const currentAccountID = session?.handler?.data?.currentAccountID;
    const allReports = reporting?.data[currentAccountID]?.allReports;
    const selectedReport = reporting?.data[currentAccountID]?.selectedReport;
    const updateReporting = session?.reporting?.setData;
    const newReportTemplate = reporting?.data[currentAccountID]?.reset;
    const reportablePages = ["deceased", "individuals"];
    const reportingView = props?.reportingView;

    const opportunityTypes = (props?.session?.user?.data?.opportunityTypes || []).filter(type => reportablePages.includes(type));
    const reportData = reporting?.data[currentAccountID]?.reportDataHandler;
    const currentlyEditing = !selectedReport?.recordID && JSON.stringify(selectedReport) !== JSON.stringify(newReportTemplate);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear().toString();
    const currentMonth = (`0${currentDate.getMonth() + 1}`).slice(-2);
    const getSelectedReportData = (selectedReport) => {
        const getSegment = (dateString, start, end, fallback) => 
            (dateString?.slice(start, end) || fallback).padStart(2, '0');
    
        return {
            year: getSegment(selectedReport?.details?.startDate, 0, 4, currentYear),
            month: getSegment(selectedReport?.details?.startDate, 5, 7, currentMonth) ||
                   getSegment(selectedReport?.generationTime, 5, 7, currentMonth),
            group: getGroupKey(selectedReport)
        };
    };

    const [selectedReportData, setSelectedReportData] = useState(() => getSelectedReportData(selectedReport));

    const reportCategory = props?.reportCategoryObj?.reportCategory;
    const setReportCategory = props?.reportCategoryObj?.setReportCategory;

    if(props?.summary && reportCategory !== 'individuals' && reportCategory !== 'deceased'){
        setReportCategory('individuals');
    }

    const groupHandlerData = {
        "system" : {
            friendlyTerm : "Default",
            icon : <WysiwygIcon/>,
            ignoreNesting : true
        },
        "weekly" : {
            friendlyTerm : "Weekly",
            icon : <DateRangeIcon/>
        },
        "daily" : {
            friendlyTerm : "Daily",
            icon : <TodayIcon/>
        },
        "groupless" : {
            friendlyTerm : "Custom",
            icon : <FolderOpenIcon/>
        },
    }

    const monthName = (monthNumber) => {
        const months = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
      
        if (monthNumber >= 1 && monthNumber <= 12) {
          return months[monthNumber - 1];
        }

        return "Invalid Month";
    }

    function reportFolders() {
        const opportunityTypeCount = opportunityTypes?.length + (props?.summary ? 0 : 2);

        const swappedOpportunityTypes = [...opportunityTypes];
        if (swappedOpportunityTypes.length > 1) {
            // Swap the first and second objects
            [swappedOpportunityTypes[0], swappedOpportunityTypes[1]] = [swappedOpportunityTypes[1], swappedOpportunityTypes[0]];
        }
        return (
            <div
                className="categories g f cC fR"
                style={opportunityTypes ? {gridTemplateColumns : `repeat(${opportunityTypeCount}, 1fr)`} : null}  
            >
                {swappedOpportunityTypes?.map((reportType, index) => {
                    const reportTypeInfo = reportData?.[reportType];
                    if (reportablePages.includes(reportType)) {
                        return (
                            <div
                                key={index}
                                className={`option p cC f fC g bold${reportCategory === reportType ? " active" : ''}`}
                                onClick={() => {
                                    setReportCategory(reportType);
                                }}
                            >
                                <div className="gR2 f cC">
                                    {reportTypeInfo?.icon}
                                </div>
                                {selectedReport?.stem === reportType &&
                                    <div className="f cC gR3">
                                        <div className="bullet">
                                        </div>
                                    </div>
                                }
                            </div>
                        );
                    } else {
                        return null; // Return null for individuals not handled
                    }
                })}
                {!props?.summary &&
                    <>
                        <div 
                            key="scheduled"
                            className={`option g p cC f fC bold${reportCategory === "scheduled" ? " active" : ''}`}
                            onClick={() => {
                                setReportCategory("scheduled");
                            }}
                        >
                            <div className="gR2 f cC">
                                <EventRepeatTwoToneIcon/>
                            </div>
                        </div>
                        <div
                            key="summary"
                            className={`option g p cC f fC bold${reportCategory === "summary" ? " active" : ''}`}
                            onClick={() => {
                                setReportCategory("summary");
                            }}
                        >
                            <div className="gR2 f cC">
                                <AssessmentTwoToneIcon/>
                            </div>
                            {selectedReport?.stem === "summary" &&
                                <div className="f cC gR3">
                                    <div className="bullet">
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
        );
    }

    function getGroupKey(selectedReport) {
        const groupID = selectedReport?.groupID?.value;

        if (groupID === -1) {
            return 'system';
        } else if (groupID === -2) {
            return 'weekly';
        } else if (groupID === -3) {
            return 'daily';
        } else if (groupID === 0 || groupID === undefined) {
            return 'groupless';
        } else if (groupID > 0) {
            return groupID.toString(); // Convert to string if the groupID is a number
        } else {
            return null; // Return null for invalid groupIDs
        }
    }

    function iterateExistingReports(reset){
        const groupHandler = {
            "system" : [],
            "weekly" : [],
            "daily" : [],
            "groupless" : [],
        }

        allReports?.forEach((report) => {
            if(report?.relatedAccountID == currentAccountID || report?.relatedAccountID == null){
                const groupID = report?.groupID?.value;
                const targetGroup = 
                    groupID === -1 ? groupHandler.system :
                    groupID === -2 ? groupHandler.weekly :
                    groupID === -3 ? groupHandler.daily :
                    groupID === 0 || groupID === undefined ? groupHandler.groupless :
                    groupHandler[groupID] || (groupHandler[groupID] = []);
            
                targetGroup.push(report);
            }
        });

        for (const groupKey in groupHandler) {
            if (groupHandler.hasOwnProperty(groupKey)) {
                groupHandler[groupKey].sort((a, b) => {
                    if (a.details?.startDate && b.details?.startDate) {
                        return new Date(b.details.startDate) - new Date(a.details.startDate);
                    } else if (a.details?.startDate) {
                        return -1;
                    } else if (b.details?.startDate) {
                        return 1;
                    } else {
                        return b.generationTime - a.generationTime;
                    }
                });
            }
        }

        if (groupHandler?.weekly?.length > 0) {
            groupHandler.system.unshift(groupHandler.weekly[0]);
        }

        if (groupHandler?.daily?.length > 0) {
            groupHandler.system.unshift(groupHandler.daily[0]);
        }

        function generateGroupHierarchy(data) {
            return Object.keys(data).reduce((hierarchy, groupKey) => {
              const group = data[groupKey];
              const groupHierarchy = {};
          
              group.forEach((report) => {
                const startDate = new Date(report.details.startDate);
                const year = !isNaN(startDate.getFullYear()) ? startDate.getFullYear().toString() : currentYear;
                const month = !isNaN(startDate.getMonth() + 1) ? (startDate.getMonth() + 1).toString().padStart(2, '0') : currentMonth;
          
                groupHierarchy[year] = groupHierarchy[year] || {};
                groupHierarchy[year][month] = groupHierarchy[year][month] || { reports: [] };
          
                groupHierarchy[year][month].reports.push({ report });
              });
          
              hierarchy[groupKey] = groupHierarchy;
              return hierarchy;
            }, {});
        }
        
        const groupHierarchy = generateGroupHierarchy(groupHandler);

        function Header({ type, prompt, index, showValue, folder, year, month, tempObj, parentCount, folderObj}) {
            const [showStatus, setShowStatus] = useState(false);
            let activityStatus =
                reportCategory === selectedReport?.stem && (
                    type === "group" && selectedReportData?.group === folder && selectedReport?.recordID ||
                    type === "year" && selectedReportData?.year === year && selectedReportData.group === folder && selectedReport?.recordID ||
                    type === "month" && selectedReportData?.year === year && selectedReportData?.month === month && selectedReportData?.group === folder && selectedReport?.recordID
                );

            let activityIcon = type === "month" ? <CircleTwoToneIcon /> : <CircleIcon />;

            useEffect(() => {
                if (type === "group") {
                    setShowStatus(tempObj?.[folder] === undefined ? showValue : tempObj?.[folder] ?? false);
                } else if (type === "year") {
                    setShowStatus(tempObj?.[folder]?.[year] === undefined ? showValue : tempObj?.[folder]?.[year] ?? false);
                } else if (type === "month") {
                    setShowStatus(tempObj?.[folder]?.[year]?.[month] === undefined ? showValue : tempObj?.[folder]?.[year]?.[month] ?? false);
                }
            }, [tempObj, folder, year, month, type]);

            return (
                folderObj?.ignoreNesting && type !== "group" ?
                    null
                :
                    <div
                        key={type + prompt + index}
                        className={`reportGroupHeader ${type} dG g bold p bR nS lH`}
                        onClick={() => {
                            setShowStatus((prevShowStatus) => !prevShowStatus);

                            if (type === "group") {
                                setShowStatus((prevShowStatus) => !prevShowStatus);
                                setExpandedFolders((prevExpandedFolders) => ({
                                ...prevExpandedFolders,
                                [folder]: !showStatus,
                                }));
                            } else if (type === "year") {
                                setShowStatus((prevShowStatus) => !prevShowStatus);
                                setExpandedYears((prevExpandedYears) => ({
                                ...prevExpandedYears,
                                [folder]: {
                                    ...prevExpandedYears[folder],
                                    [year]: !showStatus,
                                },
                                }));
                            } else if (type === "month") {
                                setShowStatus((prevShowStatus) => !prevShowStatus);
                                setExpandedMonths((prevExpandedMonths) => ({
                                ...prevExpandedMonths,
                                [folder]: {
                                    ...prevExpandedMonths[folder],
                                    [year]: {
                                    ...prevExpandedMonths[folder]?.[year],
                                    [month]: !showStatus,
                                    },
                                },
                                }));
                            }
                        }}
                    >
                        {activityStatus &&
                            <div className="f cC s activityStatus">
                                {activityIcon}
                            </div>
                        }
                        <div className={`f gC2 g prompt dG${type === "month" ? " cR" : " cL"}`}>
                            {folderObj?.icon  && type === "group" &&
                                <div className="f cC">
                                    {folderObj?.icon}
                                </div>
                            }
                            <div className={`f cL`}>
                                {prompt}
                            </div>
                        </div>
                        <div className="f cC">
                            {showStatus ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                        </div>
                    </div>
            );
        }

        function ReportItem({ report, reportIndex, folderKey }) {
            const iconEligible = reportCategory === "scheduled" || reportCategory === "summary" || (folderKey === "system" && (report?.frequency === "Weekly" || report?.frequency === "Daily"));
            const handleReportClick = () => {
                if(props?.summary){
                    const newSelectedReportData = {...selectedReport};
                    newSelectedReportData.referenceBranch = report?.branch;
                    newSelectedReportData.selectAll = report?.selectAll;
                    newSelectedReportData.referenceStem = report?.stem;
                    newSelectedReportData.referenceType = reporting?.functions?.reportType(report?.stem);
                    newSelectedReportData.referenceRecordID = report?.recordID;
                    newSelectedReportData.criteria = {
                        ...report?.criteria,
                        groupBy: []
                    };
                    updateReporting(`${session?.handler?.data?.currentAccountID}`, "selectedReport", newSelectedReportData);
                }else{
                    const isTempReport = currentlyEditing || (report?.recordID !== selectedReport?.recordID && unsavedChanges(true));

                    const reportType = reporting?.functions?.reportType(report?.stem);
                    const reportTypeKey = isTempReport ? "tempReport" : "selectedReport";
                    const reportData = !currentlyEditing && report?.recordID === selectedReport?.recordID ? selectedReport : report;
                    
                    updateReporting(`${session?.handler?.data?.currentAccountID}`, reportTypeKey, reportData);
                    
                    if (currentlyEditing || (report?.recordID !== selectedReport?.recordID && unsavedChanges(true))){
                        session?.env?.setOverlay("reportErrorHandler");
                    }

                    if (!currentlyEditing) {
                        if (report?.recordID !== selectedReport?.recordID) {
                            if (!unsavedChanges(true) && reporting?.data[currentAccountID]?.selectionState?.length){
                                    reporting?.functions?.toggleAllSelectRows(selectedReport?.sortedListResults?.data, selectedReport?.stem, false);
                            }
                            if (reportType === "generated" && selectedReport?.recordID !== report?.recordID && !unsavedChanges(true)) {
                                reporting?.functions?.requestResultBlock({ newQuery: report?.query, newColumns: report?.columns }, props?.requestObject, currentAccountID);
                            }
                        } else {
                            if(reporting?.data[currentAccountID]?.selectionState?.length){
                                reporting?.functions?.toggleAllSelectRows(selectedReport?.sortedListResults?.data, selectedReport?.stem, false);
                            }
                        }
                    }
 
                    if (viewType === "mobile") {
                        reportingView?.set("report");
                    }
                }
            };

            return (
                // if report.frequency === weekly, if report.relatedAccountID === currentAccountID || report?.frequency === session?.account?.data[currentAccountID]?.reportFrequency
                (report?.relatedAccountID == currentAccountID || report?.relatedAccountID == null) && (
                    <div
                        className="parentingContainerOfMonth"
                        key={report?.recordID + reportIndex}
                    >
                        <div
                            title={report?.details?.name ?? "Unnamed Report"}
                            key={folderKey + report?.recordID}
                            className={`criterionBlock cC g p s e${
                                selectedReport?.recordID === report?.recordID ? " active" : ''}${
                                iconEligible ? " wIcon" : ''}${folderKey === "system" && reportCategory === "scheduled" ? "s" : ''}`}
                            onClick={handleReportClick}
                        >
                            {iconEligible &&
                                (folderKey === "system" ?
                                    (reportCategory === "scheduled" || reportCategory === "summary") ?
                                        <>
                                            {reportData?.[report?.referenceStem ?? report?.stem]?.icon}
                                            {groupHandlerData?.[report?.frequency?.toLowerCase()]?.icon}
                                        </>
                                    :
                                        groupHandlerData?.[report?.frequency?.toLowerCase()]?.icon
                                :
                                    reportData?.[report?.referenceStem ?? report?.stem]?.icon)
                            }
                            <div className="bold f cL gCW s">
                                {unsavedChanges(report?.recordID) && selectedReport?.recordID === report?.recordID && "*"}
                                {report?.details?.name ?? "Unnamed Report"}
                            </div>
                            {!props?.summary && reportCategory !== "scheduled" &&
                                <div
                                    className="icon f cC dP"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (currentlyEditing) {
                                            session?.env?.setOverlay("reportErrorHandler");
                                            updateReporting(`${session?.handler?.data?.currentAccountID}`, "tempReport", report);
                                        } else if (unsavedChanges(true) && report?.recordID !== selectedReport?.recordID) {
                                            session?.env?.setOverlay("reportErrorHandler");
                                            updateReporting(`${session?.handler?.data?.currentAccountID}`, "tempReport", report);
                                        } else {
                                            if (report?.recordID !== selectedReport?.recordID) {
                                                updateReporting(`${session?.handler?.data?.currentAccountID}`, "selectedReport", report);
                                            }
                                            session?.env?.setOverlay("criteriaHandler");
                                        }
                                    }}
                                >
                                    <EditIcon/>
                                </div>
                            }
                        </div>
                    </div> 
                )
            );
        }

        function initializeNestedProperty(obj, keys, initialValue) {
            let currentObj = obj;
            for (const key of keys) {
                // if (currentObj[key] === undefined || currentObj[key] !== initialValue) {
                if (currentObj[key] === undefined) {
                        currentObj[key] = key === keys[keys.length - 1] ? initialValue : {};
                }
                currentObj = currentObj[key];
            }
        }

        function matchingReports(levelData, reportCategory, level) {
            let count = 0;

            switch (level) {
                case 'group':
                    Object.keys(levelData).forEach(yearKey => {
                        const yearData = levelData[yearKey];
                        Object.keys(yearData).forEach(monthKey => {
                            const monthData = yearData[monthKey];
                            count += monthData.reports.reduce((acc, reportObject) => {
                                if(reportCategory === "scheduled"){
                                    return acc + (reportObject.report?.scheduled === true ? 1 : 0);
                                }else{
                                    return acc + (reportObject.report?.stem === reportCategory ? 1 : 0);
                                }
                            }, 0);
                        });
                    });
                    break;

                case 'year':
                    Object.keys(levelData).forEach(monthKey => {
                        const monthData = levelData[monthKey];
                        count += monthData.reports.reduce((acc, reportObject) => {
                            if(reportCategory === "scheduled"){
                                return acc + (reportObject.report?.scheduled === true ? 1 : 0);
                            }else{
                                return acc + (reportObject.report?.stem === reportCategory ? 1 : 0);
                            }
                        }, 0);
                    });
                    break;

                case 'month':
                    count = levelData.reports.reduce((acc, reportObject) => {
                        if(reportCategory === "scheduled"){
                            return acc + (reportObject.report?.scheduled === true ? 1 : 0);
                        }else{
                            return acc + (reportObject.report?.stem === reportCategory ? 1 : 0);
                        }
                    }, 0);
                    break;

                default:
                    break;
            }
          
            return count;
        }

        function NestedSection({ childrenCount, ignore, elements, type, showStatus}) {
            if(childrenCount === 0 || showStatus === false){
                return null;
            }

            const elementsList = [
                <div
                    key="elements"
                    className={`reportNested g ${type}`}
                >
                    {elements}
                </div>
            ];

            const splitElement = [
                <div
                    key="split"
                    className="split g"
                >
                    <div className="vDivider">
                    </div>
                    {elementsList}
                </div>
            ];

            return (
                ignore ?
                    elementsList
                :
                    splitElement
            )
        }

        const tempExpandedFolders = reset ? {} : { ...expandedFolders };
        const tempExpandedYears = reset ? {} : { ...expandedYears };
        const tempExpandedMonths = reset ? {} : { ...expandedMonths };
        const reportElements = [];
        let expandedFolder = false;

        {Object.keys(groupHandlerData).filter((folderKey, index) => {
            const category = groupHandlerData[folderKey];
            return (
                selectedFolder?.name === "Show All" ||
                category.friendlyTerm === selectedFolder?.name ||
                reportCategory === "scheduled"
            );
        }).map((folderKey, index) => {
            const category = groupHandlerData[folderKey];
            const isFirstCategory = index === 0;
            const categoryData = groupHierarchy?.[folderKey];
            const folderVisibleChildren = matchingReports(categoryData, reportCategory, 'group');
            const folderChildrenVisibilityStatus = folderVisibleChildren > 0;
            const folderShowStatus = ((
                tempExpandedFolders?.[folderKey] === undefined && isFirstCategory) ||
                tempExpandedFolders?.[folderKey] === undefined && !expandedFolder ||
                tempExpandedFolders?.[folderKey] === true
            ) &&
            folderChildrenVisibilityStatus;

            const folderElements = [];

            if(folderChildrenVisibilityStatus){
                initializeNestedProperty(tempExpandedFolders, [folderKey], folderShowStatus);
    
                if(folderShowStatus && !expandedFolder){
                    expandedFolder = true;
                }

                reportElements.push(
                    <div key={`${folderKey}-folder`} className={`reportFolder g bR`}>
                        <Header
                            type="group"
                            prompt={category?.friendlyTerm}
                            index={index}
                            showValue={folderShowStatus}
                            folder={folderKey}
                            tempObj={tempExpandedFolders}
                            folderObj={category}
                        />
                        <NestedSection
                            ignore={category?.ignoreNesting}
                            elements={folderElements}
                            childrenCount={folderVisibleChildren}
                            type={"folder"}
                            showStatus={folderShowStatus}
                        />
                    </div>
                )
            }

            if (folderShowStatus) {
                const years = Object.keys(categoryData).sort((a, b) => parseInt(b) - parseInt(a));
                let expandedYear = false;
                years.forEach((yearKey, yrIndex) => {
                    const yearElements = [];
                    const yearData = categoryData[yearKey];
                    const yearVisibleChildren = matchingReports(yearData, reportCategory, 'year');
                    const yearChildrenVisibilityStatus = yearVisibleChildren > 0;
                    const yearShowStatus = ((
                            tempExpandedYears?.[folderKey]?.[yearKey] === undefined && isFirstCategory && yrIndex === 0) ||
                            tempExpandedYears?.[folderKey]?.[yearKey] === undefined && !expandedYear ||
                            tempExpandedYears?.[folderKey]?.[yearKey] === true
                        ) &&
                        yearChildrenVisibilityStatus;

                    if(yearChildrenVisibilityStatus || tempExpandedFolders?.[folderKey]  === undefined && folderShowStatus || category?.ignoreNesting){
                        initializeNestedProperty(tempExpandedYears, [folderKey, yearKey], yearShowStatus);

                        if(yearShowStatus && !expandedYear){
                            expandedYear = true;
                        }

                        folderElements.push(
                            <React.Fragment key={folderKey + yearKey}>
                                <Header
                                    type="year"
                                    prompt={yearKey}
                                    index={yrIndex}
                                    showValue={yearShowStatus}
                                    folder={folderKey}
                                    year={yearKey}
                                    tempObj={tempExpandedYears}
                                    parentCount={yearVisibleChildren}
                                    folderObj={category}
                                />
                                <NestedSection
                                    ignore={category?.ignoreNesting}
                                    elements={yearElements}
                                    childrenCount={yearVisibleChildren}
                                    type={"year"}
                                />
                            </React.Fragment>
                        );
                    }

                    const months = Object.keys(categoryData?.[yearKey]).sort((a, b) => parseInt(b) - parseInt(a));
                    let expandedMonth = false;
                    months.forEach((monthKey, moIndex) => {
                        const monthElements = [];
                        const monthData = categoryData[yearKey][monthKey]; // Placeholder for month data
                        const monthVisibleChildren = matchingReports(monthData, reportCategory, 'month');
                        const monthChildrenVisibitliyStatus = monthVisibleChildren > 0;
                        const monthShowStatus = (
                            (tempExpandedMonths?.[folderKey]?.[yearKey]?.[monthKey] === undefined && isFirstCategory && yrIndex === 0 && moIndex === 0) ||
                            tempExpandedMonths?.[folderKey]?.[yearKey]?.[monthKey] === undefined && !expandedMonth ||
                                tempExpandedMonths?.[folderKey]?.[yearKey]?.[monthKey] === true
                            ) &&
                            monthChildrenVisibitliyStatus;

                        if(
                            monthChildrenVisibitliyStatus &&
                                tempExpandedYears?.[folderKey]?.[yearKey] === true ||
                            tempExpandedYears?.[folderKey]?.[yearKey]  === undefined &&
                                yearShowStatus ||
                            category?.ignoreNesting
                        ){
                            initializeNestedProperty(tempExpandedMonths, [folderKey, yearKey, monthKey], monthShowStatus);

                            if(monthShowStatus && !expandedMonth){
                                expandedMonth = true;
                            }

                            yearElements.push(
                                <React.Fragment key={folderKey + yearKey + monthKey}>
                                    <Header
                                        type="month"
                                        prompt={monthName(monthKey)}
                                        index={moIndex}
                                        showValue={monthShowStatus}
                                        folder={folderKey}
                                        year={yearKey}
                                        month={monthKey}
                                        tempObj={tempExpandedMonths}
                                        parentCount={monthVisibleChildren}
                                        folderObj={category}
                                    />
                                    <NestedSection
                                        ignore={category?.ignoreNesting}
                                        elements={monthElements}
                                        childrenCount={monthVisibleChildren}
                                        type={"month"}
                                    />
                                </React.Fragment>
                            );
                        }

                        const reportList = categoryData?.[yearKey]?.[monthKey]?.reports;

                        if(
                            tempExpandedFolders?.[folderKey] === true
                                && tempExpandedYears?.[folderKey]?.[yearKey] === true
                                && tempExpandedMonths?.[folderKey]?.[yearKey]?.[monthKey] === true
                            ||
                            tempExpandedMonths?.[folderKey]?.[yearKey]?.[monthKey] === undefined
                                && monthShowStatus
                            ||
                                category?.ignoreNesting
                            ||
                                reportCategory === "scheduled"
                        ){
                            reportList.forEach((reportObject, index) => {
                                const report = reportObject?.report;
                                if(
                                    reportObject?.report?.stem === reportCategory ||
                                    reportObject?.report?.scheduled === true &&
                                        reportCategory === "scheduled" &&
                                        monthShowStatus
                                ){
                                    monthElements.push(
                                        <ReportItem
                                            key={report.recordID}
                                            report={report}
                                            folderKey={folderKey}
                                        />
                                    );
                                }
                            });
                        }
                    });
                });
            }
        })}

        setExistingReports(reportElements);

        if(JSON.stringify(tempExpandedFolders) !== JSON.stringify(expandedFolders)){
            setExpandedFolders(tempExpandedFolders);
        }

        if(JSON.stringify(tempExpandedYears) !== JSON.stringify(expandedYears)){
            setExpandedYears(tempExpandedYears);
        }

        if(JSON.stringify(tempExpandedMonths) !== JSON.stringify(expandedMonths)){
            setExpandedMonths(tempExpandedMonths);
        }
    }

    const TopBarActionButton = () => {
        if(props?.summary){
            return (
                <div
                    key="createBtn"
                    className={`criterionBlock create cC g p e`}
                    onClick={() => {
                            const reportTypeInfo = reportData?.[reportCategory];

                            const summaryReport = { ...selectedReport };
                            summaryReport.referenceBranch = reportTypeInfo?.branchType;
                            summaryReport.referenceStem = reportTypeInfo?.stem;
                            summaryReport.referenceRecordID = null;

                            updateReporting(`${session?.handler?.data?.currentAccountID}`, "selectedReport", summaryReport);                            
                        }
                    }
                >
                    <div className={`bold f cC${viewType === "mobile" ? " gC2" : ''}`}>
                        Set {reportCategory?.charAt(0)?.toUpperCase() + reportCategory?.slice(1)} Filters
                    </div>
                    <div className={`f cR zTPC${viewType === "mobile" ? " gC3" : " gC2"}`}>
                        <ChevronRightTwoToneIcon/>
                    </div>
                </div>
            )
        }else{
            return (
                <div
                    key="createBtn"
                    className={`criterionBlock create cC g p e${currentlyEditing ? " active" : ''}`}
                    onClick={() => {
                        session?.env?.setOverlay("criteriaHandler");
                        if(selectedReport?.recordID && !unsavedChanges(true)){
                            updateReporting(`${session?.handler?.data?.currentAccountID}`, "selectedReport", {...newReportTemplate});
                        }else if(unsavedChanges(true) && selectedReport?.recordID){
                            session?.env?.setOverlay("reportErrorHandler");
                            updateReporting(`${session?.handler?.data?.currentAccountID}`, "tempReport", {...newReportTemplate});
                        }
                    }}
                >
                    <div className={`bold f cC${viewType === "mobile" ? " gC2" : ''}`}>
                        {currentlyEditing ?
                            "Continue Editing"
                        :
                            "Create New Report"
                        }
                    </div>
                    <div className={`f cC zTPC${viewType === "mobile" ? " gC3" : " gC2"}`}>
                        {currentlyEditing ?
                            <EditIcon/>
                        :
                            <AddCircleIcon/>
                        }
                    </div>
                </div>
            )
        }
    }

    function printReportsList(){
        return (
            <>
                <TopBarActionButton/>
                <Dropdown
                    setShowMenu={(input) => {
                        setSelectedFolder((prev) => ({
                            ...prev,
                            showMenu: input ?? !prev.showMenu,
                        }));
                    }}
                    showMenu={selectedFolder?.showMenu}
                    default="Show All"
                    list={["Default", "Weekly", "Daily", "Custom"]}
                    onClick={(value) => {
                        setSelectedFolder({
                            name : value,
                            showMenu: false,
                        });
                    }}
                    flex={true}
                    reset={props?.resetDropdowns}
                />
                {existingReports?.length ?
                    existingReports
                :
                    <div className="content f cC bR oH">
                        <div className="f loading cC">
                            {props?.summary ?
                                "Select Report or Set Filters"
                            :
                                "No Saved Reports Found"
                            }
                        </div>
                    </div>
                }
            </>
        )
    }

    const unsavedChanges = (reportID) => {
        if (!selectedReport || Object?.keys(reporting?.data[currentAccountID]?.allReports)?.length === 0) return false;
            const index = reporting?.data[currentAccountID]?.allReports?.findIndex(
            (report) => report.recordID === selectedReport.recordID
        );

        if (index === -1) return false;

        if(reportID === true){
            return !reporting?.functions?.isEqualWithoutAttributes(
                reporting?.data[currentAccountID]?.allReports[index], selectedReport, [
                    "list",
                    "totalFound",
                    "lastRecordID",
                    "lastPageIndex",
                    "criteria.current",
                    "columns.*.frozen",
                    "subReport",
                    "sortedListResults"
                ]
                // ) || props?.editingRowValues?.data?.length > 0;
            );
        }else if (selectedReport.recordID) {
            return !reporting?.functions?.isEqualWithoutAttributes(
                reporting?.data[currentAccountID]?.allReports[index], selectedReport, [
                    "list",
                    "totalFound",
                    "lastRecordID",
                    "lastPageIndex",
                    "criteria.current",
                    "columns.*.frozen",
                    "subReport",
                    "sortedListResults"
                ]
                // ) || selectedReport?.recordID === reportID && props?.editingRowValues?.data?.length > 0;
            );
        }
    };

    useEffect(() => {
        iterateExistingReports();
    }, [expandedFolders, expandedYears, expandedMonths, props?.editingRowValues?.data]);

    useEffect(() => {
        iterateExistingReports(true);
    }, [selectedFolder, reportCategory]);

    useEffect(() => {
        setSelectedReportData(getSelectedReportData(selectedReport));
        iterateExistingReports();
    }, [selectedReport, allReports]);

    useEffect(() => {
        if(selectedReport == null){
            iterateExistingReports(true);
        }
    }, [selectedReport]);

    return (
        <>
            {(props?.viewType !== "mobile" || (props?.viewType === "mobile" && reportingView?.data === "list")) &&
                <div className={`reportListTopBar dG g fC oH${!props?.summary ? " b" : ''}`}>
                    <div className="reportCategory bR g fR">
                        {reportFolders()}
                    </div>
                    <div className={`criteriaSection f g dP oA gR2${!allReports ? " idle" : ''}${!existingReports?.length && allReports ? " noResults" : ''}`}>
                        {allReports ?
                            printReportsList()
                        :
                            <div className="content f cC bR oH">
                                <div className="f loading cC gR2">
                                    <div className="cC f">
                                        <div className="g dG cC">
                                            <CircularProgress color="inherit"/>
                                            <div className="dG cC f">
                                                <TableRowsTwoToneIcon color="inherit"/>
                                                Loading all reports
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )

}

export default ReportList;