import React, { useEffect, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function RemoveWarning(props){

    const session = props?.session;
    const viewType = session?.env?.viewport?.viewType;

    const removeFile = () => {
        // setFiles([]);
        // setHeaders([]);     
        session?.upload?.setData(prevData => ({
            ...prevData,
            removeFile: true,
            largeUpload: false,
            largeUploadCount: null,
            csvErrorsCounted: false,
            errorCountData: session?.upload?.data?.initialErrorCount,
        }));  
        session?.edit?.functions?.updateReport("rows", null, []);
        session?.edit?.functions?.updateReport("rowErrors", null, []);
        session?.edit?.functions?.updateReport("rowErrorKeys", null, []);
        session?.edit?.functions?.updateImportedList("columns", []);
        session?.edit?.functions?.updateReport("sorting", null, []);
        //session?.upload?.functions?.updateReport("errorCountData", session?.upload?.data?.initialErrorCount);
        props?.setter(false);
    }

    function printOverlayContent() {
        return (
                <div className="removeFileContainer f g fC dP tO gR2 bR">
                    <div className="gR1 cC gCW successMessage bold"><div><ErrorOutlineIcon/></div><div>Warning</div></div>
                    <div className="gR2 cC gCW successMessage bold">{viewType === "mobile" ? "Any changes will not be saved" : "Your file and any changes you have made will not be saved"}</div>
                </div>         
        );
    }

    return (
        <>
            <div className="overlayBoard g f fC oH dP">
                <div className='uploadFailure g'>
                    {printOverlayContent()}
                    
                </div>
                <div className='cC'>
                    <div className='btnWIcon p bR cC' style={{width:'30%', height:'50px'}} onClick={() => removeFile()}>Confirm</div>
                </div>
            </div>  
        </>
    );
};

export default RemoveWarning;