import React, { useEffect, useState, useRef } from 'react';

import InputField from "../InputField.js";
import ViewBarBtn from '../ViewBarBtn.js';
import PercentageBar from '../PercentageBar.js';

import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import WorkspacePremiumTwoToneIcon from '@mui/icons-material/WorkspacePremiumTwoTone';
import BadgeIcon from '@mui/icons-material/Badge';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import HomeIcon from '@mui/icons-material/Home';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import LanguageIcon from '@mui/icons-material/Language';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import HistoryIcon from '@mui/icons-material/History';
import PreviewIcon from '@mui/icons-material/Preview';
import LoopIcon from '@mui/icons-material/Loop';
import VerifiedIcon from '@mui/icons-material/Verified';
import FolderSharedTwoToneIcon from '@mui/icons-material/FolderSharedTwoTone';
import PlaylistAddCheckCircleTwoToneIcon from '@mui/icons-material/PlaylistAddCheckCircleTwoTone';
import TroubleshootTwoToneIcon from '@mui/icons-material/TroubleshootTwoTone';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ArticleIcon from '@mui/icons-material/Article';
import ContactsIcon from '@mui/icons-material/Contacts';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CloseIcon from '@mui/icons-material/Close';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import { CircularProgress } from '@mui/material';

function ReportProfile(props){
    const session = props?.session;
    const individualData = session?.individual?.data[session?.handler?.data?.currentAccountID];
    const viewType = session?.env?.viewport?.viewType;
    const analytics = session?.analytics;
    const selectedReport = analytics?.data?.selectedReport;
    const accountData = session?.account?.data?.[session?.user?.data?.accountID];
    const obitString = useRef(null);
    const formatDate = (date) => session?.env?.functions?.reformatDate(date);

    const lastRequest = {};

    const reportingData = session?.reporting?.data[session?.handler?.data?.currentAccountID];
    const updateReporting = session?.reporting?.setData;

    const selectedProfiles = props?.session?.individual?.data[session?.handler?.data?.currentAccountID]?.selectedProfile;
    const [selectedProfile, setSelectedProfile] = useState("");
    const [obituaries, setObituaries] = useState(selectedProfile?.matches);
    const [hasBeneficiaryData, setHasBeneficiaryData] = useState(false);

    const [individualLoading, setIndividualLoading] = useState(false);

    const formatDateVariations = (dateString) => {
        if (!dateString || !dateString.includes('-')) {
            return [];
        }

        const [year, month, day] = dateString.split("-").map(Number);
        const date = new Date(year, month - 1, day);
    
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const longDate = date.toLocaleDateString('en-US', options);
    
        const shortYear = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substr(-2)}`;
        const fullYear = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    
        return [longDate, shortYear, fullYear];
    };

    function calculateAge(dob, dod = null) {
        const birthDate = new Date(dob);
        const endDate = dod ? new Date(dod) : new Date(); // Use the provided date of death or the current date if dod is not provided
        let age = endDate.getFullYear() - birthDate.getFullYear();
        const monthDifference = endDate.getMonth() - birthDate.getMonth();
    
        if (monthDifference < 0 || (monthDifference === 0 && endDate.getDate() < birthDate.getDate())) {
            age--;
        }
    
        return age;
    }
    
    function weeksSince(date) {
        const givenDate = new Date(date);
        const today = new Date();
        const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7;
        return Math.floor((today - givenDate) / millisecondsPerWeek);
    }

    function daysSince(dateString) {
        const pastDate = new Date(dateString);
        const currentDate = new Date();
        const diffInTime = currentDate.getTime() - pastDate.getTime();
        const diffInDays = diffInTime / (1000 * 3600 * 24);
    
        return Math.floor(diffInDays);
    }

    const getHostName = url => {
        try {
            return new URL(url).hostname;
        } catch (e) {
            return undefined;
        }
    };

    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    function getNextMonday(dateString) {
        // Parse the date string into a Date object
        const date = new Date(dateString);
    
        // Check if the date is valid
        if (isNaN(date)) return null;
    
        // Get the day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
        const dayOfWeek = date.getDay();
    
        // Calculate how many days to add to get to the next Monday
        // If it's already Monday (1), we want to go to the next week's Monday, hence 7 days
        const daysToAdd = ((7 - dayOfWeek) % 7) || 7;
    
        // Create a new Date object for the next Monday
        const nextMonday = new Date(date);
        nextMonday.setDate(date.getDate() + daysToAdd);
    
        return nextMonday;
    }

    const [selectedObituary, setSelectedObituary] = useState(undefined);

    const selectedStem = selectedProfile?.stem;

    const currentView = props?.currentView;
    const setCurrentView = props?.setCurrentView;
    // const [currentView, setCurrentView] = useState("individual");
    
    const renderInputField = (label, value ) => {
        return (
          <InputField value={value ? value : "N/A"} placeholder={label} name={label.toLowerCase()} readOnly={true}/>
        );
    };

    function tacklineDecoration(){
        return (
            <div className="listTracker f g cC gC1">
                <div className="bullet">
                </div>
                <div className="vDivider">
                </div>
            </div>
        )
    }

    function printDeathReceipt(){
        function DeathReportRow({ label, value, backgroundColor, fontColor, icon }) {
            return (
                <div className="rowData g s">
                    <div className="s cL f">
                    <span className="cC dG lH">
                        {label} : <span
                            className={`bold e bR cC${icon ? " alt dG dP" : ''}`}
                            style={(backgroundColor && fontColor) ? {backgroundColor : backgroundColor, color : fontColor, borderColor: fontColor} : null}
                        >
                            {icon ?? null}{value}
                        </span>
                    </span>
                    </div>
                </div>
            );
        }

        function LinkRow({label, objectData, functionPointer}) {
            return (
                <div className="rowData g alt linkHandler">
                    <div className="f cC p" onClick={()=>{functionPointer(); setCurrentView("obituary")}}>
                        <VerticalSplitIcon/>
                    </div>
                    <div className={`cL f ${objectData?.href ? '' : 'inactive'}`}>
                        {objectData?.href ? (
                            <a target="_blank" href={objectData?.href}>
                                <span className="link p s e bR">{label}</span>
                            </a>
                        ) : (
                            <span className="link nS s e bR">No Link</span>
                        )}
                    </div>
                </div>
            );
        }

        return (
            <div className="deathReceipt g dG f">
                <div className="header bold cL s g dG">
                    <div className="g dG">
                        <ReceiptLongIcon />
                        <div className="f cC gCW">
                            Death Receipt
                        </div>
                    </div>
                </div>
                <div className="content bR dP g oH f fR">
                    <div className="receipt bR g fC dP dG oA">
                        <div className="statement f t cC">
                            {`Reported Death of ${selectedProfile?.first_name + " " + selectedProfile?.last_name}`}
                        </div>
                        <div className="f cC">
                            <div className="divider">
                            </div>
                        </div>
                        <div className="form g f cC fC">
                            {tacklineDecoration()}
                            <div className="row alt g f fC gC2 dP bR">
                                <div className="header bold cL g dG">
                                    <AssuredWorkloadIcon/>
                                    <div className="f cC gCW">
                                        Death Report
                                    </div>
                                </div>
                                <DeathReportRow
                                    label="Date of Death"
                                    value={formatDate(selectedProfile?.dod)}
                                />
                                <DeathReportRow
                                    label="Age at Death"
                                    value={calculateAge(selectedProfile?.dob, selectedProfile?.dod)}
                                />
                                <DeathReportRow
                                    label="Reported Time"
                                    value={formatDate(selectedProfile?.death_confirmation_date)}
                                />
                                <DeathReportRow
                                    label={`${accountData?.reportFrequency} Report`}
                                    value={
                                        (() => {
                                            const targetDate = new Date(selectedProfile?.death_confirmation_date);
                                            const reportsIncludingDate = reportingData?.allReports?.filter(report => {
                                                const startDate = new Date(report?.details?.startDate);
                                                const endDate = new Date(report?.details?.endDate);

                                                return startDate <= targetDate && targetDate <= endDate;
                                            });

                                            return reportsIncludingDate[0]?.details?.name;
                                        })()
                                    }
                                />
                            </div>
                            {tacklineDecoration()}
                            <div className="row alt g f fC gC2 dP bR">
                                <div className="header bold cL g dG">
                                    <VerifiedIcon/>
                                    <div className="f cC gCW">
                                        Sources and Verification
                                    </div>
                                </div>
                                <DeathReportRow
                                    label="Source"
                                    value={individualData?.sourceData?.[selectedProfile?.source]?.friendlyTerm}
                                    backgroundColor={individualData?.sourceData?.[selectedProfile?.source]?.backgroundColor}
                                    fontColor={individualData?.sourceData?.[selectedProfile?.source]?.fontColor}
                                    icon={individualData?.sourceData?.[selectedProfile?.source]?.icon}                                  
                                />
                                {obituaries && Object.keys(obituaries).filter(key => obituaries[key]?.href).length > 0 &&
                                    <DeathReportRow
                                        label="Confirmation Found"
                                        value={
                                            Object.keys(obituaries).filter(key => obituaries[key]?.hostName ?? obituaries[key]?.href).length > 0 ?
                                            `${Object.keys(obituaries).filter(key => obituaries[key]?.hostName ?? obituaries[key]?.href).length} Obituar${
                                                Object.keys(obituaries).filter(key => obituaries[key]?.hostName ?? obituaries[key]?.href).length === 1 ? "y" : "ies"}`
                                        :
                                            null
                                        }
                                    />
                                }
                                <div className="list f cL g dG">
                                    <div className="f cR">
                                        <div className="vDivider">
                                        </div>
                                    </div>
                                    <div className="f cL g dG gC2">
                                    {obituaries && Object.keys(obituaries).map(key => (
                                        (obituaries?.[key]?.hostName ?? getHostName(obituaries?.[key]?.href)) && (
                                            <LinkRow
                                                key={key}
                                                label={obituaries?.[key]?.hostName ?? getHostName(obituaries?.[key]?.href)}
                                                objectData={obituaries?.[key]}
                                                functionPointer={() => setSelectedObituary(obituaries?.[key])}
                                            />
                                        )
                                    ))}
                                    </div>
                                </div>
                            </div>
                            {tacklineDecoration()}
                            <div className="row alt g f fC gC2 dP bR">
                                <div className="header bold cL g dG">
                                    <HistoryIcon/>
                                    <div className="f cC gCW">
                                        History
                                    </div>
                                </div>
                                <DeathReportRow
                                    label="Monitoring Duration"
                                    value={`${weeksSince(selectedProfile?.createdAt)} week${weeksSince(selectedProfile?.createdAt) === 1 ? '' : 's'}`}
                                />
                            </div>
                        </div>
                        <div className="icon cR b">
                            <WorkspacePremiumTwoToneIcon />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function prinStatusReport(){
        function StatusReportRow({ label, value, backgroundColor, fontColor, icon }) {
            return (
                <div className="rowData g s">
                    <div className="s cL f">
                    <span className="cC dG lH">
                        {label} : <span
                            className={`bold e bR cC${icon ? " alt dG s" : ''}`}
                            style={(backgroundColor && fontColor) ? {backgroundColor : backgroundColor, color : fontColor, borderColor: fontColor} : null}
                        >
                            {icon ?? null}{value}
                        </span>
                    </span>
                    </div>
                </div>
            );
        }

        return (
            <div className="statusReport f g dG oA">
                <div className="header bold cC s g dG">
                    <div className="g dG">
                        <PreviewIcon />
                        <div className="f cC gCW">
                            Status Report
                        </div>
                    </div>
                </div>
                <div className="content bR dP g oH f fR">
                    <div className="receipt bR g fC dP oA tO">
                        <div className="statement f cC">
                            {`No Reported Death of ${selectedProfile?.first_name + " " + selectedProfile?.last_name}`}
                        </div>
                        <div className="form alt g cC">
                            {tacklineDecoration()}
                            <div className="row alt g f fC dP bR nS">
                                <div className="header bold cL g dG">
                                    <TravelExploreIcon/>
                                    <div className="f cC gCW">
                                        Tracking Status
                                    </div>
                                </div>
                                {/* <StatusReportRow label="Actively Tracking" value={selectedProfile?.upload ? "Pending" : selectedProfile?.disabled} /> */}
                                <StatusReportRow label="Actively Tracking" value={"Yes"} />
                                <StatusReportRow label="Tracked Since" value={selectedProfile?.upload ? "Pending" : formatDate(selectedProfile?.createdAt)} />
                            </div>
                        </div>
                        <div className="form alt g cC dG">
                            {tacklineDecoration()}
                            <div className="row alt g f fC dP bR nS">
                                <div className="header bold cL g dG">
                                    <EqualizerIcon/>
                                    <div className="f cC gCW">
                                        Demographics
                                    </div>
                                </div>
                                <div className="data g f cC dG bold b">
                                    Current Age
                                    <div className="circle value f g cC fC fR">
                                        <span className="f cC lH">
                                            {calculateAge(selectedProfile?.dob)}
                                        </span>
                                    </div>
                                </div>
                                <div className="data g f cC bold fC">
                                    {
                                        <PercentageBar
                                            rowData={
                                                {
                                                    averageAgeOfDeath : {
                                                        prompt : `Overall Average Age of Death`,
                                                        dataSet : reportingData?.deceased?.termDeaths,
                                                        value : "mean"
                                                    },      
                                                    ...(selectedProfile?.internal_group && {
                                                        groupAverageAgeOfDeath: {
                                                            prompt: `Average Age of Death in ${selectedProfile?.internal_group}`,
                                                            dataSet: selectedProfile?.groupData,
                                                            value: "mean",
                                                        },
                                                    }),
                                                }
                                            }
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="f cC">
                            <div className="footer g cC dG s e">
                                <LoopIcon/>
                                <div className="prompt f cC">
                                    {`Weeks of Tracking: `}
                                </div>
                                <div className="bold f cC">
                                    { weeksSince(selectedProfile?.createdAt) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        const activeIndividual = selectedProfiles?.active;
        const individual = selectedProfiles?.[activeIndividual?.upload ? "upload" : activeIndividual?.stem]?.[activeIndividual?.upload ? 0 : activeIndividual?.recordID];
        let path = individual?.stem;
        if(individual?.stem === "individuals"){
            path = "individual";
        }

        if(individual?.profile){
            setSelectedProfile({
                ...individual?.profile,
                "stem" : individual?.stem,
                "upload" : individual?.upload,
            });
            setCurrentView(currentView ?? "individual");
        }else{
            if(lastRequest?.recordID !== individual?.recordID || activeIndividual?.upload){
                const paramVals = {
                    recordID : individual?.recordID,
                    accountID: session?.handler?.data?.currentAccountID
                }

                lastRequest.recordID = individual?.recordID;

                if(activeIndividual?.upload){
                    setSelectedProfile({
                        ...activeIndividual?.profile,
                        "stem" : "individual",
                        "upload" : true
                    });
                    setCurrentView(currentView ?? "individual");
                    setHasBeneficiaryData(Object.keys(selectedProfile).some(key => {
                        return key.startsWith('benef_') && selectedProfile?.[key];
                    }));
                }else{
                    setIndividualLoading(true);
                    session?.env?.functions?.buildFetchRequest(`${path}/getOne`, paramVals)
                    .then(response => response.json())
                    .then(resData => {
                        if(resData.status === 200){
                            setIndividualLoading(false);
                            const obits = resData?.matches;
                            const profile = {
                                ...(resData?.[path] || {}),
                                ...(individual || {}),
                                ...(obits ? { matches: obits } : {}),
                                groupData : session?.individual?.functions?.calculateTermData(
                                    session?.individual?.data[session?.handler?.data?.currentAccountID]?.deathTrac?.deceased,
                                    accountData?.dateCreated,
                                    new Date().toISOString(),
                                    resData?.[path]?.internal_group
                                )                         
                            }

                            props?.session?.individual?.setData(`${session?.handler?.data?.currentAccountID}.selectedProfile.${individual?.stem}[${individual?.recordID}]`, {
                                stem : individual?.stem,
                                recordID : individual?.recordID, 
                                profile : profile
                            });

                            setSelectedProfile((prev) => ({
                                ...prev,
                                ...profile,
                            }));
                            setCurrentView(currentView ?? "individual");
                            setHasBeneficiaryData(Object.keys(profile).some(key => {
                                return key.startsWith('benef_') && profile?.[key];
                            }));

                            if (obits) {
                                Object.keys(obits).forEach(key => {
                                    obits[key].hostName = getHostName(obits?.[key]?.url ?? obits?.[key]?.fh_url);
                                    obits[key].href = obits?.[key]?.url ?? obits?.[key]?.fh_url;
                                    obits[key].type = obits?.[key]?.url ? "obituary" : "funeral";
                                });
                                setObituaries(obits);
                                setSelectedObituary(obits?.find(obit => obit?.hostName) || obits?.[0]);
                            }
                        }
                    });            
                }
            }
        }
    }, [selectedProfiles?.active]);

    useEffect(() => {
        setObituaries(selectedProfile?.matches);
        setSelectedObituary(selectedProfile?.matches?.[0]);
        if(selectedProfile?.source !== 'Obituary' && currentView === 'obituary'){
            setCurrentView("individual");
        }
    }, [selectedProfile]);

    useEffect(() => {
        setSelectedProfile("");
        updateReporting(`${session?.handler?.data?.currentAccountID}`, 'selectedRowIndex', null);
    }, [session?.reporting?.data[session?.handler?.data?.currentAccountID]?.selectedReport?.details?.name]);

    useEffect(() => {
        const approvedAttributes = ["city", "dob", "dod", "first_name", "last_name", "middle_name", "state", "zip_code"];

        if(selectedProfile?.matches && !selectedObituary){
            setSelectedObituary(selectedProfile?.matches?.find(obit => obit?.hostName));
        }

        if (obitString.current && selectedObituary?.text_content && selectedProfile) {
            // Normalize whitespace in obitStringHTML
            let obitStringHTML = selectedObituary?.text_content.replace(/\s+/g, ' ');

            if (selectedProfile.dob && selectedProfile.dod) {
                const age = calculateAge(selectedProfile.dob, selectedProfile.dod).toString();
                // Enhanced pattern to include a lookbehind for a space or start of line, and a lookahead for a space, end of line, or non-numeric character
                const agePattern = new RegExp(`(?<=^|\\s)${escapeRegExp(age)}(?=$|\\s|\\D)`, 'gi');
    
                if (agePattern.test(obitStringHTML)) {
                    obitStringHTML = obitStringHTML.replace(agePattern, `<span class="matchingText bR bold s e">${age}</span>`);
                }
            }

            approvedAttributes.forEach(key => {
                let values = [selectedProfile[key]];

                // Check if the attribute is a date
                if (key === 'dob' || key === 'dod') {
                    values = formatDateVariations(selectedProfile[key]);
                }

                values.forEach(value => {
                    if (value && typeof value === 'string') {
                        const normalizedValue = value.replace(/\s+/g, ' ');
                        // Use word boundaries (\b) around the normalized value to match only full words
                        const pattern = new RegExp(`\\b${escapeRegExp(normalizedValue)}\\b`, 'gi');
    
                        // Replace the matched value with bold tags
                        if (pattern.test(obitStringHTML)) { // Test if the pattern exists in the obitStringHTML before replacing
                            obitStringHTML = obitStringHTML.replace(pattern, `<span class="matchingText bR bold s e">${normalizedValue}</span>`);
                        }
                    }
                });
            });

            obitString.current.innerHTML = `<section>${obitStringHTML}</section>`;
        }
    }, [selectedProfile, selectedObituary, currentView]); // Ensure correct dependency array based on your needs

    return (
        <div className={`reportProfile selectedProfile g f fC ${
            selectedStem}${
                (!selectedProfile?.stem || !selectedProfile?.matches?.length || selectedProfile?.abl_source !== "Obituary") ?
                " alt" : ''}`}
        >
            {/* && !props?.profileLoading */}
            {(selectedProfile?.stem ) && 
                <>
                    {(true) &&
                        <div className="f cC g viewBarRow">
                            <div className="viewBar cC">
                                <ViewBarBtn
                                    isActive={currentView === "individual"}
                                    onClick={() => setCurrentView("individual")}
                                    label="Individual"
                                    icon={<PersonOutlineTwoToneIcon />}
                                    viewType={viewType}
                                    session={session}
                                />
                                <ViewBarBtn
                                    isActive={currentView === "deathReciept"}
                                    onClick={() => setCurrentView("deathReciept")}
                                    label={(selectedProfile?.stem === "deceased" || (selectedProfile?.dod || selectedProfile?.source) ) ? "Death Reciept" : "Status Report"}
                                    icon={<ReceiptLongIcon/>}
                                    viewType={viewType}
                                    session={session}
                                />
                                { 
                                    (selectedProfile?.source === 'Obituary') &&
                                        <ViewBarBtn
                                            isActive={currentView === "obituary"}
                                            onClick={() => setCurrentView("obituary")}
                                            label="Obituary"
                                            icon={<NewspaperIcon/>}
                                            viewType={viewType}
                                            session={session}
                                        />
                                }
                                
                            </div>
                            {/* <div className='cR gC2 f'> <div className='closeBtn p' onClick={()=> {setSelectedProfile(''); updateReporting(`${session?.handler?.data?.currentAccountID}`, 'profileOpen', false)}}><CloseIcon fontSize='large'/></div> </div> */}
                        </div>
                    }
                    {individualLoading ?
                            <div className='cC'>
                                <CircularProgress disableShrink color="success" variant="indeterminate" fontSize="large"/>
                            </div>
                        :
                    
                            <div className='g gR2 profileContent'>
                                {(currentView === "individual") &&
                                    <div className="body g s e oH f fR">
                                        <div className="form info f g dG fC oA">
                                            <div className="row g s fC">
                                                <div className="header bold cL s g dG">
                                                    <BadgeIcon/>
                                                    <div className="f cC gCW">
                                                        Personal Information
                                                    </div>
                                                    {selectedProfile?.internal_id &&
                                                        <div className="group f cC s e bR gCW">
                                                            {selectedProfile?.internal_id}
                                                        </div>
                                                    }
                                                    {selectedProfile?.internal_group &&
                                                        <div className="group f cC s e bR gCW">
                                                            {selectedProfile?.internal_group}
                                                        </div>
                                                    }
                                                </div>
                                                <div className="rowData name g s">
                                                    {renderInputField('First Name', selectedProfile?.first_name)}
                                                    {renderInputField('Middle Name', selectedProfile?.middle_name)}
                                                    {renderInputField('Last Name', selectedProfile?.last_name)}
                                                </div>
                                            </div>

                                            <div className="row g s fC">
                                                <div className="header bold cL s g dG">
                                                    <FingerprintIcon/>
                                                    <div className="f cC gCW">
                                                        Identification
                                                    </div>
                                                </div>
                                                <div className="rowData important g s">
                                                    {renderInputField('Social Security Number', selectedProfile?.ssn)}
                                                    {renderInputField('Birth Date', session?.env?.functions?.reformatDate(selectedProfile?.dob))}
                                                </div>
                                            </div>
                                            <div className="row g s fC">
                                                <div className="header bold cL s g dG">
                                                    <HomeIcon/>
                                                    <div className="f cC gCW">
                                                        Address
                                                    </div>
                                                </div>
                                                <div className="rowData location g s">
                                                    {renderInputField('City', selectedProfile?.city)}
                                                    {renderInputField('State', selectedProfile?.state)}
                                                    {renderInputField('Zip Code', selectedProfile?.zip_code)}
                                                </div>
                                            </div>
                                            {hasBeneficiaryData &&
                                                <div className="row g s fC">
                                                    <div className="header bold cL s g dG">
                                                        <ContactsIcon/>
                                                        <div className="f cC gCW">
                                                            Beneficiary Information
                                                        </div>
                                                        {selectedProfile?.internal_id &&
                                                            <div className="group f cC s e bR gCW">
                                                                {selectedProfile?.internal_id}
                                                            </div>
                                                        }
                                                        {selectedProfile?.internal_group &&
                                                            <div className="group f cC s e bR gCW">
                                                                {selectedProfile?.internal_group}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="rowData name g s">
                                                        {renderInputField('First Name', selectedProfile?.benef_first_name)}
                                                        {renderInputField('Middle Name', selectedProfile?.benef_middle_name)}
                                                        {renderInputField('Last Name', selectedProfile?.benef_last_name)}
                                                    </div>
                                                    <div className="rowData name g s">
                                                        {renderInputField('Date of Birth', session?.env?.functions?.reformatDate(selectedProfile?.benef_dob))}
                                                        {renderInputField('City', selectedProfile?.benef_city)}
                                                        {renderInputField('State', selectedProfile?.benef_state)}
                                                    </div>
                                                    <div className="rowData name g s">                   
                                                        {renderInputField('Zip Code', selectedProfile?.benef_zip)}
                                                        {renderInputField('Relationship', selectedProfile?.benef_relationship)}
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                }

                                {currentView === "obituary" &&
                                    <div className="obituaries g f fR s b l e">
                                        <div className="sources  f bR g dG">
                                            <div className={`header bold g cL dG${viewType === "mobile" ? " s" : " cC"}`}>
                                                <div className={`g dG ${viewType === "mobile" ? " s" : ''}`}>
                                                    <LanguageIcon className='gC1'/>
                                                    <div className='gC2'>{`Source${obituaries && Object.keys(obituaries)?.length !== 1 ? "s" : ''}`}</div>
                                                    
                                                </div>
                                            </div>
                                            <div className="content bR oA">
                                                <div className="f oH tO dP">
                                                    <div className="list dG g oA">
                                                        {obituaries && Object.keys(obituaries).filter(key => obituaries[key]?.hostName).map(key => {
                                                            const obituary = obituaries?.[key];
                                                            return (
                                                                <div
                                                                    key={key}
                                                                    className={`obituaryBlock cC g p e${selectedObituary === obituary ? " active" : ''}${!obituary?.href ? " alt" : ''}`}
                                                                    onClick={() => setSelectedObituary(obituary)}
                                                                >
                                                                    <div className="bold f cL gCW s">
                                                                        {obituary?.hostName}
                                                                    </div>
                                                                    {obituary?.href &&
                                                                        <a
                                                                            className="action f cR fR fC p"
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            href={obituary?.href}
                                                                        >
                                                                            <LanguageIcon/>
                                                                        </a>
                                                                    }
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="description f bR g dG cL">
                                            <div className="header cC g dG right e">
                                                <div className={`bold g gC1 dG cC contentHeader${viewType !== "mobile" ? " gC2" : ''}`}>
                                                    <ArticleIcon/> <div className='gC3'>Content</div>
                                                </div>
                                                <div className={`individualName g f cR fC bold${viewType === "mobile" ? " gC3" : ''}`}>
                                                    {selectedProfile?.first_name + " " + selectedProfile?.last_name}
                                                </div>
                                            </div>
                                            <div className="content bR oA">
                                                <div className="f oH tO dP">
                                                    <div ref={obitString} className="obituaryString bR dP l">
                                                        {selectedObituary?.text_content}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {currentView === "deathReciept" && (
                                    <>
                                        {selectedProfile?.stem === "deceased" ?
                                            printDeathReceipt()
                                            :
                                            selectedProfile?.stem === "individuals" && ((!selectedProfile?.dod || !selectedProfile?.source) && !selectedProfile?.death_confirmation_date) ? (
                                                <>
                                                    {prinStatusReport()}
                                                </>
                                            ) : (
                                                printDeathReceipt()
                                            )
                                        }
                                    </>
                                )}
                            </div>
                        }
                </>
            }

            {!selectedProfile?.stem &&
                <>  
                <div className='unselected f cC'>   
                    {/* Individual Profile */}
                </div>
                <div className='unselected empty f cC g'>                                                           
                    <div className='g cC dG'><div>Select an individual to view details</div> <ArrowCircleLeftIcon/></div>                                                               
                </div>                                                                                              
                </>                                                                                 
            }

            {/* {props?.profileLoading && 
                <div className="loadingScreen f cC">
                    <div className="loader cC g">
                        <div className="cC g fR">
                            <div className="icon cC">
                                <FolderSharedTwoToneIcon/>
                            </div>
                            <div className="icon cC">
                                <TroubleshootTwoToneIcon/>
                            </div>
                            <div className="icon cC">
                                <PlaylistAddCheckCircleTwoToneIcon/>
                            </div> 
                        </div>
                        <div className="cC">
                            We're gathering the individual's information.  
                        </div>
                    </div>
                </div>
            } */}
            
            
        </div>
    )
};

export default ReportProfile;