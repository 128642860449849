import React, { useState, useEffect } from 'react';

const ViewportHandler = () => {
  // Function to determine the viewport size category based on the width
  const getViewportType = (width) => {
    if (width <= 768) {
      return 'mobile';
    } else if (width <= 1024) {
      return 'medium';
    } else {
      return 'full';
    }
  };

  const [viewport, setViewport] = useState(() => ({
    width: window.innerWidth,
    viewType: getViewportType(window.innerWidth),
  }));

  useEffect(() => {
    const handleViewportChange = () => {
      const newWidth = window.innerWidth;
      const newSize = getViewportType(newWidth);
      setViewport({ width: newWidth, viewType: newSize });
    };

    window.addEventListener('resize', handleViewportChange);

    return () => {
      window.removeEventListener('resize', handleViewportChange);
    };
  }, []);

  return viewport;
};

export default ViewportHandler;
