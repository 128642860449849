import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';
import HandymanIcon from '@mui/icons-material/Handyman';
import DonutSmallTwoToneIcon from '@mui/icons-material/DonutSmallTwoTone';

function DonutChart(props){
    const session = props?.session;
    const viewType = session?.env?.viewport?.viewType;
    const reporting = props?.session?.reporting;
    const currentAccountID = session?.handler?.data?.currentAccountID;
    const reportingData = props?.session?.reporting?.data[currentAccountID]?.deceased;
    const individualData = props?.session?.individual?.data[currentAccountID];
    const dashboardData = session?.dashboard?.data;
    const selectedTermData = dashboardData?.selectedTerm;
    const donutChartData = reportingData?.termDeaths
    const updateReporting = props?.session?.reporting?.setData;
    const [currentCategoryHover, setCurrentCategoryHover] = useState("default");
    const [currentRelativeTotal, setCurrentRelativeTotal] = useState();

    const donutChartCategories = [
        "SSA",
        "State",
        "Obituary",
    ];

    let relativeTotal = props?.total ?? 0;
    let eligibleSubData = [];

    useEffect(() => {
        maintainCenterMessage();
    }, [currentCategoryHover]);

    useEffect(()=>{
        setCurrentRelativeTotal(props.total);
    }, [props?.total, props?.selectedGroups]);

    if(!donutChartData){
        return (
            <div className="f loading cC">
                <div className="cC f">
                    <div>
                        <CircularProgress color="inherit"/>
                    </div>
                </div>
            </div>
        )
    }

    function maintainCenterMessage(){
        if(currentCategoryHover === "default"){
            setCurrentRelativeTotal(relativeTotal);
            setCurrentCategoryHover("Total Deaths");
        }
    }

    let eachCategory = [];
    let summary = [];
    let pieceCount = 0;
    let lastPosition = 0;
    let lastAnimationDelay = 0;
    let zIndex = 0;

    function createAnimation(rules){
        var styleEl = document.createElement("style");
        document.head.appendChild(styleEl);
        var styleSheet = styleEl.sheet;
        styleSheet.insertRule(rules, 0);
    }

    function checkDataType(category){
        if(props.subData === undefined && donutChartCategories.includes(category)){
            return true;
        }

        if(props.subData && props.chartData[category].position === props.subData){
            return true;
        }
    }

    function checkSummaryType(category){
        if(props.subData === undefined){
            return true;
        }

        if(props.subData !== null && eligibleSubData.includes(category)){
            return true;
        }
    }

    function loadCategoryColor(category){
        if(props.subData !== null && donutChartCategories.includes(category)){
            return individualData?.sourceData?.[category]?.fontColor;
        }
        if(props.subData && props.chartData[category].position === props.subData){
            // let statusData = returnStatusData(category, "Status", true);
            let statusData = "data";
            return statusData.color;
        }
    }

    function loadSummaryDisplay(category, percentage) {
        return (
            <>
                <div className="f cC oH">
                    <div className="gCW">
                        {(donutChartData?.sourceCount?.[category] || 0).toLocaleString() + " " + individualData?.sourceData?.[category]?.friendlyTerm}
                    </div>
                </div>
                <div
                    className="divider"
                    style={ percentage ?
                        { backgroundColor : individualData?.sourceData?.[category]?.[currentCategoryHover === category ? "hoveredFontColor" : "fontColor"] }
                        : {}
                    }
                >
                </div>
            </>
        );
    }

    let animationTime = .125;  // Reduce this to speed up the animation

    for (const category of donutChartCategories) {
        const categoryString = category;

        if (checkDataType(category)) {
            const categoryTotal = donutChartData?.sourceCount?.[category];

            let result = (categoryTotal / relativeTotal) * 360;
            let degree = isNaN(result) ? 0 : result;

            let colorScheme = loadCategoryColor(category);
            let animationDelay = null;
            let firstPartDegree;
            let continuedDegree = 0;

            if (lastAnimationDelay === 0) {
                animationDelay = 0;
                lastAnimationDelay = animationTime;
            } else {
                animationDelay = lastAnimationDelay;
                lastAnimationDelay = lastAnimationDelay + animationTime;
            }

            if (degree > 180) {
                firstPartDegree = 180;
                continuedDegree = degree - 180;
            } else {
                firstPartDegree = degree;
                continuedDegree = 0;
            }

            const animationName = `${selectedTermData?.term}${category.replace(/[^A-Z0-9]/ig, "_")}`;
            let keyframes = `@keyframes ${animationName}${pieceCount} {
                from { transform: rotate(0deg); }
                to { transform: rotate(${firstPartDegree}deg); }
            }`;

            createAnimation(keyframes);

            let categorySlider = {
                transform: `rotate(${lastPosition}deg)`,
                zIndex: zIndex ?? 0,
            };

            let categoryProgress = {
                animation: `${animationTime}s ease ${animationDelay}s 1 normal forwards running ${animationName}${pieceCount}`,
                backgroundColor: colorScheme,
            };

            eachCategory.push(
                <div
                    key={categoryString}
                    className="pieSlice"
                    style={categorySlider}
                    title={individualData?.sourceData?.[category]?.friendlyTerm}
                    onMouseOver={() => {
                        setCurrentCategoryHover(categoryString);
                        setCurrentRelativeTotal(categoryTotal);
                    }}
                >
                    <div className="sliceProgress" style={categoryProgress}></div>
                </div>
            );

            lastPosition = lastPosition + firstPartDegree;   

            if (continuedDegree > 0) {
                pieceCount++;

                let continuedAnimationTime = (continuedDegree / 360) * animationTime;
                let secondPartDelay = animationDelay; // Use the original animationDelay for the second portion

                animationDelay += secondPartDelay;

                keyframes = `@keyframes ${animationName}${pieceCount} {
                    from { transform: rotate(0deg); }
                    to { transform: rotate(${continuedDegree}deg); }
                }`;

                createAnimation(keyframes);

                categorySlider = {
                    transform: `rotate(${lastPosition}deg)`,
                    zIndex: zIndex,
                };

                categoryProgress = {
                    animation: `${continuedAnimationTime}s ease ${animationDelay}s 1 normal forwards running ${animationName}${pieceCount}`,
                    backgroundColor: colorScheme,
                };

                eachCategory.push(
                    <div
                        key={categoryString + "b"}
                        className="pieSlice continued"
                        style={categorySlider}
                        onMouseOver={() => {
                            setCurrentCategoryHover(categoryString);
                            setCurrentRelativeTotal(categoryTotal);
                        }}
                    >
                        <div
                            className="sliceProgress"
                            style={categoryProgress}
                            title={categoryString}
                        >
                        </div>
                    </div>
                );

                lastAnimationDelay += continuedAnimationTime;
                lastPosition += continuedDegree;
            }

            if (checkSummaryType(category)) {
                const sourceCount = donutChartData?.sourceCount?.[category] ?? 0;
                const percentage = isFinite(sourceCount / relativeTotal) ? (sourceCount / relativeTotal) * 100 : 0;
                
                const showSummaryReport = (e, reportData) => {
                    e.stopPropagation(0);
                    props?.session?.user?.functions?.updateUserData(["currentActivity"], ["reporting"]);
                    updateReporting(`${session?.handler?.data?.currentAccountID}`,"selectedReport", reportData);
                }

                const staticReport = {
                    // ...props?.session?.reporting?.data?.reset,
                    branch: "deathTrac",
                    stem: "deceased",
                    columns: reporting?.data[currentAccountID]?.defaultColumns?.["deceased"]?.detailedArray,
                    query: `(death_confirmation_date in_between '${selectedTermData?.startDate ?? "1900-01-01"}' to '${
                        selectedTermData?.endDate ?? new Date().toISOString().split('T')[0]}' AND abl_source match '${
                            category}')${props?.selectedGroup !== "All Groups" ? " AND internal_group match '" + props?.selectedGroup + "'": ''}`,
                    details: {
                        ...props?.session?.reporting?.data[currentAccountID]?.reset?.details,
                        name: `${props?.selectedTerm} ${category}`,
                    },
                    criteria: {
                        existing: [
                            {
                                "formType": "date",
                                "criterion": {
                                    "in_between": {
                                        "startDate": `${selectedTermData?.startDate ?? "1900-01-01"}`,
                                        "endDate": `${selectedTermData?.endDate ?? new Date().toISOString().split('T')[0]}`,
                                    },
                                },
                                "groupID": 1,
                                "groupOperator": "AND",
                                "inlineOperator": "AND",
                                "attr": "death_confirmation_date",
                            },
                            {
                                "formType": "dropdown",
                                "criterion": {
                                    "match": `${category}`,
                                },
                                "relativeKey": 0,
                                "groupID": 1,
                                "groupOperator": "AND",
                                "inlineOperator": "AND",
                                "attr": "abl_source",
                            },
                            props?.selectedGroup !== "All Groups" ?
                            {
                                "formType": "string",
                                "criterion": {
                                  "match": `${props?.selectedGroup}`,
                                },
                                "relativeKey": 0,
                                "groupID": 1,
                                "groupOperator": "AND",
                                "inlineOperator": "AND",
                                "attr": "internal_group",
                            } : undefined
                        ].filter(Boolean),
                    },
                };

                const summaryReport = {
                    ...props?.session?.reporting?.data[currentAccountID]?.reset,
                    ...staticReport
                };

                summary.push(
                    <Link
                        key={category}
                        to={"/reporting"}
                        className={`block bR bold${viewType !== "mobile" ? " s e" : ''}${percentage === 0 ? " inactive" : ''}`}
                        style={
                            percentage > 0 ? {
                                backgroundColor : individualData?.sourceData?.[category]?.[currentCategoryHover === categoryString ? "hoveredBackgroundColor" : "backgroundColor"],
                                borderColor : individualData?.sourceData?.[category]?.[currentCategoryHover === categoryString ? "hoveredFontColor" : "fontColor"],
                                }
                            :   {}
                        }
                        onClick={(e) => {
                            const summaryReportCopy = { ...summaryReport }; // Create a copy of the summaryReport
                            showSummaryReport(e, summaryReportCopy)
                        }}
                    >
                        <div
                            key={pieceCount + "c"}
                            className={`summaryInfoBlock g cC f fC fR${viewType !== "mobile" ? ' dG' : ''}`}
                            title={categoryString}
                            style={
                                percentage > 0 ?
                                    { color : individualData?.sourceData?.[category]?.[currentCategoryHover === categoryString ? "hoveredFontColor" : "fontColor"] }
                                :   {}
                            }
                            onMouseOver={() => {
                                setCurrentCategoryHover(categoryString);
                                setCurrentRelativeTotal(categoryTotal);
                            }}
                        >
                            <div className="f cC icon">
                                {individualData?.sourceData?.[category]?.icon}
                            </div>
                            <div className="point g f dG">
                                {loadSummaryDisplay(category, percentage)}
                                <div className="summaryInfoBlockPercentage f cC">
                                    {percentage.toFixed(0) + "%"}
                                </div>
                            </div>
                        </div>
                    </Link>
                );
            }

            pieceCount++;
            zIndex++;
        }
    }

    let chartContainer = "donutChart fR fC g cC";
    let chartType = "pie";
    let chartSummary = "pieChartSummary f g bR fR dP g dG";

    return (
        <div className={`chartContainer f${props?.placeholder ? " dP" : " s b e"}`}>
            {props?.placeholder ?
                <div className={`placeholder bR f cC dP`}>
                    <div className="block g cL bold dG">
                        <DonutSmallTwoToneIcon/>
                        <div className="prompt">
                            {props?.promptMessage}
                        </div>
                    </div>
                </div>
            :
                <div className={`chartData g fC cC${viewType === "full" ? " fR" : '' }`}>
                    <div className={`${chartContainer}${!currentRelativeTotal ? " inactive" : '' }`}>
                        <div className={chartType} onMouseLeave={()=> { setCurrentCategoryHover("default")}}>
                            {eachCategory}
                            <div
                                className="centerPrompt"
                                style={{zIndex : (relativeTotal + 1)}}
                                onMouseOver={(e) => {e.preventDefault()}}
                            >
                                <div className="pieTotalCountWrap g cC">
                                    <div className="pieTotalCount">
                                        <div>
                                            <div className="pieCountNumber">
                                                {((currentRelativeTotal)?.toLocaleString() ?? 0)}
                                            </div>
                                            <span className="pieCountMessage">
                                                {currentCategoryHover}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={chartSummary} onMouseLeave={()=> { setCurrentCategoryHover("default")}}>
                        {summary}
                    </div>
                </div>
            }
        </div>
    )
 }

 export default DonutChart;