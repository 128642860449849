import React from 'react';
import { useState, useEffect} from 'react';
import { Button, Text, useDisclosure } from '@chakra-ui/react';
import CircularProgress from '@mui/material/CircularProgress';

import SaveIcon from '@mui/icons-material/Save';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
  
  function ContractLimit(props) {
      const session = props?.session;

      const [loadingStatus, setLoadingStatus] = useState(false);

      const OverlayOne = () => (
        <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(6px)'
        />
      )

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [overlay, setOverlay] = React.useState(<OverlayOne />)

    return (
      <>
        <div className='btnWIcon cC f bR'
          onClick={() => {
            setOverlay(<OverlayOne />)
            onOpen()
          }}
        >
          <div className='f cC'>Save Upload </div>
          <SaveIcon/>
        </div>
        <Modal isCentered isOpen={isOpen} size={'sm'} onClose={onClose} motionPreset='slideInBottom'>
          {overlay}
          <ModalContent top={'300px'} width={'50%'} left={'25%'} className='userAgreementBox bR cC'>
            {!loadingStatus &&
              <>
                <ModalHeader className='bold redText'>Contract Limit Exceeded</ModalHeader>
                <div className='cC' style={{width:'1000px'}}>
                  
                </div>
                <ModalBody>
                         <br/>      
                        <div className='g dG'>
                            <div className='bold gC1 cR'>Contract Limit:</div> <div className='gC2 cL'>{session?.upload?.data?.contractLimit.toLocaleString()}</div>
                        </div>
                        <div className='g dG'>
                            <div className='bold gC1 cR'>Individuals Uploaded:</div> <div className='gC2 cL'>{session?.edit?.data?.uploadCount.toLocaleString()}</div>
                        </div>
                  
                  <Text>             
                    <>
                      &emsp;The number of individuals uploaded has exceeded your contract limit. Please contact us at (321) 475-8251 with any questions you may have.
                    </>
                  </Text>
                  
                </ModalBody>
                <ModalFooter>
                  <div className='userAgreementButtons  cC f'>
                    
                    <div className='btnWIcon cC bold saveUploadBtn dP bR gC2' onClick={onClose}>Return to Upload</div>
                  </div>
                </ModalFooter>
              </>
            }
            
          </ModalContent>
        </Modal>
      </>
    )
  }

  export default ContractLimit;