import React, { useEffect, useState } from 'react';

import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

function Settings(props){
    const session = props?.session;

    const settingsPages = [
        // { 
        //     path : "adminBoard",
        //     friendlyTerm : "Admin Board",
        //     navigatiionTab: 'manageUsers'
        // },
        
        // {
        //     path : "emailPreferences",
        //     friendlyTerm : "Email Preferences"
        // },
        // {
        //     path : "passwordChange",
        //     friendlyTerm : "Change Password"
        // },
    ];

    session?.user?.data?.permissions?.includes("accMgr") && 
        settingsPages.push({ 
            path : "adminBoard",
            friendlyTerm : "Admin Board",
            navigatiionTab: 'manageUsers'
        });

    settingsPages.push({
        path : "adminBoard",
        friendlyTerm : "Settings",
        navigatiionTab: 'settings'
    });

    
    function iteratePages() {
        return settingsPages.map((page) => {

        //   if (page?.path === "adminBoard" && !session?.user?.data?.permissions.includes("admin")) {
        //     return null;
        //   }

          return (
            <div className="options g f cC fC p" key={page.path + page.friendlyTerm}>
                <div
                    className="option f cC bR s e bold"
                    onClick={() => {
                        session?.env?.setOverlay({name: page.path, props: [page?.navigatiionTab]});
                    }}
                >
                    {page.friendlyTerm}
                </div>
            </div>
          );
        });
    }

    useEffect(() => {

    }, []);

    return (
        <div className="settingsOptions g f oH nS">
            <div className="f cC s e g fC">
                <div className="accountInfo g f cC fC">
                    <div className="f cL">
                        {session?.user?.data?.companyName}
                    </div> 
                    <div className="f cL">
                        {session?.user?.data?.email}
                    </div> 
                </div>
                {iteratePages()}
            </div>
            <div className="logout g f cRe dP" onClick={()=>{session?.user?.functions?.logout()}}>
                <div className="btnWIcon f e bR bold cC p gC2">
                    <div className="f cC">
                        Logout
                    </div>
                    <LogoutRoundedIcon/>
                </div>
            </div>
        </div>
    )
};

export default Settings;