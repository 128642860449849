import React, { useEffect, useState, useRef } from 'react';

import PublicIcon from '@mui/icons-material/Public';

function ResultsRenderHandler({
    session,
    cachedFormattedDates
  }) {
    
    const envFunctions = session?.env?.functions;

    function boldSearchText(text, searchStrings) {
        if(!text || (searchStrings?.length === 0 || !searchStrings || searchStrings?.[0] === '')){
            return text;
        }

        const lowercaseText = { value: text?.toLowerCase() };
        const result = [];
        searchStrings.sort((a, b) => b.length - a.length);

        searchStrings
        ?.filter(str => str?.trim())
        ?.forEach(searchString => {
            const lowercaseSearchString = searchString?.toLowerCase();
            let startIndex = lowercaseText?.value?.indexOf(lowercaseSearchString);

            while (startIndex !== -1) {
                const endIndex = startIndex + searchString?.length;
                result.push({ string: text?.substring(0, startIndex), bold: false });
                result.push({ string: text?.substring(startIndex, endIndex), bold: true });

                text = text?.substring(endIndex);
                lowercaseText.value = lowercaseText?.value?.substring(endIndex);
                startIndex = lowercaseText?.value?.indexOf(lowercaseSearchString);
            }
        });

        if (text?.length > 0) {
            result.push({ string: text, bold: false });
        }

        return (
            result.map((portion, index) => (
                <span className={portion?.bold ? 'bold' : ''} key={index + "-" + portion?.string}>
                    {portion?.string}
                </span>
            ))
        );
    }

    function parseDateToUTC(dateString) {
        let year, month, day;
    
        // Check if the date is in MM/DD/YYYY format
        if (dateString.includes('/')) {
            const [monthPart, dayPart, yearPart] = dateString.split('/');
            month = parseInt(monthPart, 10) - 1; // Convert to zero-indexed month
            day = parseInt(dayPart, 10);
            year = parseInt(yearPart, 10);
        } 
        // Check if the date is in YYYY-MM-DD format
        else if (dateString.includes('-')) {
            const [yearPart, monthPart, dayPart] = dateString.split('-');
            year = parseInt(yearPart, 10);
            month = parseInt(monthPart, 10) - 1; // Convert to zero-indexed month
            day = parseInt(dayPart, 10);
        } 
        else {
            throw new Error("Unsupported date format.");
        }
    
        return Date.UTC(year, month, day);
    }
    const renderFormattedColumnValue = (columnValue, returnType, searchData, special) => {
        const stringOnly = searchData === ("stringOnly" || !searchData);

        if(!columnValue){
            return null;
        }

        if(special === "grouped" || special === "Count"){
            return columnValue;
        }

        switch (returnType) {
            case "percentage":
                return stringOnly ?
                    envFunctions.convertFloatToPercentage(columnValue).toString()
                :
                    boldSearchText(envFunctions.convertFloatToPercentage(columnValue).toString(), searchData, stringOnly);
            case "currency":
                return stringOnly ?
                    envFunctions.convertIntToCurrency(columnValue).toString()
                :
                    boldSearchText(envFunctions.convertIntToCurrency(columnValue).toString(), searchData, stringOnly);
            case "date":
                const UTCdate = parseDateToUTC(columnValue);
                const date = new Date(UTCdate);
                const year = date.getUTCFullYear();
                const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
                const day = String(date.getUTCDate()).padStart(2, '0');
                const newDate = `${year}-${month}-${day}`;

                if(special === 'excel'){
                    return newDate; 
                }

                if (cachedFormattedDates.has(newDate)) {
                    const formattedDate = cachedFormattedDates.get(newDate);
                    return stringOnly ?
                        formattedDate
                    :
                        boldSearchText(formattedDate, searchData, stringOnly);
                }

                const reformattedDate = envFunctions?.reformatDate(newDate);
                cachedFormattedDates.set(newDate, reformattedDate);

                if (reformattedDate === "Invalid Date" || !newDate) {
                    return null;
                }

                return stringOnly ?
                    reformattedDate?.toString()
                :
                    boldSearchText(reformattedDate.toString(), searchData, stringOnly);

            case "link":
                return (
                    <a className="link" target="_blank" href={columnValue}>
                        <div className="f cC g">
                            <PublicIcon />
                            <div className="f gCW">
                                { stringOnly ?
                                    columnValue?.toString()
                                :
                                    boldSearchText(columnValue?.toString(), searchData, stringOnly)}
                            </div>
                        </div>
                    </a>
                );
            case "obitLink":
                return (
                    <a className="link greenLink" target="_blank" href={columnValue} onClick={(e) => { e.stopPropagation(); }}>
                        <div className="f cC g">
                            <PublicIcon />
                            <div className="f gCW">
                                {getHostName(columnValue?.toString())}
                            </div>
                        </div>
                    </a>
                );
            default:
                return stringOnly ?
                    columnValue?.toString()
                :
                    boldSearchText(columnValue?.toString(), searchData, stringOnly);
        }
    };

    const getHostName = url => {
        try {
            return new URL(url).hostname.slice(4);
        } catch (e) {
            return undefined;
        }
    };

    const functions = {
        renderFormattedColumnValue,
        boldSearchText
    }

    const resultsRenderHandler = {
        functions,
    };

    return resultsRenderHandler;
};

export default ResultsRenderHandler;
