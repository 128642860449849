import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import CircularProgress from '@mui/material/CircularProgress';

import Initialization from '../SessionVars/Initialization.js';

import FullLogo from '../Images/FullLogo.png';

function Unsubscribe(props){
    const session = props?.session;
    const initialize = Initialization();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const d = params.get("data");
    const [currentStage, setCurrentStage] = useState("prompt");
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [errorStatus, setErrorStatus] = useState(false);
    const [invalidView, setInvalidView] = useState(false);

    function unsubscribe(){
        setLoadingStatus(true);

        const paramVals = {
            encodedData : d
        }

        const headers = {
            "Authorization" : "bearer K6f15RLQI9aecByHjugD1jy36HxAGg"
        }

        session?.env?.functions?.buildFetchRequest("unsubscribeUserEmail", paramVals, headers)
            .then(response => response.json())
            .then(resData => {    
                setLoadingStatus(false);
                if(resData.status === 200){
                    setCurrentStage("unsubscribed");
                    navigate("/unsubscribe?data=unsubscribed");
                }else{
                    setErrorStatus(resData.message);
                }
        });
    }

    const renderContent = () => {
        if (loadingStatus) {
          return (
            <div className="f cC">
              <CircularProgress />
            </div>
          );
        } else if (invalidView){
            return (
                <div>
                    <div className="header">
                        You're viewing an outdated page
                    </div>
                    <div>
                        You can proceed with viewing your email preferences or login
                    </div>
                </div>
            )
        } else if (currentStage === "prompt") {
          return (
            <div>
                <div className="header">
                    Are you sure you wish to unsubscribe?
                </div>
                <div>
                    You can customize your notifications to better match your preferences
                </div>
            </div>
          );
        } else {
          return <div>{errorStatus ? errorStatus : "You've been unsubscribed"}</div>;
        }
    };

    const renderOptions = () => {
        return (
            <div className={`options f g cC fR${currentStage === 'prompt' ? '' : " alt"}`}>
                <div
                    className="option bold cC p bR f fR dP"
                    onClick={() => {
                        session?.env?.functions?.redirectLink('/unsubscribe');
                        navigate("/");
                    }}
                >
                    View My Email Preferences
                </div>
                {currentStage === "prompt" && !invalidView ?
                    <div className="option alt bold cC p bR f fR" onClick={() => unsubscribe()}>
                        Turn Off All Case Notifications
                    </div>
                :
                    <div className="option alt bold cC p bR f fR" onClick={() => {sessionStorage.removeItem('redirectLink'); navigate("/")}}>
                        Login
                    </div>
                }
            </div>
        );
    };

    useEffect(() => {
        if (!d) {
            navigate("/");
        }
      
        if (d === "unsubscribed" && currentStage === "prompt") {
            setInvalidView(true);
        } else {
            setInvalidView(false);
        }
    }, [d, currentStage]);

    useEffect(() => {
        initialize.resetLoaders();
    }, []);

      return (
        <div className="unsubscribeBoard f g cC dP fR fC">
            <div className="form moduleBlock bR g dP cC fC">
                <div className="container f g cC dP fC">
                    <div className="logo f cC">
                        <img className="f cC" src={FullLogo} alt="Logo" />
                    </div>
                    <div className="prompt f g">
                        {renderContent()}
                    </div>
                    {renderOptions()}
                </div>
            </div>
        </div>
      );

};

export default Unsubscribe;