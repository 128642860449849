import React, { useEffect, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function ContractLimitOverlay(props){

    const session = props?.session;

    const [loadingStatus, setLoadingStatus] = useState(false);

    function printOverlayContent() {
        return (
            <div className="removeFileContainer f g fC dP tO gR2 bR">
                    <div className='g dG'>
                    <div className='bold gC1 cR'>Contract Limit:</div> <div className='gC2 cL'>{session?.upload?.data?.contractLimit.toLocaleString()}</div>
                </div>
                <div className='g dG'>
                    <div className='bold gC1 cR'>Individuals Uploaded:</div> <div className='gC2 cL'>{session?.edit?.data?.uploadCount.toLocaleString()}</div>
                </div>     
                <>
                    The number of individuals uploaded has exceeded your contract limit. <br/> Please contact us at (321) 475-8251 with any questions you may have.
                </>  
            </div>         
        );
    }

    return (
        <>
            <div className="overlayBoard g f fC oH dP">
                <div className='uploadFailure g'>
                    {printOverlayContent()}
                </div>
                <div className='userAgreementButtons cC f'>                   
                    <div className='btnWIcon cC bold saveUploadBtn dP bR gC2' onClick={() => {props?.setter(false);}} >Return to Upload</div>
                </div>
            </div>              
        </>
    );
};

export default ContractLimitOverlay;
