import React, { useEffect, useState } from 'react';

import TimerTwoToneIcon from '@mui/icons-material/TimerTwoTone';
import CircularProgress from '@mui/material/CircularProgress';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';
import SmsIcon from '@mui/icons-material/Sms';


function Preferences(props){
    const session = props?.session;
    const sessionUser = props?.sessionUser;
    const [updatePrompt, setUpdatePrompt] = useState("Update");
    const [loading, setLoadingStatus] = useState(false);
    const [toggle, setToggle] = useState({});
    const toggleableAttributes = ['emailNotifications', 'emailSummaryStatus', 'textNotifications'];

    // const actualData = {
    //     emailFrequency : "live",
    //     emailSummaryFrequency : "monthly",
    //     emailNotifications : true,
    //     emailSummaryStatus : true
    // }
    const currentPreferences = sessionUser?.preferences;

    const [preferences, setPreferences] = useState({
        ...currentPreferences,
    });

    const summaryEligable = !(preferences?.emailNotifications && preferences?.emailFrequency === preferences?.emailSummaryFrequency);

    const frequencyOptions = [
        { value: "Live", label: "Live" },
        { value: "Daily", label: "Daily" },
        { value: "Weekly", label: "Weekly" },
        { value: "Monthly", label: "Monthly" },
    ];

    const frequencySummaryOptions = [
        { value: "Daily", label: "Daily" },
        { value: "Weekly", label: "Weekly" },
        { value: "Monthly", label: "Monthly" },
    ];

    const [data, setData] = useState({
        password : '',
        passwordConfirmation : ''
    });

    function attrHandler(attr) {
        return preferences[attr] !== currentPreferences[attr]
        ? { [attr]: preferences[attr] }
        : {};
    }

    function generateUpdateArrays() {
        const attrPaths = [];
        const attrVals = [];
        
        for (const attr in preferences) {
            if (preferences[attr] !== currentPreferences[attr]) {
                    attrPaths.push("preferences." + attr);
                    attrVals.push(preferences[attr]);
            }
        }

        return { attrPaths, attrVals };
    }

    function updateEligable() {
        for (const key in currentPreferences) {
            if (currentPreferences[key] !== preferences[key]) {
                return true;
            }
        }

        return false;
    }

    function updatePreferences(forgotPassword){
        setLoadingStatus(true);

        const paramVals = {
            userID: sessionUser?.userID,
            ...attrHandler('emailFrequency'),
            ...attrHandler('emailSummaryFrequency'),
            ...(Object.keys(toggle).length > 0 ? { toggle } : {}),
        };

        session?.env?.functions?.buildFetchRequest("user/update", paramVals)
            .then(response => response.json())
            .then(resData => {    
                setLoadingStatus(false);
                if(resData.status === 200){
                    const {attrPaths, attrVals } = generateUpdateArrays();
                    session?.user?.functions?.updateUserData(attrPaths, attrVals);
                    session?.env?.setOverlay();
                }else{
                    setUpdatePrompt("Update Error");
                }
        });
    }

    useEffect(() => {
        if(session?.env?.overlay !== "passwordChange"){
            setData({
                password : '',
                passwordConfirmation : ''
            })
        }
    }, [session?.env?.overlay]);

    useEffect(() => {
        const updatedToggle = {};
    
        toggleableAttributes.forEach(attribute => {
          if (currentPreferences !== preferences) {
            updatedToggle[attribute] = preferences[attribute];
          }
        });
    
        setToggle(updatedToggle);
      }, [preferences]);

    //   toggleableAttributes.forEach(attribute => {
    //     if (currentPreferences[attribute] !== preferences[attribute]) {
    //       updatedToggle[attribute] = preferences[attribute];
    //     }
    //   });

    return (
        <>
            <div className="preferences g f oH dP nS tC fC">
                <div className="emailToggle f g fR s">
                    <div
                        className={`toggle s e p${preferences?.emailNotifications ? " active cR" : " cL"}`}
                        onClick={() =>
                            setPreferences((prev) => ({
                                ...prev,
                                emailNotifications: !prev.emailNotifications,
                            }))
                        }
                    >
                        <div className="status">
                        </div>
                    </div>
                    <div className="f cL">
                        <div className="f cC bold">
                            Update Notifications
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="actionBar g f fR dP">
                <div
                    className={`loginBtn${loading ? " btn" : " btnWIcon"} f g bR e cC gC3 bold${updateEligable() ? " active p" : " inactive"}`}
                    onClick={loading || !updateEligable() ? undefined : updatePreferences}
                >
                    <div className="f cC">
                        {loading ?
                            <CircularProgress/>
                        :
                            updatePrompt
                        }
                    </div>
                    {!loading &&
                        <div className="f cC">
                            <VerifiedRoundedIcon/>
                        </div>     
                    }
                </div>
            </div> */}
            <div className="bold cC dP">Text Message Preferences</div>
            <div className="preferences g f oH dP nS tC fC">
                <div className="emailToggle f g fR s">
                    <div
                        className={`toggle s e p${preferences?.textNotifications ? " active cR" : " cL"}`}
                        onClick={() =>
                            setPreferences((prev) => ({
                                ...prev,
                                textNotifications: !prev.textNotifications,
                            }))
                        }
                    >
                        <div className="status">
                        </div>
                    </div>
                    <div className="f cL">
                        <div className="f cC bold">
                            Opt-in to Text
                        </div>
                    </div>
                </div>
            </div>
            <div className="actionBar g f fR dP">
                <div
                    className={`loginBtn${loading ? " btn" : " btnWIcon"} f g bR e cC gC3 bold${updateEligable() ? " active p" : " inactive"}`}
                    onClick={loading || !updateEligable() ? undefined : updatePreferences}
                >
                    <div className="f cC">
                        {loading ?
                            <CircularProgress/>
                        :
                            updatePrompt
                        }
                    </div>
                    {!loading &&
                        <div className="f cC">
                            <VerifiedRoundedIcon/>
                        </div>     
                    }
                </div>
            </div>
        </>
    )
};

export default Preferences;