import { useState, useRef, useEffect } from 'react';

function SessionHandler(props){
    const [data, setData] = useState({
        currentAccountID: '',
        currentName: '',
        selectedAccount: {showMenu: false},
        // contractLimit: undefined,
        // views:[],
        // permissions:[],
        // deceased: [],
        // individuals:[],
        // Reports: [],
    });

    // useEffect(() => {
    //     console.log("currentAccID:",data?.currentAccountID);
    // }, [data?.currentAccountID]);
    // useEffect(() => {
    //     setData({
    //         ...prevData,
    //         currentName : session?.account?.data[data?.currentAccountID]?.companyName
    //     });
    // }, [data?.currentAccountID]); 

    function findAccountIdByName(name, dictionary) {
        for (const accountId in dictionary) {
            if (dictionary[accountId].name === name) {
                return accountId; // Return the accountID if the name matches
            }
        }
        return null; // Return null if no match is found
    }

    const setSelectedAccount = (isSelected) => {
        setData(prevData => ({
            ...prevData,
            selectedAccount: isSelected,
        }));
    };

    const functions = {
        findAccountIdByName,
        setSelectedAccount
    }

    const SessionHandler = {
        data,
        setData,
        functions
    }

    return SessionHandler;
};

export default SessionHandler;