import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';

function Tab(props){
    const session = props?.session;
    const individualData = session?.individual?.data;
    const notifications = session?.notifications?.data;

    const [newLabel, setNewLabel] = useState(props?.label);

    // If label has space, convert to camelCase
    useEffect(() => {
        if (props?.label && props.label.includes(' ')) {
          const updatedLabel = props.label.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
          }).replace(/\s+/g, '');
          setNewLabel(updatedLabel);
        }
      }, []);

    const checkNotificationType = () => {
        if (!notifications || !props?.alertType) return false;

        const hasStatusNotification = notifications.some(
          (notificationArray) =>
            notificationArray[1]?.activityType === props?.alertType &&
            notificationArray[1]?.relatedID === props?.currentPolicy?.relatedPolicyID
        );

        const hasMessageNotification = notifications.some(
          (notificationArray) => notificationArray[1]?.activityType === "Message" &&
            props?.alertType === "Message"
        );

        return hasStatusNotification || hasMessageNotification;
    };
      
    const hasPoliciesAlert = checkNotificationType();

    return(
        <Link
            to={!props?.loading && newLabel?.toLowerCase()}
            className={`tab g f cC fC${props?.isActive ? " active" : ''}${props?.loading ? " offline" : " p"}`}
            onClick={!props?.loading && props?.onClick}
        >
            <div className="prompt g f cC pR fR gR2">
                {hasPoliciesAlert && (
                    <div className="alert">
                        <NewReleasesRoundedIcon/>
                    </div>
                )}
                {props?.viewType === "full" &&
                    <div className="s f cC g oH">
                        <div className="f cC gCW">
                            {props?.label}
                        </div>
                    </div>
                }
                <div className={`f cC icon${props?.viewType === "full" ? " gC2" : ''}`}>
                    {props?.icon}
                </div>
            </div>
            <div className="status f gR3">
            </div>
        </Link>
    )
}

export default Tab;
