import React, { useEffect, useState } from 'react';

function GenerateKey(props){

    return(
        <div className='bR f g gR1 gC1'>
            <div className='cC bold f gR1 gC1 colSpan2'>
                Are You Sure You Want To Generate a New Key?<br/>
                
            </div> 
            <div className='gR2 gC1 colSpan2 redText'>
                All Previous Keys Will Be Deactivated                
            </div> 
            
            {/* <div className='btnWIcon bC dP p bR gR3 gC1 colSpan2' onClick={()=>{
                    props?.overlayProps?.deleteUser(props?.overlayProps?.selectedUser?.recordID);
                    props?.overlayProps?.setRemovingUser(false);
                    props?.overlayProps?.setRemoveClicked(true);
                    props?.overlayProps?.setSelectedItemIndex(null);
                    props?.overlayProps?.setCurrentlyEditing(false);
                    props?.setter(false);
                }}>
                Confirm
            </div>                            */}
        </div>
    )

};

export default GenerateKey