import React, { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
const fetchBase = process.env.REACT_APP_FETCH_BASE;

function SFTPConfirmation(props){

   
    const session = props?.session;

    const sessionUser = props?.session

    const viewType = session?.env?.viewport?.viewType;

    const [loadingStatus, setLoadingStatus] = useState(false);

    const fileRows = session?.edit?.data?.importedList.list;
    const errorCountData = session?.upload?.data?.errorCountData;

    const [progressBar, setProgressBar] = useState(0);
        
    function printOverlayContent() {
        return (
            <div className='cC f dP g dG' style={{maxWidth:'750px'}}>
            {!loadingStatus ?
                <>
                    <div className='l dP'>
                        Your file will be sent to ABL servers through SFTP. Please confirm that you are uploading the correct file.
                    </div>

                    <div className='sftpButtons g cC f'>
                        
                        <div className='btnWIcon cC bold saveUploadBtn dP bR gC2' onClick={() => {props?.setter(false);}}>Cancel</div>

                        <div className='btnWIcon cC bold saveUploadBtn dP bR gC4' onClick={() => {uploadSFTP(props?.file); session?.upload?.functions?.setSftpFiles([]); setLoadingStatus(true);}}>Confirm Upload</div>

                    </div> 
                </>
            :
                <div className='cC g sftpLoadingContainer dG'>
                    <div className='gR2 bC'>{`Current Progress: ${progressBar.toFixed(2)}%`}</div>
                    <div class="progress gR3">
                        <div class="bar">
                            <div class="progress-value"></div>
                        </div>
                    </div>
                </div>
                // <div className="cC f dP bR" style={{ minWidth: viewType === "mobile" ? 'auto' : '750px', minHeight: '300px' }}>
                //     <CircularProgress disableShrink color="success" variant="indeterminate" fontSize="large"/>
                // </div>
            }
            </div>                                              
        );                                                  
    }           

    function uploadSFTP(selectedFile) {
        return new Promise((resolve, reject) => {
          if (selectedFile) {
            const xhr = new XMLHttpRequest();
            const formData = new FormData();

            xhr.withCredentials = true;
            
            formData.append("accountID", session?.handler?.data?.currentAccountID);
            formData.append('file', selectedFile);
            
            xhr.open('POST', `${fetchBase}SFTP/upload`, true);
            xhr.setRequestHeader("Authorization", `Bearer ${session?.user?.data?.sessionID}`);
            
            xhr.upload.onprogress = (event) => {
              if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                //console.log(`Upload progress: ${percentComplete.toFixed(2)}%`);
                setProgressBar(percentComplete);

                const progress = document.querySelector(".progress");
                    
                const value = Math.floor(percentComplete);
                progress?.style.setProperty("--progress", `${value}%`);          

              }
            };
        
            xhr.onload = function() {
                setLoadingStatus(false);
              if (xhr.status === 200) {
                session?.env?.setOverlay("SFTPSuccess");
                //console.log('Upload complete!');
                resolve(xhr.responseText);
              } else {
                session?.env?.setOverlay("uploadFailure");
                //console.error('Upload failed');
                reject(new Error('Upload failed'));
              }
            };
        
            xhr.onerror = function() {
              setLoadingStatus(false);
              session?.env?.setOverlay("uploadFailure");
              //console.error('Upload failed');
              reject(new Error('Upload failed'));
            };
        
            xhr.send(formData);
          };
        });
      }
            
    // function uploadSFTP(file) { 
    //     const formData = new FormData();
    //     formData.append("accountID", session?.handler?.data?.currentAccountID);
    //     formData.append("file", file);
    //     //console.log(file);
    //     const headers = {
    //         // 'Content-Type': 'application/json',
    //         // 'Accept': 'application/json',
    //         'Authorization': `Bearer ${session?.user?.data?.sessionID}`
    //     }
    //     fetch("https://apistg.abltech.com/SFTP/upload", {
    //         method: 'POST',
    //         body: formData,
    //         credentials: 'include',
    //         headers: headers
    //     })
    //     .then(response => {
    //         setLoadingStatus(false);
    //         if(response.ok){
    //             session?.env?.setOverlay("SFTPSuccess");
    //             return response.json()
    //         }else{
    //             session?.env?.setOverlay("uploadFailure");
    //         }
    //     })
    //     .catch(error => {
    //         setLoadingStatus(false);
    //         console.error('Upload error:', error);
    //         session?.env?.setOverlay("uploadFailure");
    //     });
    // }

    return (
        <>
            <div className="overlayBoard g f fC oH dP">
                <div className='g'>
                    {printOverlayContent()}
                </div>
            </div>  
        </>
    );
};

export default SFTPConfirmation;