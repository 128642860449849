import React, { useEffect, useState } from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';


import ActionBar from "../Overlays/ActionBar.js";
import RemoveUser from "./RemoveUser.js";
import DeleteReport from "./DeleteReport.js";
import GenerateKey from './GenerateKey.js';
import { AlarmSharp } from '@mui/icons-material';


function NestedOverlayHandler(props){
    const session = props?.session;
    const sessionUser = props?.session?.user?.data;
    const viewType = session?.env?.viewport?.viewType;

    let overlayName;
    let overlayProps;

    // Check if overlay contains props, else, just store the name
    if (typeof props?.nestedOverlay === 'object'){
        overlayName = props?.nestedOverlay?.name;
        overlayProps = props?.nestedOverlay?.params;
    }else if((typeof props?.nestedOverlay === 'string')){
        overlayName = props?.nestedOverlay;
    }

    // Close with 'Esc'
    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.keyCode === 27) { 
            props?.setter(false); 
          }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
 
    const headerHandler = {
        "removeUser" : "Remove Account",
        "deleteReport" : "Delete Report",
        "generateKey" : "Generate API Key",
    }

    const overflowAuto = [];

    const moduleBlockType = {
        "settingsMenu" : " inline",
        "remove" : " default",
        "removeUser" : " wAction",
        "deleteReport" : " wAction",
        "generateKey": " wAction",
    }

    return (
        overlayName ?
            <div
                className="nestedOverlay cC"
                onClick={(e)=>{
                    overlayName==='removeUser' && overlayProps?.setRemovingUser(false);
                    props?.handleClose(e, overlayName, props?.setter);
                    session?.user?.functions?.maintainSession();
                }}
            >
                <div className={`moduleBlock${moduleBlockType[overlayName] ?? " default"} g oH ${overlayName}`}>
                    <div className="header g f fR cC">
                        <div className='gC2 f cC'>
                            {headerHandler[overlayName]}
                        </div>
                        <div className="btn close f p cC" 
                            onClick={()=>{
                                overlayName==='removeUser' && overlayProps?.setRemovingUser(false);
                                props?.setter(false)
                            }}
                        >
                            <CloseRoundedIcon/>
                        </div>
                    </div>
                    <div className={`format g f pR fC${overflowAuto?.includes(overlayName) ? " oA" : " oH"}`}>
                        {overlayName === "deleteReport" &&
                            <DeleteReport
                                session={session}
                                overlayProps={overlayProps}
                                setter={props?.setter} 
                            />
                        }
                        {overlayName === "removeUser" &&
                            <RemoveUser
                                session={session}
                                overlayProps={overlayProps}
                                setter={props?.setter} 
                            />
                        }
                        {overlayName === "generateKey" &&
                            <GenerateKey
                                session={session}
                                overlayProps={overlayProps}
                                setter={props?.setter} 
                            />
                        }
                    </div>
                    {moduleBlockType[overlayName] === " wAction" &&
                        <ActionBar
                            session={session}
                            // sessionUser={sessionUser}
                            overlayProps={overlayProps}
                            setter={props?.setter} 
                        />
                    }
                </div>
            </div>
        :
            false
    )
};

export default NestedOverlayHandler