import React, { useEffect, useState } from 'react';

function RemoveUser(props){

    return(
        <div className='bR f cC g gR1 gC1'>
            <div className='cC bold f gR1 gC1 colSpan2 '>Are You Sure You Want <br/>To Remove This User?</div> 
            {/* <div className='btnWIcon bC dP p bR gR3 gC1 colSpan2' onClick={()=>{
                    props?.overlayProps?.deleteUser(props?.overlayProps?.selectedUser?.recordID);
                    props?.overlayProps?.setRemovingUser(false);
                    props?.overlayProps?.setRemoveClicked(true);
                    props?.overlayProps?.setSelectedItemIndex(null);
                    props?.overlayProps?.setCurrentlyEditing(false);
                    props?.setter(false);
                }}>
                Confirm
            </div>                            */}
        </div>
    )

};

export default RemoveUser