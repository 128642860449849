import React, { useEffect, useState } from 'react';

function DeleteReport(props){

    return(
        <div className='bR f cC g gR1 gC1'>
            <div className='cC bold f gR1 gC1 colSpan2 '>Are You Sure You Want <br/>To Delete This Report?</div> 
            {/* <div className='btnWIcon bC dP p bR gR4 gC1 colSpan2' onClick={()=>{
                    props?.setter(false);
                }}>
                Cancel
            </div>    */}
            {/* <div className='btnWIcon bC dP p bR gR4 gC2 colSpan2' onClick={()=>{
                    props?.overlayProps?.deleteReport(props?.overlayProps?.recordID);
                    props?.setter(false);
                }}>
                Confirm
            </div>                   */}
        </div>
    )

};

export default DeleteReport